import React, { useEffect, useMemo, useState } from 'react';
import { t, tString } from 'components/translation/Translation';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// styles
import styles from './sneak-peek.module.scss';
import { BlobSneakPeek } from '../assets/svg-assets';

const FEATURES = {
  UNIFORMS: 'Uniforms',
  LANGUAGE: 'Language Education',
  LANGUAGE_EDUCATION: 'Additional language classes',
  GENDER: 'Gender',
  SPECIAL_NEEDS: 'Special Needs Support',
  SPECIAL_NEEDS2: 'Special Needs',
  RELIGION: 'Religious Affiliation',
};

export const SneakPeek = ({ className, school }) => {
  const sneakPeekClasses = {
    [className]: className,
  };
  const [studentsPerTeacher, setStudentsPerTeacher] = useState();
  const {
    transportation_offered: hasTransportation,
    tuition,
    features,
    number_of_teachers: numberOfTeachers,
    number_of_students: numberOfStudents,
  } = school;
  const [sneakFeatures, setSneakFeatures] = useState({
    hasUniform: false,
    religion: '',
    gender: '',
    specialNeeds: false,
    languageProgram: [],
  });

  const offerTransportation = useMemo(
    () => hasTransportation || features.find((item) => item.name === 'Transportation Offered'),
    [hasTransportation]
  );

  const getSneakFeatures = () => {
    const auxSneakFeature = { ...sneakFeatures };
    features.forEach((feature) => {
      const groupName = feature.group.name;
      switch (groupName) {
        case FEATURES.GENDER:
          auxSneakFeature.gender = feature.name || feature.description;
          break;
        case FEATURES.LANGUAGE:
        case FEATURES.LANGUAGE_EDUCATION:
          auxSneakFeature.languageProgram.push(feature.name);
          break;
        case FEATURES.RELIGION:
          auxSneakFeature.religion = feature.name || feature.description;
          break;
        case FEATURES.UNIFORMS:
          auxSneakFeature.hasUniform = true;
          break;
        case FEATURES.SPECIAL_NEEDS:
        case FEATURES.SPECIAL_NEEDS2:
          auxSneakFeature.specialNeeds = true;
          break;
        default:
          break;
      }
    });

    setSneakFeatures({ ...auxSneakFeature });
  };
  useEffect(() => {
    getSneakFeatures();
  }, [school]);

  useEffect(() => {
    if (numberOfStudents && numberOfTeachers) {
      let aux;
      const w = numberOfTeachers;
      const h = numberOfStudents;

      const r = w === 0 ? 1 : w;
      aux = `${parseInt(h / r, 10)} : ${w / r}`;
      setStudentsPerTeacher(aux);
    }
  }, [numberOfStudents, numberOfTeachers]);

  const answerReligion = `${tString('schoolProfile:sneakPeek.features.religiousAffiliated.answers.affirmative')} ${
    sneakFeatures.religion
  }`;

  const questions = [
    {
      question: t('schoolProfile:sneakPeek.features.transportation.question'),
      answer: offerTransportation
        ? t('schoolProfile:sneakPeek.features.transportation.answers.affirmative')
        : t('schoolProfile:sneakPeek.features.transportation.answers.negative'),
      validation: offerTransportation,
      icon: 'https://static.schola.com/profile/sneak_peak_transportation.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.languagePrograms.question'),
      answer: sneakFeatures.languageProgram.join(', '),
      validation: sneakFeatures.languageProgram.length > 0,
      icon: 'https://static.schola.com/profile/sneak_peak_languages.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.specialNeeds.question'),
      answer: (
        <>
          {t('schoolProfile:sneakPeek.features.specialNeeds.answers.affirmative')}
          <a href="#specialneeds">{t('schoolProfile:sneakPeek.features.specialNeeds.answers.link')}</a>{' '}
          {t('schoolProfile:sneakPeek.features.specialNeeds.answers.content')}
        </>
      ),
      validation: sneakFeatures.specialNeeds,
      icon: 'https://static.schola.com/profile/sneak_peak_special_needs.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.ratio.question'),
      answer: studentsPerTeacher,
      validation: numberOfStudents && numberOfTeachers,
      icon: 'https://static.schola.com/profile/sneak_peak_teacher_ratio.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.tuition.question'),
      answer: tuition,
      validation: tuition && tuition !== 'More Information',
      icon: 'https://static.schola.com/profile/sneak_peak_cost.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.schoolType.question'),
      answer: sneakFeatures.gender,
      validation: sneakFeatures.gender,
      icon: 'https://static.schola.com/profile/sneak_peak_gender.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.religiousAffiliated.question'),
      answer: answerReligion,
      validation: sneakFeatures.religion,
      icon: 'https://static.schola.com/profile/sneak_peak_religion.webp',
    },
    {
      question: t('schoolProfile:sneakPeek.features.uniformsRequired.question'),
      answer: t('schoolProfile:sneakPeek.features.uniformsRequired.answers.affirmative'),
      validation: sneakFeatures.hasUniform,
      icon: 'https://static.schola.com/profile/sneak_peak_uniform.webp',
    },
  ];

  const hasAtLeastOneTrueValue = Object.values(sneakFeatures).some((item) => {
    if (Array.isArray(item)) {
      return item.length > 0;
    }
    return !!item;
  });

  const emptyQuestions = hasAtLeastOneTrueValue || hasTransportation;

  if (!emptyQuestions) return null;
  return (
    <div className={classNames(styles.container, sneakPeekClasses)}>
      <BlobSneakPeek className={styles.blob} />
      <div className={styles.mainGrid}>
        <div className={styles.titleContainer}>
          <img
            className={styles.blobStart}
            src="https://static.schola.com/profile/sneak-peak-background-3.svg"
            alt="sneak-peak-img-1"
            width="24"
            height="34"
            loading="lazy"
          />
          <div className={styles.titleSeparation}>
            <h2 className={styles.title}>
              {t('schoolProfile:sneakPeek.titleSeparation.titleFacts')}
              <br />
              {t('schoolProfile:sneakPeek.titleSeparation.titleSchool')}
            </h2>
          </div>
          <img
            className={styles.blobEnd}
            src="https://static.schola.com/profile/sneak-peak-background-2.svg"
            alt="sneak-peak-image-2"
            width="185"
            height="29"
          />
        </div>

        <div className={styles.questionsGrid}>
          {questions.map(
            (item, idx) =>
              item.validation && (
                <article key={idx} className={styles.article}>
                  <figure>
                    <img width="48" height="48" src={item.icon} alt="schola illustration" />
                  </figure>
                  <div>
                    <h3 className="heading-5">{item.question}</h3>
                    <p>{item.answer}</p>
                  </div>
                </article>
              )
          )}
        </div>
      </div>
    </div>
  );
};
SneakPeek.propTypes = {
  className: PropTypes.string,
};
