import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import URLSearchParams from 'url-search-params'; // eslint-disable-line
import { DevTools, store } from 'redux/store.js';
import Routes from 'components/Routes.js';
import './sass/App.scss';
import 'index.css';
import { getProfile } from 'util/auth';
import config from 'config';

import { I18nextProvider } from 'react-i18next';
import i18next from './translations/i18n';
import LinkedInTag from 'react-linkedin-insight';
import ModalLanguage from 'components/pages/scholamatch/components/ModalLangauge/ModalLanguage';
import { useShowModalOnFirstVisit } from 'hooks/useShowModalOnFirstVisit';
import { useRouterHistory } from 'react-router';

require('autotrack');
// Facebook tracking
require('components/tracking/facebook_pixel');
// Hotjar tracking
// require('components/tracking/hotjar')
// MXCF tracking
// require('components/tracking/mcfx');
// Freshpaint
require('components/tracking/freshpaint');
// ui mountain
// require('components/tracking/mountain');

// Google Analytics
const gaKey = config.google.analytics.key;

if (gaKey) {
  window.ga('create', gaKey, 'auto');

  // Only require the plugins you've imported above.
  window.ga('require', 'eventTracker');
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'urlChangeTracker');
  window.ga('send', 'pageview');
}

window.URLSearchParams = URLSearchParams; // make ie happy

if (process.env.REACT_APP_ENV === 'production') {
  const partnerId = config.linkedin.partnerId;
  LinkedInTag.init(partnerId, 'px', false);
  // const conversionId = '';
  // LinkedInTag.track(conversionId);
}

var elem = document.createElement('script');
elem.src = `https://maps.googleapis.com/maps/api/js?key=${config.google.places.apiKey}&libraries=places&loading=async`;
elem.type = 'text/javascript';
elem.id = 'gmaps-script-loader';
elem.async = true;
elem.defer = true;
document.body.appendChild(elem);

// <!-- Global site tag (gtag.js) - Google Ads-->
var elem2 = document.createElement('script');
elem2.src = `https://www.googletagmanager.com/gtag/js?id=${config.google.gtag.id}`;
elem2.type = 'text/javascript';
elem2.id = 'gtag-script-loader';
elem2.async = true;
elem2.defer = true;
document.body.appendChild(elem2);

var elem3 = document.createElement('script');
elem3.type = 'text/javascript';
var code = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${config.google.gtag.id}');  
`;
try {
  elem3.appendChild(code);
} catch (error) {
  elem3.text = code;
}
document.body.appendChild(elem3);

// Disabled to improve performance
// if (process.env.REACT_APP_ENV === 'production') {
//   // TikTok
//   var elem5 = document.createElement('script');
//   elem5.type = 'text/javascript';
//   var code5 = `
//   !function (w, d, t) {
//     w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

//     ttq.load('C3VR1H1U9OSLU1GC7NCG');
//     ttq.page();
//   }(window, document, 'ttq');
//   `;
//   try {
//     elem5.appendChild(code);
//   } catch (error) {
//     elem5.text = code5;
//   }
//   document.body.appendChild(elem5);
// }

const App = () => {
  // theming
  const [selectedTheme, setTheme] = useState('light');
  useEffect(() => {
    changeTheme(selectedTheme);
  }, [selectedTheme]);
  const changeTheme = (theme) => {
    setTheme(theme);
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${selectedTheme}`);
  };

  // Avoid displaying language modal in login route
  const currentPath = window.location.pathname;
  const routesToHideModal = ['/login'];

  // const { showModal, handleLanguage, onClose } = useShowModalOnFirstVisit();
  return (
    <div>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <div>
            {/* {showModal && !routesToHideModal.includes(currentPath) && (
              <ModalLanguage showModal={showModal} handleLanguage={handleLanguage} onClose={onClose} />
            )} */}
            <Routes />
          </div>
        </Provider>
      </I18nextProvider>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
