import React, { useState } from 'react';
import StarsExperience from './StarsExperience';
import './rateExperience.scss';
import { Button } from 'schola-components';
import { t } from 'components/translation/Translation';

const RateExperience = ({ setState, state, increaseStep, rateGeneral, setRateGeneral }) => {
  const rates = [
    { label: t('schoolProfile:reviews.areas.academics'), name: 'academics', value: state.academics },
    { label: t('schoolProfile:reviews.areas.tachers'), name: 'teachers', value: state.teachers },
    {
      label: t('schoolProfile:reviews.areas.schoolEnvironment'),
      name: 'schoolEnvironment',
      value: state.schoolEnvironment,
    },
    { label: t('schoolProfile:reviews.areas.schoolCulture'), name: 'schoolCulture', value: state.schoolCulture },
    { label: t('schoolProfile:reviews.areas.sports'), name: 'sports', value: state.sports },
    {
      label: t('schoolProfile:reviews.areas.clubsAndActivities'),
      name: 'clubsAndActivities',
      value: state.clubsAndActivities,
    },
    {
      label: t('schoolProfile:reviews.areas.diversityCulture'),
      name: 'diversityCulture',
      value: state.diversityCulture,
    },
    { label: t('schoolProfile:reviews.areas.facilities'), name: 'facilities', value: state.facilities },
  ];

  const [error, setError] = useState('');

  const handleNext = () => {
    if (!state.general) {
      setError('Please, Complete General Experience');
    } else {
      increaseStep();
    }
  };

  return (
    <div>
      <h2 className="spacing-mb-32 spacing-pl-16">{t('schoolProfile:reviews.rateExperience')}</h2>
      <div className="ratesWrapper">
        <div className="ratesScroll">
          <div className="generalRatingBlock spacing-pb-32 spacing-mb-32">
            <p className="body-large spacing-mb-16">{t('schoolProfile:reviews.generalExperience')}</p>
            <StarsExperience
              name="general"
              state={state}
              setState={setState}
              value={state.general}
              spaceBetween={18}
              size={28}
              color="#F0AD1B"
            />
          </div>
          {rates.map((item, index) => (
            <div key={`Rate Item Experience: ${index}`} className="review-row spacing-mb-32">
              <p className="body-large spacing-mb-16">{item.label}</p>
              <StarsExperience
                name={item.name}
                state={state}
                setState={setState}
                value={item.value}
                spaceBetween={18}
                size={28}
                color="#F0AD1B"
                onlyView={false}
              />
            </div>
          ))}
        </div>

        <div className="ratesWrapper_shadow" />
      </div>

      <p className="warningValidation spacing-mt-32">{error}</p>

      <div className="bigModalReview_buttons spacing-mt-24" style={{ justifyContent: 'flex-end' }}>
        <Button color="primarylegacy" onClick={handleNext}>
          {t('schoolProfile:reviews.nextButton')}
        </Button>
      </div>
    </div>
  );
};

export default RateExperience;
