import { combineReducers } from 'redux';
import getApiReducer, { defaultState } from '../util/get-api-reducer';

import { GET_FEATURES, GET_FEATURED_LIST, SET_USER_CLICK, ADD_SUGGESTION } from './feature-actions';

const setfeaturesid = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_FEATURES_ID':
      return action.data;
    default:
      return state;
  }
};

const setsearchquery = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return action.data;
    default:
      return state;
  }
};

const getFeatureList = (state = defaultState, action) => {
  state = getApiReducer(GET_FEATURED_LIST)(state, action);
  switch (action.type) {
    case 'GET_FEATURED_LIST_SUCCESS':
    case 'GET_FEATURED_LIST_FAILURE':
      return state;
    default:
      return state;
  }
};

const reducers = combineReducers({
  getFeatures: getApiReducer(GET_FEATURES),
  setFeaturesids: setfeaturesid,
  setSearchQuery: setsearchquery,
  featureList: getFeatureList,
  setUserClick: getApiReducer(SET_USER_CLICK),
  sendSuggestion: getApiReducer(ADD_SUGGESTION),
});

export default reducers;
