import client from '../client';

const axios = require('axios');

export const _addLog = async (payload) => {
  var ipData = await axios.get('https://api.ipify.org/?format=json');
  if (ipData) {
    payload.ip = ipData.data.ip;
  }
  return await client.post(`log-auths`, payload);
};
