import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetSchoolByIdQuery, useGetStudentsQuery } from 'redux/api/school-api';
import { getGradesPerSchool, getMapGradeValues } from '../../utils';
import { getProfile } from 'util/auth';
import { StudentsCounter } from './students-counter';
import { StudentInputs } from './student-inputs';
import gradesArray from 'util/grades-array';

export const StudentsStage = ({ isError }) => {
  const { contactType } = useSelector((state) => state.contactSchool);
  const { schoolId } = useSelector((state) => state.profile);
  const students = useSelector((state) => state.contactSchool.students);

  const { data: school } = useGetSchoolByIdQuery(schoolId, {
    skip: contactType === 'multiple' || !schoolId,
  });

  const grades =
    contactType === 'multiple' || !school?.grades ? getMapGradeValues(gradesArray) : getGradesPerSchool(school?.grades);

  const userId = useMemo(() => getProfile()?.sub, []);

  const { studentsByUser } = useGetStudentsQuery(undefined, {
    skip: !userId, // skip in true avoid the api call, in this case when there is no a user login
    selectFromResult: ({ data }) => ({
      studentsByUser: data?.map((student) => ({ name: student.name })),
    }),
  });

  const savedStudents = useSelector((state) => {
    const studentsNames = state.contactSchool.students.map(({ name }) => name);

    return studentsByUser?.filter((student) => {
      if (!studentsNames.includes(student.name)) {
        return student;
      }
    });
  });

  return (
    <div>
      <StudentsCounter students={students} />

      <hr className="spacing-my-24" />

      <div>
        {students?.map((student, idx) => (
          <StudentInputs
            key={student.id}
            data-testid="student-input"
            student={student}
            grades={grades}
            number={idx + 1}
            showHeader={students.length > 1}
            savedStudents={savedStudents}
            isUserLogin={Boolean(userId)}
            iserror={isError}
          />
        ))}
      </div>
    </div>
  );
};
