import React from 'react';
import { CheckCircleFilled, HeartOutlined, StarFilled } from '@ant-design/icons';
import { ConnectionReceive16, Location16 } from '@carbon/icons-react';
import { gradesLabel, handleRouteSchool } from '../utils';
import { _getFeatures } from 'controllers/features/features_controller';
import { SchoolPhoto } from '../school-photo/school-photo';
import { useToggle } from 'hooks/useToggle';
import styles from '../school-card.module.scss';
import { FeaturesMatched } from 'components/features-matched/features-matched';
import Button from 'components/button/Button';

export const VerticalCard = ({ school, handleContact }) => {
  const {
    name,
    distance,
    type,
    grades,
    open_enrollment: isOpenEnrollment,
    claimed,
    featuresMatched,
    profile_image: image,
  } = school;
  const [completeView, toggleCompleteView] = useToggle(false);

  return (
    <div className={styles.item}>
      <div className={styles.card_image_container}>
        <SponsoredTag />
        {/* <div className={styles.card_favorite}>
          <HeartOutlined style={{ fontSize: '22px' }} />
        </div> */}
        <SchoolPhoto direction="vertical" photo={image} />
      </div>
      <div className={styles.content}>
        <div className={styles.flex_content} style={featuresMatched.length === 0 ? { height: '170px' } : {}}>
          <div>
            <div className={styles.school_name}>
              <h5 className={styles.vertical_school_title}>{name}</h5>
              {claimed && <CheckCircleFilled style={{ color: '#0099CC', marginTop: '8px' }} />}
            </div>
            <div className={styles.school_info}>
              <div className={styles.school_location_type}>
                <Location16 /> <span>{distance.toFixed(1)} miles away</span> | <span>{type}</span>
              </div>
              <div className={styles.school_reviews_grades}>
                {/* <StarFilled style={{ color: '#F0AD1B' }} />
                <span>3.8 (800 Reviews)</span> |  */}
                <span>{gradesLabel(grades)} Grade</span>
              </div>
              {isOpenEnrollment && <EnrollingTag />}
            </div>
          </div>
          <FeaturesMatched
            className={completeView && styles.matchOverlay}
            featuresMatched={featuresMatched}
            completeView={completeView}
            toggleCompleteView={toggleCompleteView}
            showElements={2}
          />
        </div>
        <Button className="spacing-mb-12" fullWidth color="primary" onClick={handleContact}>
          Request Information
        </Button>
        <Button fullWidth color="secondary" onClick={() => handleRouteSchool(school)}>
          View Profile
        </Button>
      </div>
    </div>
  );
};

export const SponsoredTag = () => {
  return (
    <div className={styles.card_bestmatch}>
      <img src="https://static.schola.com/scholamatch/bestmatch-cup.svg" alt="Schola Icon" />
      Sponsored
    </div>
  );
};

const EnrollingTag = () => {
  return (
    <div className={styles.school_enrolling}>
      <p className="school-info-chip spacing-mt-16">
        <ConnectionReceive16 /> &nbsp; <span>Enrolling</span>
      </p>
    </div>
  );
};
