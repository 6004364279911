import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectorOptions = {
  order: ['localStorage' /* , 'querystring', 'navigator', 'htmlTag' */],
  lookupQuerystring: 'lng',
  caches: ['localStorage'],
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init(
    {
      interpolation: { escapeValue: false },
      ns: [
        'schola',
        'parent',
        'landing',
        'landingPage',
        'referralLanding',
        'onboarding',
        'ride',
        'schoolProfile',
        'results',
        'addBoard',
        'scholaMatch',
        'aboutUs',
        'contactSchool',
      ],
      defaultNS: 'schola',
      fallbackLng: 'en',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      react: {
        useSuspense: true,
      },
      detection: detectorOptions,
    },
    (err, t) => {
      if (err) console.error('Error initializing i18next:', err);
    }
  );

export default i18next;
