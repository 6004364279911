import React, { useState, useEffect } from 'react';
import './likeQuestion.scss';
import { t } from 'components/translation/Translation';
import { Button } from 'schola-components';

const items = [
  { item: 'supportive teachers', text: t('schoolProfile:reviews.items.supportive_teachers') },
  { item: 'safety', text: t('schoolProfile:reviews.items.safety') },
  { item: 'excellent enviroment', text: t('schoolProfile:reviews.items.excellent_enviroment') },
  { item: 'extracurricular classes', text: t('schoolProfile:reviews.items.extracurricular') },
  { item: 'after school programs', text: t('schoolProfile:reviews.items.after_school') },
  { item: 'director', text: t('schoolProfile:reviews.items.director') },
  { item: 'community involvement', text: t('schoolProfile:reviews.items.community_involvement') },
  { item: 'facilities', text: t('schoolProfile:reviews.items.facilities') },
  { item: 'staff', text: t('schoolProfile:reviews.items.staff') },
  { item: 'booster club', text: t('schoolProfile:reviews.items.booster_club') },
  { item: 'PTA - Parent teacher association', text: t('schoolProfile:reviews.items.PTA') },
];

const LikeQuestion = ({ schoolName, state, setState, decreaseStep, increaseStep }) => {
  const [selectedLikes, setSelectedLikes] = useState([]);

  useEffect(() => {
    setSelectedLikes(state);
  }, []);

  const removeOption = (option, id) => {
    let optionClicked = document.getElementById(id);
    if (optionClicked) optionClicked.classList.remove('selected');
    setSelectedLikes((items) => items.filter((i) => i !== option));
    setState((items) => items.filter((i) => i !== option));
  };

  const selectOption = (option, id) => {
    if (!selectedLikes.includes(option.item) && !state.includes(option.item)) {
      setSelectedLikes((oldArray) => [...oldArray, option.item]);
      setState((oldArray) => [...oldArray, option.item]);
      // changeColor(id);
    } else if (selectedLikes.includes(option.item) && state.includes(option.item)) {
      removeOption(option.item, id);
    }
  };

  const [error, setError] = useState('');
  const handleNext = () => {
    if (!state.length) {
      setError('Please, Choose at Least One Option');
    } else {
      increaseStep();
    }
  };

  return (
    <section className="reviewLikeQuestion" id="reviewLikeQuestion" data-testid="reviewLikeQuestion">
      <h2 className="heading-h2 reviewLikeQuestion-title">
        {t('schoolProfile:reviews.whatDidYouLike', { schoolName })}
      </h2>
      <div className="reviewLikeQuestion__tag-container" data-testid="reviewLikeContainer">
        {items.map((item, i) => {
          return (
            <span
              key={i}
              id={`option-${i}`}
              data-testid="reviewLikeTag"
              className={`reviewLikeQuestion__tag-item ${selectedLikes?.includes(item.item) && 'selected'}`}
              onClick={() => selectOption(item, `option-${i}`)}>
              {item.text}
            </span>
          );
        })}
      </div>

      <p className="warningValidation spacing-mt-32">{error}</p>

      <div className="bigModalReview_buttons">
        <Button color="primarylegacy" onClick={decreaseStep}>
          {t('schoolProfile:reviews.prevButton')}
        </Button>
        <Button color="primarylegacy" onClick={handleNext}>
          {t('schoolProfile:reviews.nextButton')}
        </Button>
      </div>
    </section>
  );
};

export default LikeQuestion;
