import { useState, useEffect } from 'react';

function useHeightScreen() {
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => setHeight(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return height;
}

export default useHeightScreen;
