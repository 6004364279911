import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './school-facilities.module.scss';

export const SchoolFacilitiesCard = ({ className, image, title, description }) => {
  const defaultImage = 'https://static.schola.com/profile/placeholder-facilities.webp'; // Placeholder image
  const SchoolFacilitiesCardClasses = {
    [className]: className,
    [styles.facility_card]: true,
  };
  return (
    <div className={classNames(SchoolFacilitiesCardClasses)}>
      <img src={image || defaultImage} className={`${styles.facility_image} spacing-mb-16`} />
      <div className={styles.facility_content}>
        <h3 className={`${styles.facility_title} spacing-mb-20`}>{title}</h3>
        <p className={styles.facility_description}>{description}</p>
      </div>
    </div>
  );
};
SchoolFacilitiesCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
