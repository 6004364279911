import React from 'react';
import { SmileOutlined, MehOutlined, TrophyOutlined } from '@ant-design/icons';
import styles from './match-label.module.scss';
import classNames from 'classnames';

/** matchLevel prop:
 * 'good' | 'normal' | 'partner'
 */
export const MatchLabel = ({ className, matchLevel = 'normal' }) => {
  const matchIcon = {
    good: <SmileOutlined />,
    normal: <MehOutlined />,
    partner: <TrophyOutlined />,
  };

  const labelStyles = {
    [className]: true,
    [styles.good]: matchLevel === 'good',
    [styles.normal]: matchLevel === 'normal',
    [styles.partner]: matchLevel === 'partner',
  };

  const levelStyles = {
    [styles.good]: matchLevel === 'good',
    [styles.normal]: matchLevel === 'normal',
    [styles.partner]: matchLevel === 'partner',
  };

  const matchLabel = {
    good: 'Recommended Match',
    normal: 'Recommended Match',
    partner: 'Schola Partner School',
  };

  return (
    <div className={styles.wrapper}>
      <span className={classNames(styles.label, labelStyles)}>
        {matchIcon[matchLevel]}
        <p>{matchLabel[matchLevel]}</p>
      </span>
      <span className={classNames(styles.ornament, levelStyles)} />
    </div>
  );
};
