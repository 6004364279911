export const createMap = (elementId, options) => {
  return new google.maps.Map(document.getElementById(elementId), options);
};

export const createPanorama = (elementId, options) => {
  return new google.maps.StreetViewPanorama(document.getElementById(elementId), options);
};

export const createMarker = (map, position) => {
  return new google.maps.Marker({
    position,
    icon: 'https://static.schola.com/school-blue-pin.svg',
    map,
  });
};
