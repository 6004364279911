import React from 'react';
import PropTypes from 'prop-types';
import './skeleton.scss';

const Skeleton = ({ height = 100, lenght = 1, className }) => {
  const loaders = Array(lenght).fill('');

  return loaders.map((_, index) => (
    <div key={`Loader-${index}`} className={`skeletonLoader ${className}`} style={{ height: `${height}px` }} />
  ));
};

export default Skeleton;

Skeleton.propTypes = {
  height: PropTypes.number,
  length: PropTypes.number,
  className: PropTypes.string,
};
