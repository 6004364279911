import { useMemo } from 'react';
import { useToggle } from 'hooks/useToggle';
import gradesArray from 'util/grades-array';
import { ChevronDown20, ChevronUp20 } from '@carbon/icons-react';
import styles from './grades-dropdown.module.scss';

export const GradeDropdown = ({ handleGrade, gradeQuery }) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const gradesOptions = useMemo(() => ['all', ...gradesArray], []);

  return (
    <div className={styles.gradesDrop}>
      <button onClick={toggleOpen} className={styles.buttonToggle}>
        <span>{gradeQuery}</span>
        {!isOpen ? <ChevronDown20 /> : <ChevronUp20 />}
      </button>
      {isOpen && (
        <div className={styles.gradesList}>
          {gradesOptions.map((grade) => (
            <div key={grade} className={styles.item}>
              <label htmlFor={grade}>{grade}</label>
              <input
                style={{ height: '12px', width: 'auto' }}
                type="checkbox"
                id={grade}
                name={grade}
                value={grade}
                onChange={() => handleGrade(grade)}
                checked={gradeQuery.split(',').includes(grade)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
