import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { handleInput } from 'redux/slices/contact-school.slice';
import { t } from 'components/translation/Translation';
import styles from './language-selector.module.scss';

export const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.contactSchool);

  const languages = [
    { label: t('contactSchool:message.en'), value: 'en' },
    { label: t('contactSchool:message.es'), value: 'es' },
  ];

  const handleMessage = ({ target }) => {
    const { name, value } = target;
    dispatch(handleInput({ name, value }));
  };

  useEffect(() => {
    if (language === '') {
      dispatch(handleInput({ name: 'language', value: i18next.language }));
    }
  }, []);

  return (
    <>
      <p className={styles.label}>{t('contactSchool:language')}</p>
      <div className={`${styles.langSelector} spacing-mb-24`}>
        {languages.map((lang) => (
          <button
            key={lang.value}
            value={lang.value}
            name="language"
            className={`${styles.languageButton} ${language === lang.value ? styles.selected : ''}`}
            onClick={(e) => handleMessage(e)}>
            {lang.label}
          </button>
        ))}
      </div>
    </>
  );
};
