import classNames from 'classnames';
import styles from './button-retool.module.scss';

export const ButtonRetool = ({ children, color = 'main', ...props }) => {
  const buttonClasses = {
    [props.className]: true,
    [styles.pro]: color === 'pro',
    [styles.green]: color === 'green',
  };

  return (
    <button {...props} className={classNames(styles.buttonRetool, buttonClasses)}>
      <span className={null}>{children}</span>
    </button>
  );
};
