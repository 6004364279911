import React from 'react';
import Button from 'components/button/Button';
import styles from './banner-card.module.scss';

export const BannerCard = ({ title, description, buttonLabel, onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content_info}>
        <h3>{title}</h3>
        <p>{description}</p>
        <Button className={styles.button} color="green" onClick={onClick}>
          {buttonLabel}
        </Button>
      </div>
      <div className={styles.figure_content}>
        <div>
          <img
            width={180}
            src="https://static.schola.com/illustrations/schola-connect-figure.png"
            alt="schola coneect figure"
          />
        </div>
      </div>
    </div>
  );
};
