import { useEffect, useState } from 'react';
import { _getGeoLocation } from 'controllers/geolocation/geolocation_controller';

export const getDefaultLocation = () => {
  const phoenix = {
    currentLat: 33.448376,
    currentLong: -112.07403,
  };

  const boston = {
    currentLat: 42.3600825,
    currentLong: -71.0588801,
  };

  if (process.env.REACT_APP_ENV === 'devlocal') {
    return boston;
  }

  return phoenix;
};

export const useLocation = () => {
  const [location, setLocation] = useState({
    currentLat: null,
    currentLong: null,
  });

  const defaultLocation = getDefaultLocation();

  useEffect(() => {
    // if (process.env.REACT_APP_ENV === 'devlocal') {
    //   setLocation(defaultLocation);
    //   return;
    // }

    if (!navigator.geolocation) {
      console.log("This browser doesn't support location access.");
      _getGeoLocation().then(
        (result) => {
          setLocation({
            currentLat: result.latitude,
            currentLong: result.longitude,
          });
        },
        (error) => {
          console.log('Error getting location from _getGeoLocation:', error);
          setLocation(defaultLocation);
        }
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            currentLat: position.coords.latitude,
            currentLong: position.coords.longitude,
          });
        },
        () => {
          console.log('Error getting location from navigator.geolocation.');
          _getGeoLocation().then(
            (result) => {
              setLocation({
                currentLat: result.latitude,
                currentLong: result.longitude,
              });
            },
            (error) => {
              console.log('Error getting location from _getGeoLocation:', error);
              setLocation(defaultLocation);
            }
          );
        },
        { enableHighAccuracy: true }
      );
    }
  }, []);

  return { location };
};

export default useLocation;
