import client from 'redux/client';

export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const ADD_STUDENT = 'ADD_STUDENT';
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS';
export const DELETE_STUDENT = 'DELETE_STUDENT';

export function getStudents(userId) {
  return {
    type: GET_STUDENTS,
    promise: client.get(`user/${userId}/students`),
  };
}

export function getStudent(userId, studentId) {
  return {
    type: GET_STUDENT,
    promise: client.get(`user/${userId}/students/${studentId}`),
  };
}

export function addStudent(userId, studentData) {
  const formData = new FormData();
  formData.append('name', studentData.name);
  formData.append('grade', studentData.grade);
  formData.append('birthdate', studentData.birthdate);
  formData.append('file', studentData.file);
  formData.append('parent_relationship', studentData.parent_relationship);
  formData.append('enrollment_year', studentData.enrollment_year);

  return {
    type: ADD_STUDENT,
    promise: client.post(`user/${userId}/students`, formData),
  };
}

export function updateStudent(userId, studentId, studentData) {
  const formData = new FormData();
  formData.append('name', studentData.name);
  formData.append('grade', studentData.grade);
  formData.append('birthdate', studentData.birthdate);
  formData.append('file', studentData.file);
  formData.append('parent_relationship', studentData.parent_relationship);
  formData.append('enrollment_year', studentData.enrollment_year);
  return {
    type: UPDATE_STUDENTS,
    promise: client.post(`user/${userId}/students/${studentId}`, formData),
  };
}

export function deleteStudent(userId, studentId) {
  return {
    type: DELETE_STUDENT,
    promise: client.delete(`user/${userId}/students/${studentId}`),
  };
}
