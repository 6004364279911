import React from 'react';
import styles from '../school-card.module.scss';

export const SchoolPhoto = ({ photo, direction }) => {
  return (
    <div className={direction === 'vertical' ? styles.vertical_card_image : styles.card_image}>
      <img src={photo || 'https://static.schola.com/profile/placeholderbig.png'} alt="Schola Image" />
    </div>
  );
};
