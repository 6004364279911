/* eslint-disable func-names */
module.exports = function (
  actionTypePrefix,
  initialValue,
  requestReducer,
  successReducer,
  failureReducer,
  loadingReducer,
  resetReducer
) {
  return function (state = initialValue, action) {
    let method;
    switch (action.type) {
      case `${actionTypePrefix}_REQUEST`:
        method = requestReducer;
        break;
      case `${actionTypePrefix}_SUCCESS`:
        method = successReducer;
        break;
      case `${actionTypePrefix}_FAILURE`:
        method = failureReducer;
        break;
      case `${actionTypePrefix}_LOADING`:
        method = loadingReducer;
        break;
      case `${actionTypePrefix}_RESET`:
        method = resetReducer;
        break;
      default:
        break;
    }

    if (method) {
      return method(state, action);
    }

    return state;
  };
};
