import React, { useState, useRef, useEffect } from 'react';
import { StarFilled32 } from '@carbon/icons-react';
import './starsExperience.scss';

const StarsExperience = ({
  name,
  setState,
  state,
  value,
  spaceBetween,
  size,
  onlyView,
  notPointer,
  color,
  outlineColor,
  lenght = 5,
}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setRating(value);
  }, [value]);

  const buttonRef = useRef();

  const handleClick = (name, index) => {
    setRating(index);

    // setState(index)
    setState({
      ...state,
      [name]: index,
    });
  };

  return (
    <div className="starsExperience" style={{ gap: `${spaceBetween}px` }}>
      {[...Array(lenght)].map((star, index) => {
        index += 1;
        return (
          <button
            key={index}
            name={name}
            ref={buttonRef}
            onClick={onlyView ? null : () => handleClick(name, index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            style={notPointer ? { cursor: 'default' } : { cursor: 'pointer' }}>
            <span className="starsExperience_item">
              <StarFilled32
                fill={index <= rating ? color : '#C7CCD4'}
                style={{ width: `${size}px`, height: `${size}px` }}
                className={onlyView ? null : index <= (rating || hover) ? 'starOn' : 'starOff'}
              />
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default StarsExperience;
