import { useState } from 'react';
import { useToggle } from 'hooks/useToggle';
import { _batchEditLeads } from 'controllers/leads/leads_controller';
import { useStatus } from 'hooks/useStatus';

export const useUnfollowApplication = (trigger, confirmUnfollow) => {
  const [isNotInterested, toggleNotInterested] = useToggle(false);

  const [stage, setStage] = useState('confirmation');
  const onSuccesStage = () => setStage('success');
  const onAppliedStage = () => setStage('applied');

  let reasonsIds = {
    school_dont_contact_me: '7',
    dont_match: '6',
    another_school: '4',
    other: null,
  };

  const [optionToLeave, setOptionToLeave] = useState(null);
  const [reasonId, setReasonId] = useState(null);

  const [otherReason, setOtherReason] = useState('');
  const handleOtherReason = (event) => setOtherReason(event.target.value);

  const handleSelectOption = ({ target }) => {
    const { value } = target;

    setReasonId(reasonsIds[value]);
    setOptionToLeave(value);
  };

  const initialLeaveSchoolState = {
    lead_id: null,
    school_id: null,
    school: '',
    grade: '',
    transportation_need: null,
    profile_image: null,
  };

  const [leaveSchool, setLeaveSchool] = useState(initialLeaveSchoolState);

  const handleNotInterested = ({
    lead_id: leadId,
    school_id: schoolId,
    school,
    grade,
    transportation_need: transportationNeed,
    profile_image: profileImage,
  }) => {
    toggleNotInterested();

    setLeaveSchool({
      lead_id: leadId,
      school_id: schoolId,
      school,
      grade,
      transportation_need: transportationNeed,
      profile_image: profileImage,
    });
  };

  const onResetUnfollow = () => {
    setOptionToLeave(null);
    setLeaveSchool(initialLeaveSchoolState);
    setStage('confirmation');
    toggleNotInterested();
  };

  const applicationId = leaveSchool.lead_id;

  const schoolId = leaveSchool.school_id;
  const leads = [applicationId];
  let other = otherReason || 'other';
  const values = {
    status: 'archived',
    reason_id: reasonId,
    ...(!reasonId && { reason_other: other }),
  };

  const { onLoading, onSuccess, status } = useStatus();

  const updateLeadStatus = async () => {
    onLoading();
    const edit = await _batchEditLeads(schoolId, leads, values);

    if (edit.ok) {
      trigger();
      confirmUnfollow();
      onSuccess();
      return edit.ok;
    }
  };

  return {
    stage,
    onSuccesStage,
    onAppliedStage,
    isNotInterested,
    optionToLeave,
    handleSelectOption,
    leaveSchool,
    handleNotInterested,
    onResetUnfollow,
    updateLeadStatus,
    unfollowStatus: status,
    reasonId,
    otherReason,
    handleOtherReason,
    toggleNotInterested,
  };
};
