import React, { Suspense } from 'react';
import { Translation } from 'react-i18next';
import Parser from 'html-react-parser';
import i18next from 'translations/i18n';

export const languages = {
  en: 'en',
  es: 'es',
};

export function getTransKey(line, subNS) {
  const split = line.split(' ');
  const key =
    split.length == 1
      ? split[0]
      : split.length == 2
      ? `${split[0]}_${split[1]}`
      : split.length >= 3
      ? `${split[0]}_${split[1]}_${split[2]}`
      : undefined;
  if (key) {
    if (subNS) {
      return `${subNS}.${key}`;
    }
    return key;
  }
  console.error(`The line:${line}`, 'Does not have a key');
}

export function tl(line, subNS) {
  const id = getTransKey(line, subNS);

  const trans = (
    <Translation>
      {(t, { i18n }) => {
        let lineTrans = t(id);
        if (lineTrans == id) lineTrans = line;
        return Parser(lineTrans);
      }}
    </Translation>
  );

  if (id) return trans;
}

export function t(id, params) {
  const trans = (
    <Translation>
      {(t, { i18n }) => {
        return Parser(t(id, params));
      }}
    </Translation>
  );

  if (id) return trans;
}

// Translate simple string
export const tString = (line, ns, params) => {
  const id = getTransKey(line, ns);
  const trans = i18next.t(id, params);
  return id == trans ? line : trans;
};
