import { FEATURES as usedFeatures } from '../utils/get-insights-features';

export const useFeaturesJoin = (features, applyExclusion = true) => {
  /** feature groups that already are shown in another section, exclude to avoid duplicate groups */
  const excludedCategories = Object.values(usedFeatures);

  if (features && features.length > 0) {
    const featureCategories = features.reduce((acc, feature) => {
      const groupName = feature.group.name;
      const isTopFeature = feature._pivot_is_top;

      if (!acc[groupName] && (!applyExclusion || !excludedCategories?.includes(groupName))) {
        acc[groupName] = [];
      }

      if (feature._pivot_type === 'included' && acc[groupName]) {
        acc[groupName]?.push(feature);
      }

      if (isTopFeature && feature._pivot_type === 'included') {
        if (!acc.topFeatures) {
          acc.topFeatures = [];
        }
        acc.topFeatures.push(feature);
      }
      return acc;
    }, {});

    /** Select and apart learning environments to a separate object */
    let schoolEnvironment;
    if (featureCategories['School Learning Environment']) {
      schoolEnvironment = featureCategories['School Learning Environment'];
      delete featureCategories?.['School Learning Environment'];
    }

    let schoolFacilities;
    if (featureCategories['School Facilities']) {
      schoolFacilities = featureCategories['School Facilities'];
      delete featureCategories?.['School Facilities'];
    }

    return { featureCategories, schoolEnvironment, schoolFacilities };
  }

  return {};
};
