import React, { useMemo } from 'react';
import styles from './sponsored-school.module.scss';
import { ButtonRetool, ChipFeature, ProfileImage } from '..';
import { CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { categoriesRequested } from '../../utils';

export const SponsoredSchool = ({ featuresSelected, school, handleContact, className }) => {
  const { categories, isTypeAvailable } = useMemo(() => {
    const featuresRequested = { ...featuresSelected };
    delete featuresRequested.labels;

    const categories = categoriesRequested(featuresRequested);
    const transformedTypes = categories[0] ? categories[0].bucket.map((item) => item.replace(/\+/g, ' ')) : '';

    const isTypeAvailable = transformedTypes.includes(school?.type);

    return { categories, isTypeAvailable };
  }, [school]);

  return (
    <section className={`${styles.sponsoredSection} ${className}`}>
      <h2 className={styles.title}>
        You Matched With a <br />
        <b>👇 Schola Sponsored School 👇</b>
      </h2>
      <div className={styles.card}>
        <figure className={styles.image}>
          <ProfileImage photo={school.profile_image} name={school.name} />
        </figure>
        <div className={styles.content}>
          <div className={styles.name}>
            <h4>{school.name}</h4>
            <CheckCircleFilled style={{ color: 'rgba(0, 107, 143, 1)' }} />
          </div>
          <p className="spacing-mt-8">{`${school.address}, ${school.city}, ${school.state}, ${school.zip}`}</p>
          <div className={styles.chipsWrapper}>
            <FeatureSponsored label={categories[0].label} isAvailable={isTypeAvailable} />

            {categories.slice(1)?.map((category, idx) => (
              <FeatureSponsored
                key={idx}
                label={category.label}
                isAvailable={school?.featuresBySchool.some((feature) => category.bucket.includes(feature))}
              />
            ))}
          </div>

          <ButtonRetool color="pro" onClick={handleContact}>
            Start Application
          </ButtonRetool>
        </div>
      </div>
    </section>
  );
};

const FeatureSponsored = ({ label, isAvailable }) => {
  return (
    <ChipFeature className={styles.sponsoredChip}>
      {label}{' '}
      {isAvailable ? (
        <CheckCircleOutlined style={{ color: 'rgba(19, 82, 0, 1)' }} />
      ) : (
        <CloseCircleOutlined style={{ color: 'rgba(135, 77, 0, 1)' }} />
      )}
    </ChipFeature>
  );
};
