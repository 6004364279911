import React, { useState } from 'react';
import { ChevronDown20, ChevronUp20 } from '@carbon/icons-react';
import './school-about-faqs.scss';
import { useGetFAQsQuery } from 'redux/api/school-api';
import { Divider } from '../divider/divider';

export const SchoolAboutFaqs = ({ schoolId, className }) => {
  const { data: faqs, isSuccess } = useGetFAQsQuery(schoolId);

  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  if ((isSuccess && faqs.length === 0) || !faqs) return null;

  return (
    <>
      <div className={className}>
        <h3 className="school-about__faq-title">Frequently Asked Questions</h3>
        <div className="school-about__faq">
          {isSuccess &&
            faqs.map((faq, index) => (
              <div
                key={index}
                className={`school-about__faq-item ${openFaqIndex === index ? 'open' : ''}`}
                onClick={() => toggleFaq(index)}>
                <div className="school-about__faq-question">
                  <span>{faq.question}</span>
                  {openFaqIndex === index ? (
                    <ChevronUp20 size={24} color="#006B8F" />
                  ) : (
                    <ChevronDown20 size={24} color="#006B8F" />
                  )}
                </div>
                {openFaqIndex === index && <p className="school-about__faq-answer">{faq.answer}</p>}
              </div>
            ))}
        </div>
      </div>
      <Divider />
    </>
  );
};
