import React from 'react';
import classNames from 'classnames';
import { getFeatureIcon } from '../../pages/school-profile-page/utils/getFeatureIcon';

import styles from './feature-list-item.module.scss';
import { Checkmark16 } from '@carbon/icons-react';

/**
 * @typedef {object} Feature
 * @property {number | string} id - The feature ID.
 * @property {string} name - The name of the feature.
 * @property {object} group - The feature group.
 * @property {string} group.name - The name of the feature group.
 */

/**
 * @param {object} props
 * @param {Feature} props.feature
 */

export const FeatureListItem = ({ feature, type, active, onItemClick }) => {
  const listItemStyle = {
    [styles.checkItem]: type === 'CHECK',
    [styles.topItem]: type === 'TOP',
    [styles.programItem]: type === 'PROGRAM',
    [styles.modalItem]: type === 'MODAL',
    [styles.filterItem]: type === 'FILTER',
    [styles.active]: active,
  };

  const listPItemStyle = {
    [styles.pCheckItem]: type === 'CHECK',
    [styles.item]: type === 'TOP',
    [styles.pItem]: type === 'PROGRAM',
    [styles.pModalItem]: type === 'MODAL',
    [styles.pFilterItem]: type === 'FILTER',
    [styles.pActive]: active,
  };

  const spanItemStyle = {
    [styles.modalDot]: type === 'MODAL',
  };

  const ICON_ITEM = {
    TOP: feature.group ? getFeatureIcon(feature.group.name, '#3D7E94') : null,
    CHECK: <Checkmark16 />,
    MODAL: '.',
  };

  return (
    <div
      className={classNames(listItemStyle)}
      data-testid="feature-list-item"
      key={feature.id || feature.name}
      onClick={onItemClick ? () => onItemClick(feature.id) : null}>
      {feature.group && <span className={classNames(spanItemStyle)}>{ICON_ITEM[type]}</span>}
      <p className={classNames(listPItemStyle)}>{feature.name}</p>
    </div>
  );
};
