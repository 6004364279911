import React from 'react';
import PropTypes from 'prop-types';
import { tString } from 'components/translation/Translation';
import classNames from 'classnames';
import { FeatureListItem } from './feature-list-item/feature-list-item';
import { TOP_STYLE_CATEGORIES } from 'components/pages/school-profile/constants/top-style-categories';
import { STATUS_ICON } from 'components/pages/school-profile/constants/status-icon';
import styles from './feature-list.module.scss';

export const FeatureList = ({ features, subTitle, type }) => {
  const listStyle = {
    [styles.checkList]: type === 'CHECK',
    [styles.topList]: type === 'TOP',
    [styles.regularFeatures]: type === 'TOP' && TOP_STYLE_CATEGORIES.includes(subTitle),
    [styles.modalList]: type === 'MODAL',
    [styles.programFeatures]: type === 'PROGRAM',
  };

  const featureListContainerStyle = {
    [styles.featureListContainer]: type === 'CHECK' || 'TOP',
    [styles.featureListProgram]: type === 'PROGRAM',
  };

  return (
    <div className={classNames(featureListContainerStyle)}>
      {subTitle && (
        <div className={styles.subtitle}>
          {STATUS_ICON[subTitle]}
          <h4 className={`body-large ${styles.subtitleText}`}>{tString(subTitle)}</h4>
        </div>
      )}
      <div className={classNames(listStyle)} data-testid="feature-list">
        {features &&
          features.map((feature) => (
            <FeatureListItem
              key={feature.id}
              feature={feature}
              type={TOP_STYLE_CATEGORIES.includes(subTitle) ? 'PROGRAM' : type}
            />
          ))}
      </div>
    </div>
  );
};

FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
