import { planName } from './schoolUtils';
import { hasActivePlan } from './has-active-plan';

const goldBadge = 'https://static.schola.com/landing/schools/badge-gold.svg';
const silverBadge = 'https://static.schola.com/landing/schools/badge-silver.svg';

export const getBadge = (school) => {
  const plan = planName(school);
  const { plan_id: planId, subscription_end: subscriptionEnd } = school;

  const isSchoolPlanActive = hasActivePlan({
    plan_id: planId,
    subscription_end: subscriptionEnd,
  });

  const badges = {
    scholarecruiterpro: goldBadge,
    scholaplus: silverBadge,
    scholabasics: null,
  };

  if (isSchoolPlanActive) {
    return badges[plan];
  }
  return null;
};
