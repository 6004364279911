import Slider from 'react-slick';
import getVideoId from 'util/video';
import './video-slider.scss';
import PropTypes from 'prop-types';

const VideoSlider = ({ videos }) => {
  let slides = videos.map((video, i) => {
    let videoSrc;

    const videoInfo = getVideoId(video.embed_code);

    if (videoInfo && videoInfo.id && videoInfo.id !== '') {
      if (videoInfo.service === 'youtube') {
        videoSrc = `//www.youtube.com/embed/${videoInfo.id}?rel=0`;
        return (
          <div className="main-media" key={`video${i}`}>
            <iframe
              width="100%"
              height="415"
              src={videoSrc}
              srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${videoInfo.id}?autoplay=1><img src=https://img.youtube.com/vi/${videoInfo.id}/sddefault.jpg alt='School Video'><span>▶</span></a>`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={videoSrc}
              data-testid="about-video"
            />
          </div>
        );
      }
      if (videoInfo.service === 'vimeo') {
        videoSrc = `https://player.vimeo.com/video/${videoInfo.id}?rel=0`;
        return (
          <iframe
            width="100%"
            height="350"
            src={videoSrc}
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${videoSrc}><img src="https://vumbnail.com/${videoInfo.id}.jpg" alt='School Video'><span>▶</span></a>`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={videoSrc}
          />
        );
      }
    } else if (/youtube/.test(video.embed_code)) {
      videoSrc = `${video.embed_code}?rel=0`;
    } else {
      videoSrc = `${video.embed_code}?rel=0`;
    }
    return (
      <div className="main-media" key={`video${i}`}>
        <iframe
          width="100%"
          height="415"
          src={videoSrc}
          frameBorder="0"
          allowFullScreen
          title={videoSrc}
          data-testid="about-video"
        />
      </div>
    );
  });

  return (
    <div className="video-slider">
      <Slider>{slides}</Slider>
    </div>
  );
};

export default VideoSlider;

VideoSlider.propTypes = {
  videos: PropTypes.array.isRequired,
};
