import React from 'react';
import styles from './school-types.module.scss';
import { schoolTypes } from '../utils';
import { t, tString } from 'components/translation/Translation';
import { FeatureListItem } from 'components/feature-list/feature-list-item/feature-list-item';

const SchoolTypeSection = ({ title, chips, text, boldText }) => {
  return (
    <section className={styles.section_container}>
      <h4>{title}</h4>
      <div className={styles.chips_container}>
        {chips.map((chip, idx) => {
          return <FeatureListItem feature={{ name: chip, id: idx }} type="TOP" key={idx} />;
        })}
      </div>
      <div className={styles.paragraph}>
        <p>
          {boldText ? <b>{boldText}</b> : null} {text}
        </p>
      </div>
    </section>
  );
};
export const SchoolTypes = () => {
  const types = [
    {
      title: tString(schoolTypes['public district'].title),
      text: schoolTypes['public district'].description,
      chips: schoolTypes['public district'].chips,
    },
    {
      title: tString(schoolTypes['public charter'].title),
      text: schoolTypes['public charter'].description,
      chips: schoolTypes['public charter'].chips,
    },
    {
      title: tString(schoolTypes['public magnet'].title),
      text: schoolTypes['public magnet'].description,
      chips: schoolTypes['public magnet'].chips,
    },
    {
      title: tString(schoolTypes.private.title),
      text: schoolTypes.private.description,
      chips: schoolTypes.private.chips,
    },
  ];
  return (
    <div className={styles.container}>
      <section className={styles.header_section}>
        <div className={styles.text_container}>
          <h2>
            {tString('schoolProfile:scholaTypes.modalTitle.firstLine')}
            <span className={styles.bg}>{tString('schoolProfile:scholaTypes.modalTitle.underline')}</span>
            <br />
            {tString('schoolProfile:scholaTypes.modalTitle.secondLine')}
          </h2>
          <p>{tString('schoolProfile:scholaTypes.modalDescription')}</p>
        </div>
        <div className={styles.image_container}>
          <img src="https://static.schola.com/profile/school-types-image.svg" alt="schola-types" />
        </div>
      </section>
      <hr className={styles.line1} />
      <section className={styles.body_container}>
        {types.map((type, idx) => (
          <SchoolTypeSection
            key={idx}
            title={type.title}
            chips={type.chips}
            text={type.text}
            boldText={type?.boldText}
          />
        ))}
        <section className={styles.tip_section}>
          <div>
            <img src="https://static.schola.com/LogoFooterSchola.svg" alt="schola logo" />
          </div>
          <p className={styles.tip}>
            <b className={styles.boldTip}>{tString('schoolProfile:scholaTypes.scholaTip.firstBold')}</b>
            {tString('schoolProfile:scholaTypes.scholaTip.firstDescription')}
            <b className={styles.boldTip}>{tString('schoolProfile:scholaTypes.scholaTip.secondBold')}</b>
            {tString('schoolProfile:scholaTypes.scholaTip.secondDescription')}
          </p>
        </section>
      </section>
    </div>
  );
};
