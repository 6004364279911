import React, { useEffect, useRef } from 'react';
import { Warning24 } from '@carbon/icons-react';
import styles from './message-chip.module.scss';
import { t } from 'components/translation/Translation';

export const MessageChip = ({ className }) => {
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <div ref={ref} className={`${styles.container} ${className}`}>
      <Warning24 />
      <p>{t('contactSchool:feedback.error')}</p>
    </div>
  );
};
