import { useStatus } from 'hooks/useStatus';
import { useEffect, useState } from 'react';
import { getUserLeads } from 'redux/user/user-actions';

export const useApplications = (userId, dispatch) => {
  const { status, onLoading, onSuccess, onError } = useStatus();
  const [applications, setApplications] = useState([]);

  const pullApplications = async () => {
    onLoading();

    try {
      const userLeads = await dispatch(getUserLeads(userId));
      setApplications(userLeads.json);
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  let mounted = true;
  useEffect(() => {
    if (mounted) {
      pullApplications();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return { applications, status, pullApplications };
};
