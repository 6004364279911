import merge from 'util/merge';

const MAX_LEN = 64;
const getQaAdminUrl = () => {
  const { REACT_APP_CS_PROJECT, REACT_APP_CS_REPO, REACT_APP_CS_BRANCH } = process.env;

  const branchName = REACT_APP_CS_BRANCH ? REACT_APP_CS_BRANCH.substr(0, 15) : '';

  const rawSubDomain = `https://${REACT_APP_CS_PROJECT}-${REACT_APP_CS_REPO}-adminproxy-${branchName}`;
  const subDomain = rawSubDomain.toLowerCase().substr(0, MAX_LEN);
  return `${subDomain}.synsit.es`;
};

// old api key AIzaSyAZjX2US7Db9MU9cmlMcet3uqeqMHzr7MA
// AIzaSyAGViAnAAgEqMfLqT2DecluLnKLQaNyJAY
const defaults = {
  google: {
    places: {
      apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
    },
    analytics: {},
    gtag: {
      id: 'AW-811705801',
      tags: {
        compare_schools_parent_portal: '',
        create_campaign_apply: '',
        create_campaign_birthday_nuturing: '',
        create_campaign_enroll: '',
        create_campaign_re_enroll: '',
        create_custom_campaign: '',
        fill_claim_your_school_school_portal: '',
        open_apply_now_parent_portal: '',
        open_parent_portal_dashboard: '',
        open_request_demo_school_portal: '',
        open_request_info_parent_portal: '',
        open_schola_match_parent_portal: '',
        question_1_schola_match_parent_portal: '',
        question_2_schola_match_parent_portal: '',
        question_3_schola_match_parent_portal: '',
        question_4_schola_match_parent_portal: '',
        question_5_schola_match_parent_portal: '',
        question_6_schola_match_parent_portal: '',
        question_7_schola_match_parent_portal: '',
        question_8_schola_match_parent_portal: '',
        submit_claim_your_school_school_portal: '',
        submit_question_9_schola_match_parent_portal: '',
        submit_request_demo_school_portal: '',
        submit_request_info_parent_portal: '',
        submit_schedule_tour_parent_portal: '',
        view_profile_parent_portal_dashboard: '',
        scholamatch_contact: '',
        scholamatch_enter: '',
        scholamatch_request_info: '',
        scholamatch_save_to_dashboard: '',
        scholamatch_search: '',
        school_results_from_landing: '',
        school_results_from_scholamatch: '',
        launch_add_to_calendar: '',
        launch_view_booth_location: '',
        launch_your_free_campaign: '',
        launch_free_video: '',
        launch_go_to_claim: '',
        launch_schedule_a_call: '',
        launch_submit_claim: '',
        launch_get_in_touch: '',
      },
    },
  },
  linkedin: {
    partnerId: '2165556',
  },
  auth0: {
    domain: 'schola-staging.auth0.com',
    clientId: 'yXlGVIgA6lmior8gGMrq7mh9U5M5yANf',
    lockOptions: {
      configurationBaseUrl: 'https://cdn.auth0.com',
      auth: {
        params: {
          scope: 'openid email name picture user_metadata app_metadata identities',
        },
        redirect: true,
        redirectUrl: `${window.location.protocol}//${window.location.host}/redirect`,
        responseType: 'token',
      },
      container: 'auth0-lock',
      languageDictionary: {
        title: 'Schola®',
      },
      theme: {
        logo: `${window.location.protocol}//${window.location.host}/logo.svg`,
        primaryColor: '#8ac341',
      },
    },
    rememberLastLogin: false,
  },
  schola: {
    externalApplicationRequirePlan: false,
  },
};

const environments = {
  ci: {},
  devlocal: {
    adminHost: 'http://localhost:4000',
  },
  DIT: {
    adminHost: 'http://admin-dit-schola.sooryen.com:4000',
  },
  qa: {
    adminHost: getQaAdminUrl(),
  },
  development: {
    adminHost: 'https://dev-admin.schola.com',
    google: {
      places: {
        apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
      },
    },
  },
  staging: {
    adminHost: 'https://staging-admin.schola.com',
    google: {
      places: {
        apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
      },
    },
  },
  production: {
    adminHost: 'https://schools.schola.com',
    auth0: {
      domain: 'login.schola.com',
      clientId: 'MDO6P0ptV95MNdXCZsstv40c81kJ0SsG',
    },
    google: {
      places: {
        apiKey: 'AIzaSyCmgfy-8n5BfivBfPebUkawRLyhARyNWLw',
      },
      analytics: {
        key: 'UA-90035592-2',
      },
      gtag: {
        id: 'AW-811705801',
        tags: {
          compare_schools_parent_portal: 'AW-811705801/vMD-CI_wuvsBEMnLhoMD',
          create_campaign_apply: 'AW-811705801/_zH8CJzgi_wBEMnLhoMD',
          create_campaign_birthday_nuturing: 'AW-811705801/7TKtCLPS6_sBEMnLhoMD',
          create_campaign_enroll: 'AW-811705801/LBhxCOq16_sBEMnLhoMD',
          create_campaign_re_enroll: 'AW-811705801/eEvVCKXjgPwBEMnLhoMD',
          create_custom_campaign: 'AW-811705801/A-YaCNvsi_wBEMnLhoMD',
          fill_claim_your_school_school_portal: 'AW-811705801/R7FoCMOapvsBEMnLhoMD',
          open_apply_now_parent_portal: 'AW-811705801/cF4qCMDouvsBEMnLhoMD',
          open_parent_portal_dashboard: 'AW-811705801/XtVdCJbkpfsBEMnLhoMD',
          open_request_demo_school_portal: 'AW-811705801/etdhCOmNpvsBEMnLhoMD',
          open_request_info_parent_portal: 'AW-811705801/j3KqCIjKpfsBEMnLhoMD',
          open_schola_match_parent_portal: 'AW-811705801/Y0KdCK3x4PcBEMnLhoMD',
          question_1_schola_match_parent_portal: 'AW-811705801/gwM8CPfHpfsBEMnLhoMD',
          question_2_schola_match_parent_portal: 'AW-811705801/ciXeCP_9pvsBEMnLhoMD',
          question_3_schola_match_parent_portal: 'AW-811705801/DkKSCNX9pvsBEMnLhoMD',
          question_4_schola_match_parent_portal: 'AW-811705801/oMhnCI2Ap_sBEMnLhoMD',
          question_5_schola_match_parent_portal: 'AW-811705801/uVMBCO6vx_sBEMnLhoMD',
          question_6_schola_match_parent_portal: 'AW-811705801/ghejCIKHp_sBEMnLhoMD',
          question_7_schola_match_parent_portal: 'AW-811705801/QByYCJuMvPsBEMnLhoMD',
          question_8_schola_match_parent_portal: 'AW-811705801/D582CLWmx_sBEMnLhoMD',
          submit_claim_your_school_school_portal: 'AW-811705801/wNxACKKgpvsBEMnLhoMD',
          submit_question_9_schola_match_parent_portal: 'AW-811705801/XvckCJ37xfsBEMnLhoMD',
          submit_request_demo_school_portal: 'AW-811705801/aFFICNKzxvsBEMnLhoMD',
          submit_request_info_parent_portal: 'AW-811705801/IKv3CNXZuvsBEMnLhoMD',
          submit_schedule_tour_parent_portal: 'AW-811705801/IxCOCO-8pfsBEMnLhoMD',
          view_profile_parent_portal_dashboard: 'AW-811705801/JuJYCJHipfsBEMnLhoMD',
          scholamatch_contact: 'AW-811705801/TaPFCJDip5QDEMnLhoMD',
          scholamatch_enter: 'AW-811705801/9E2XCP3pp5QDEMnLhoMD',
          scholamatch_request_info: 'AW-811705801/8PeyCPKgoJQDEMnLhoMD',
          scholamatch_save_to_dashboard: 'AW-811705801/ubmgCImmoJQDEMnLhoMD',
          scholamatch_search: 'AW-811705801/k6gCCNacoJQDEMnLhoMD',
          school_results_from_landing: 'AW-811705801/gjimCIbN8bcDEMnLhoMD',
          school_results_from_scholamatch: 'AW-811705801/treuCIvfo7gDEMnLhoMD',
          launch_add_to_calendar: 'AW-811705801/0LS-CKro2McDEMnLhoMD',
          launch_view_booth_location: 'AW-811705801/tF_hCObP2McDEMnLhoMD',
          launch_your_free_campaign: 'AW-811705801/2GCUCIrZ2McDEMnLhoMD',
          launch_free_video: 'AW-811705801/9IfHCLylpscDEMnLhoMD',
          launch_go_to_claim: 'AW-811705801/PqWFCPmN2ccDEMnLhoMD',
          launch_schedule_a_call: 'AW-811705801/nzXyCI7j2McDEMnLhoMD',
          launch_submit_claim: 'AW-811705801/A4KaCL7m2McDEMnLhoMD',
          launch_get_in_touch: 'AW-811705801/1wjeCP2wpscDEMnLhoMD',
        },
      },
    },
  },
};

export default merge(defaults, environments[process.env.REACT_APP_ENV]);
