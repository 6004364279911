import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import { GET_LANDING_PAGE, GET_BULLETIN } from './landing-page-actions';
import Immutable from 'immutable';

const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: Immutable.Map(),
  error: false,
  validationErrors: Immutable.Map(),
});

const getBulletinBoard = (state = defaultState, action) => {
  const bulletinBoard = getApiReducer(GET_BULLETIN)(state, action);
  switch (action.type) {
    case 'GET_BULLETIN_SUCCESS':
      return bulletinBoard;
    default:
      return state;
  }
};

export default combineReducers({
  getLandingPage: getApiReducer(GET_LANDING_PAGE),
  bulletinBoard: getBulletinBoard,
});
