import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ArrowLeft32 } from '@carbon/icons-react';
import './modal.scss';
import { CloseOutlined } from '@ant-design/icons';

class Modal extends Component {
  componentDidMount() {
    if (this.props.isVisible) {
      this.setBodyOverflow(true);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.isVisible && nextProps.isVisible) {
      this.setBodyOverflow(true);
    } else if (this.props.isVisible && !nextProps.isVisible) {
      this.setBodyOverflow(false);
    }
  }

  componentWillUnmount() {
    this.setBodyOverflow(false);
  }

  setBodyOverflow(isVisible) {
    if (isVisible) {
      document.body.classList.add('body-lock');
    } else {
      document.body.classList.remove('body-lock');
    }
  }

  render() {
    const modalClasses = {
      modal: true,
      'modal--visible': this.props.isVisible,
      [`${this.props.className}`]: this.props.className,
    };

    return (
      <div className={classNames(modalClasses)}>
        <div className="modal__content">
          <div className="modal__header d-flex justify-content-between">
            {this.props.onBack == null ? (
              <span className="modal__title">{this.props.title ? this.props.title : ' '}</span>
            ) : (
              <span>
                <span className="modal__close">
                  <ArrowLeft32 onClick={this.props.onBack} />
                </span>
                <span className="modal__title">{this.props.title ? this.props.title : ' '}</span>
              </span>
            )}
            <span className="modal__close" onClick={this.props.onClose}>
              <CloseOutlined />
            </span>
          </div>
          <div className="modal__children">{this.props.children}</div>
        </div>
        <div className="modal__overlay" onClick={this.props.onClose} />
      </div>
    );
  }
}
Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

Modal.defaultProps = {
  className: null,
  isVisible: false,
  onClose: null,
  onBack: null,
};

export default Modal;
