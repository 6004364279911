import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { removeMultipleFeatures } from 'redux/slices/school-results.slice';
import styles from './coed-status.module.scss';

export const CoedStatus = ({ coed, handleFeatureSelection, featuresSelection }) => {
  const dispatch = useDispatch();

  const gendersIds = useMemo(() => {
    const genders = coed?.features.map((gender) => gender.id);
    return genders;
  }, [coed]);

  const handleGender = (id) => {
    const remainingIds = gendersIds.filter((genderId) => genderId !== id);

    handleFeatureSelection(id);
    dispatch(removeMultipleFeatures(remainingIds));
  };

  return (
    <div className={styles.buttonGroup} role="group" aria-label="Coed Status Select">
      {coed?.features.map((gender) => (
        <button
          key={gender.id}
          id={gender.id}
          className={`${styles.button_radio} ${featuresSelection?.includes(gender.id) ? styles.checked : ''}`}
          onClick={() => handleGender(gender.id)}>
          <span>{gender.name}</span>
        </button>
      ))}
    </div>
  );
};
