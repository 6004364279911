import { _sendApplications } from 'controllers/leads/leads_controller';
import { useStatus } from 'hooks/useStatus';

export const useFullfillApplication = ({ school_id: schoolId, lead_id: leadId, userId }) => {
  const { onLoading, onSuccess, onError, status } = useStatus();

  const onFullFillApp = async () => {
    onLoading();
    try {
      const res = await _sendApplications(schoolId, [leadId], false, userId);
      if (res.ok) {
        onSuccess();
      } else {
        onError();
      }
      const data = await res.json();

      if (data.length > 0) {
        onSuccess();
        window.open(data[0]);
      }
    } catch (error) {
      onError();
    }
  };

  return {
    statusGetApplication: status,
    onFullFillApp,
  };
};
