import client from 'redux/client';

export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD = 'GET_BOARD';
export const ADD_BOARD = 'ADD_BOARD';
export const ADD_SCHOOL_TO_BOARD = 'ADD_SCHOOL_TO_BOARD';
export const UPDATE_BOARDS_BY_SCHOOL = 'UPDATE_BOARDS_BY_SCHOOL';
export const DELETE_BOARD = 'DELETE_BOARD';
export const UPDATE_BOARD = 'UPDATE_BOARD';
export const REMOVE_SCHOOL = 'REMOVE_SCHOOL';

export function getBoards(userId) {
  return {
    type: GET_BOARDS,
    promise: client.get(`boards/${userId}`),
  };
}

export function getBoard(userId, boardId) {
  return {
    type: GET_BOARD,
    promise: client.get(`boards/${userId}/${boardId}`),
  };
}

export function addBoard(userId, name, schoolId) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('school_id', schoolId);

  return {
    type: ADD_BOARD,
    promise: client.post(`boards/${userId}`, formData),
  };
}

export function addSchoolToBoard(userId, boardId, schoolId) {
  const formData = new FormData();
  formData.append('schoolId', schoolId);

  return {
    type: ADD_SCHOOL_TO_BOARD,
    promise: client.post(`boards/${userId}/${boardId}`, formData),
  };
}

/**
 * boards will contain the board Id where the school will be stored
 * if the boards array is empty the school will be removed from all the boards
 */
export function syncBoardSchools(userId, boards, schoolId) {
  const formData = new FormData();
  formData.append('boards', boards);
  formData.append('schoolId', schoolId);

  return {
    type: UPDATE_BOARDS_BY_SCHOOL,
    promise: client.patch(`boards/${userId}`, formData),
  };
}

export function deleteBoard(userId, boardId) {
  return {
    type: DELETE_BOARD,
    promise: client.delete(`boards/${userId}/${boardId}`),
  };
}

export function removeSchoolFromBoard(userId, boardId, schoolId) {
  return {
    type: REMOVE_SCHOOL,
    promise: client.delete(`boards/${userId}/${boardId}/${schoolId}`),
  };
}

export function updateBoard(userId, boardId, payload) {
  return {
    type: UPDATE_BOARD,
    promise: client.patch(`boards/${userId}/${boardId}`, payload),
  };
}

export function onOpenAddBoard(schoolId) {
  return {
    type: 'OPEN_BOARD',
    payload: schoolId,
  };
}
export function onCloseAddBoard() {
  return {
    type: 'CLOSE_BOARD',
  };
}

export const fetchBoardsData = (userId) => {
  return async (dispatch, getState) => {
    const loggedIn = getState().auth.token;

    dispatch({ type: 'LOADING_BOARDS' });

    try {
      const res = await client.get(`boards/${userId}`);
      const boards = await res.json();

      dispatch({ type: 'FETCH_BOARDS_SUCCESS', payload: boards });

      const favoritesIDs = loggedIn ? boards.boardSchools.map((fav) => fav.school_id) : [];
      dispatch({ type: 'GET_FAVORITES_IDS', payload: favoritesIDs });
    } catch (error) {
      console.log(error);
      dispatch({ type: 'ERROR_BOARDS' });
    }
  };
};

export const removeSchool = (userId, boardId, schoolId) => {
  return async (dispatch) => {
    try {
      const res = await client.delete(`boards/${userId}/${boardId}/${schoolId}`);

      if (res.ok) {
        dispatch({ type: 'REMOVE_SCHOOL', payload: schoolId });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSchool = ({ userId, boardId, schools }) => {
  return async (dispatch) => {
    try {
      const res = await client.post(`boards/${userId}/${boardId}`, { schools });
      if (res.ok) {
        dispatch(fetchBoardsData(userId));
        dispatch({ type: 'ADD_SCHOOL', payload: schools });
        dispatch(onCloseAddBoard());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createBoard = (userId, name, schoolId) => {
  const formData = new FormData();
  formData.append('name', name);
  schoolId && formData.append('school_id', schoolId);

  return async (dispatch) => {
    const res = await client.post(`boards/${userId}`, formData);

    if (res.ok) {
      dispatch(fetchBoardsData(userId));
      dispatch(onCloseAddBoard());
    }
  };
};
