export const CATEGORIES_SUBTITLES = {
  sports: 'schoolProfile:extracurricularActivities.subTitle.sports',
  clubs: 'schoolProfile:extracurricularActivities.subTitle.clubs',
  facilities: 'schoolProfile:facilities.subTitle.facilities',
  staff: 'schoolProfile:facilities.subTitle.staff',
  programs: 'schoolProfile:specialNeeds.subTitle.programs',
  specialNeedsSupported: 'schoolProfile:specialNeeds.subTitle.specialNeedsSupported',
  specificEducationalApproach: 'schoolProfile:academics.subTitle.specificEducationalApproach',
  coreKnowledge: 'schoolProfile:academics.subTitle.coreKnowledge',
  highSchoolPrograms: 'schoolProfile:academics.subTitle.highSchoolPrograms',
  schoolCurriculum: 'schoolProfile:academics.subTitle.schoolCurriculum',
  schoolClasses: 'schoolProfile:academics.subTitle.schoolClasses',
  highSchoolSpecificClasses: 'schoolProfile:academics.subTitle.highSchoolSpecificClasses',
};
