import React, { useEffect, useState } from 'react';
import { convertQueryToState } from '../schola-match-results/utils';
import {
  ColumnFilters,
  DistanceController,
  GradeDropdown,
  SchoolsList,
  MapMatches,
  SchoolTypeSelector,
  MapToggle,
  Settings,
  PlaceSearcher,
} from './components';
import { featuresByPersonality as featuresList } from '../personality-match/features-by-personality';
import { useSchoolMatch } from './hooks/useSchoolsMatch';
import { setMultipleFeatures, toggleFilters, toggleMap } from 'redux/slices/school-results.slice';
import { useDispatch, useSelector } from 'react-redux';
import { searchParamsController } from './utils/school-search-params';
import { useAutocompletePlaces } from './hooks/usePlaces';
import { usePreventBodyScroll } from '../parent-portal/track/hooks';
import { getStudent } from './utils/get-student';
import { ContactSchoolModal } from '../school-profile/contact-school/components';
import { useAutoFillForm } from './hooks/useAutofillForm';
import styles from './schools-results.module.scss';

const SchoolsByPersonality = ({ location, params }) => {
  const searchObj = convertQueryToState({ location });

  const personalityId = params.personality;
  const featuresByPersonality = featuresList[personalityId];

  /** features selected stored on redux state - selected by the user or preselected by personality */
  const { featuresSelected, isOpenFilters, isMapOpen, schoolToContact, isMultipleContact, multipleSchoolIds } =
    useSelector((state) => state.schoolResults);

  const dispatch = useDispatch();

  /** update feature selection based on personality */
  useEffect(() => {
    dispatch(setMultipleFeatures(featuresByPersonality));
  }, []);

  const { handleGrade, handleDistance, handleType, gradeQuery, schoolType, radius, handlePlace, address } =
    searchParamsController(location.query);

  const { schools, sponsored, foundSchools, loadingSchools, successSchools, errorSchools, isFetching } = useSchoolMatch(
    {
      query: searchObj.search.defaultQuery,
      gradeQuery,
      schoolType,
      featuresSelected,
    }
  );

  /** Controllers to handle request info / contact school */
  const { isContactOpen } = useSelector((state) => state.contactSchool);
  const autoFillForm = useAutoFillForm(location);

  const { places, handleAutocomplete, getGeolocation, placeLocation } = useAutocompletePlaces();
  const [searchPlace, setSearchPlace] = useState('');
  const onChangeSearch = ({ target }) => {
    const { value } = target;
    setSearchPlace(value);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      // call autocomplete google service:
      handleAutocomplete(searchPlace);
    }, 1300);

    return () => clearTimeout(debounce);
  }, [searchPlace]);

  const handleGeolocation = (place) => {
    getGeolocation(place.value, place.text).then((place) => {
      handlePlace(place);
      setSearchPlace(place.text);
    });
  };

  const student = getStudent();

  usePreventBodyScroll(isOpenFilters);

  return (
    <div className={styles.layout}>
      <Settings />
      <div className={styles.filters_cards_container}>
        <ColumnFilters className={styles.column_filters} style={{ left: !isOpenFilters ? '-100%' : '0%' }}>
          <PlaceSearcher
            places={places}
            placeholder={address}
            searchPlace={searchPlace}
            onChange={onChangeSearch}
            onClick={handleGeolocation}
          />

          <Section title="Grade:">
            <GradeDropdown gradeQuery={gradeQuery} handleGrade={handleGrade} />
          </Section>
          <Section title="Distance:">
            <DistanceController radius={radius} handleDistance={handleDistance} />
          </Section>
          <h4 className={styles.title_filter}>School Types</h4>
          <SchoolTypeSelector schoolType={schoolType} handleType={handleType} />
        </ColumnFilters>

        <div className={styles.list}>
          <div className={styles.listHeader}>
            <h3>
              {foundSchools} School Matches for {student} 👇 {isFetching && 'Loading'}
            </h3>
            <div className={styles.listHeaderButton}>
              <MapToggle />
            </div>
          </div>

          {!isMapOpen && (
            <SchoolsList
              schools={schools}
              sponsored={sponsored}
              successSchools={successSchools}
              loadingSchools={loadingSchools}
              errorSchools={errorSchools}
            />
          )}

          {isMapOpen && (
            <MapMatches
              lat={searchObj.search.defaultQuery.lat}
              lon={searchObj.search.defaultQuery.lon}
              schools={schools}
              sponsored={sponsored}
            />
          )}
        </div>
      </div>
      {isContactOpen && (
        <ContactSchoolModal
          school={schoolToContact}
          source="schola_personality_results"
          externalCampaignId={location.query.external_campaign_id}
          autoFillForm={autoFillForm}
          isMultiple={isMultipleContact}
          schoolIds={multipleSchoolIds}
        />
      )}
    </div>
  );
};

export default SchoolsByPersonality;

const Section = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <p>{title}</p>
      {children}
    </div>
  );
};
