import { FeatureChip } from '..';
import { icons } from '../../utils/get-insights-features';
import styles from './general-features.module.scss';

export const FeatureSection = ({ title, features, children }) => {
  return (
    <div>
      <h4>{title}</h4>
      <div className={styles.list}>
        {children}
        {features.map(([key, value]) => (
          <FeatureChip
            key={key}
            icon={icons[key]}
            label={key}
            value={Array.isArray(value) ? value.join(' | ') : value}
          />
        ))}
      </div>
    </div>
  );
};
