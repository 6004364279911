import { combineReducers } from 'redux';
import localstorage from 'store2';

import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './auth-actions.js';
import { UPLOAD_IMAGE_SUCCESS } from '../user/user-actions.js';

function token(state = localstorage.get('idToken') || null, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.idToken;
    case UPLOAD_IMAGE_SUCCESS:
      localstorage.set('idToken', action.json.idToken);
      return action.json.idToken;
    case LOGIN_FAILURE:
    case LOGOUT:
      localstorage.remove('idToken');
      localstorage.remove('accessToken');
      return null;
    default:
      return state;
  }
}

const auth = combineReducers({
  displayLogin: (state = false, action) => {
    if (action.type === 'LOGIN') {
      return !action.hide;
    }
    return state;
  },
  token,
});

export default auth;
