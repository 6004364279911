import config from 'config';
import schoolUrl from './schoolUrl';
import { planName } from './schoolUtils';

export const applicationType = (school) => {
  let application = 0;

  const plan = planName(school);

  if (school.open_enrollment) {
    application = 1;

    if (school.externalapplication_url && school.externalapplication_url.trim() !== '') {
      if (config.schola.externalApplicationRequirePlan) {
        if (school.plan_id && school.subscription_end) {
          let subEnd = new Date(school.subscription_end);
          const result = plan !== 'scholabasics' && subEnd > new Date();
          if (result) {
            application = 2;
          }
        }
      } else {
        application = 2;
      }
    }
  }

  return application;
};

// Internal, 1
export const onUniversalApp = (school) => {
  const sUrl = schoolUrl.build(school).replace('?', '');
  window.open(`${sUrl}/application`, 'blank');
};

// External, 2
export const onExternalApp = (url) => {
  trackEvent('open_apply_now_parent_portal', url);
  window.open(`${url}`, 'blank', 'scrollbars=yes, toolbar=no');
};
