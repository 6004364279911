import React, { useState, useRef, useEffect } from 'react';
import { Star32, StarFilled32 } from '@carbon/icons-react';
import styles from './stars-expercience.module.scss';

const StarsExperience = ({
  name,
  setState,
  state,
  value,
  spaceBetween,
  size,
  onlyView,
  notPointer,
  color,
  length = 1,
  ranking,
}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setRating(value);
  }, [value]);

  const buttonRef = useRef();

  const handleClick = (index, e, attr) => {
    setRating(index);
    setState({
      ...state,
      [buttonRef.current.name]: index,
    });
  };

  return (
    <div className={styles.starsExperience} style={{ gap: `${spaceBetween}px` }}>
      {[...Array(length)].map((star, index) => {
        index += 1;
        return (
          <div
            type="button"
            key={index}
            name={name}
            ref={buttonRef}
            onClick={onlyView ? null : (e) => handleClick(index, e)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            style={notPointer ? { cursor: 'default' } : { cursor: 'pointer' }}>
            <span className={styles.starsExperienceItem}>
              <StarFilled32
                fill={index <= rating ? color : '#C7CCD4'}
                style={{ width: `${size}px`, height: `${size}px` }}
                className={onlyView ? null : index <= (rating || hover) ? 'starOn' : 'starOff'}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default StarsExperience;
