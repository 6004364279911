import { useSelector } from 'react-redux';
import { useToggle } from 'hooks/useToggle';
import { Add16, Subtract16 } from '@carbon/icons-react';
import styles from './accordion-item.module.scss';
import { GroupChips } from '..';

export const AccordionItem = ({ title, listGroups }) => {
  const { featuresSelected } = useSelector((state) => state.schoolResults);
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div>
      <button className={styles.accordionHeader} onClick={toggleOpen}>
        <h3>{title}</h3>
        {isOpen ? <Subtract16 /> : <Add16 />}
      </button>

      {isOpen &&
        listGroups.map((group, idx) => (
          <GroupChips key={`${group.name} - ${idx}`} featuresSelection={featuresSelected} {...group} />
        ))}
    </div>
  );
};
