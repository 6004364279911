import React from 'react';
import styles from './feature-chip.module.scss';
import { Checkmark24 } from '@carbon/icons-react';

export const FeatureChip = ({ icon, label, value }) => {
  return (
    <div className={styles.chip}>
      <div className={styles.icon}>{icon || <Checkmark24 />}</div>
      <div className={styles.info}>
        <span className={styles.label}>{label}</span>
        {value && <span className={styles.value}>{value}</span>}
      </div>
    </div>
  );
};
