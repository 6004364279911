import React from 'react';
import styles from './banner-steps.module.scss';

const BannerSteps = ({ handleContact }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.bg_grid_bullets}
        src="https://static.schola.com/schola-match-results/grid-bullets-background.svg"
        alt="Schola Figure"
      />
      <h3 className={styles.title}>3 Steps to a Brighter Future!</h3>
      <div className={styles.steps_container}>
        <div className={`${styles.step} ${styles.step_blue}`}>
          <div className={styles.step_figure_number}>
            <div>
              <img
                className={styles.step_figure}
                src="https://static.schola.com/schola-match-results/bullet-blue-step-1.svg"
                alt="schola figure"
              />
            </div>
            <div className={`${styles.step_number} ${styles.step_number_blue}`}>#1</div>
          </div>
          <h4 className={styles.title_step}>Start Your Application</h4>
          <p>Simply click below to begin the application process to your school of choice.</p>
          <button onClick={handleContact} className={styles.button}>
            Start Here!
          </button>
        </div>
        <div className={`${styles.step} ${styles.step_green}`}>
          <div className={styles.step_figure_number}>
            <div>
              <img
                className={styles.step_figure}
                src="https://static.schola.com/schola-match-results/bullet-green-step-2.svg"
                alt="schola figure"
              />
            </div>
            <div className={`${styles.step_number} ${styles.step_number_green}`}>#2</div>
          </div>
          <h4 className={styles.title_step}>Get Contacted</h4>
          <p>
            If the school you are applying to is a Schola Partner School, you will be contacted within 2 business days
            to support you through next steps. If you are not contacted by Schola or the school, we’ll help you reach
            out directly or find another school in your area!
          </p>
        </div>
        <div className={`${styles.step} ${styles.step_blue}`}>
          <div className={styles.step_figure_number}>
            <div>
              <img
                className={styles.step_figure}
                src="https://static.schola.com/schola-match-results/bullet-blue-step-3.svg"
                alt="schola figure"
              />
            </div>
            <div className={`${styles.step_number} ${styles.step_number_blue}`}>#3</div>
          </div>
          <h4 className={styles.title_step}>Complete Your Enrollment</h4>
          <p>
            If your application is accepted by the school and you are offered a seat, the school you are applying to
            will request the right documents to finalize your enrollment. Congrats!
          </p>
        </div>
      </div>
    </div>
  );
};

export default BannerSteps;
