export const convertQueryToState = ({ isNew = false, location, pageSize }) => {
  const query = location.query;

  let _state = query.state || '';
  const defaultQuery = {
    address: query.address || '',
    lat: query.lat || null,
    lon: query.lon || null,
    radius: query.radius || 0,
    page: query.page || 1,
    pageSize: pageSize || 5000,
    state: _state,
    zip: query.zip || '',
  };
  location.state = _state;
  location.query.state = _state;
  query.sped_service = query.sped_service === 'yes' || query.sped_service === 'notsure';

  if (isNew) {
    // used for filtering from checkboxes
    return { defaultQuery };
  }
  return {
    search: { defaultQuery, queryWithFeatures: query },
  };
};
