import { Checkbox } from '..';
import styles from '../column-filters/column-filters.module.scss';

export const GroupFeatures = ({ title, features, handleFeatureSelection, featuresSelection }) => {
  return (
    <div>
      <h4 className={styles.title_filter}>{title}</h4>

      <div>
        {features?.map((feature) => (
          <Checkbox
            key={feature.id}
            id={feature.id}
            label={feature.name}
            onChange={() => handleFeatureSelection(feature.id)}
            isChecked={featuresSelection.includes(feature.id)}
          />
        ))}
      </div>
    </div>
  );
};
