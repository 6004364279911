import { useState } from 'react';

export function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);

  const onToggle = () => {
    setValue(!value);
  };

  const onTrue = () => {
    setValue(true);
  };

  const onFalse = () => {
    setValue(false);
  };

  return [value, onToggle, onFalse];
}
