import client from '../client';

export const CREATE_LEAD = 'CREATE_LEAD';
export const BATCH_EDIT_LEADS = 'BATCH_EDIT_LEADS';
export const GET_LONG_URL = 'GET_LONG_URL';

export function createLead(payload) {
  return {
    type: CREATE_LEAD,
    promise: client.post('leads', payload),
  };
}

export function resetCreateLead() {
  return { type: 'CREATE_LEAD_RESET' };
}

export function batchEditLeads(schoolId, leads, values) {
  return {
    type: BATCH_EDIT_LEADS,
    promise: client.patch(`schools/${schoolId}/leads/batch`, { leads, values }),
  };
}

export function getLongUrl(shortid) {
  return {
    tye: GET_LONG_URL,
    promise: client.get(`url/${shortid}`),
  };
}
