import client from '../client';

export const _addBoard = async (userId, name, schoolId) => {
  const formData = new FormData();
  formData.append('name', name);
  schoolId && formData.append('school_id', schoolId);

  return await client.post(`boards/${userId}`, formData);
};

export const _addSchoolToBoard = async (userId, boardId, schools) => {
  return await client.post(`boards/${userId}/${boardId}`, { schools });
};
