import { browserHistory } from 'react-router';

export const useQueryState = () => {
  const pathname = browserHistory.getCurrentLocation().pathname;
  const query = browserHistory.getCurrentLocation().query;

  const onChangeQuery = (paramName, value) => {
    browserHistory.push({
      pathname,
      query: {
        ...query,
        [paramName]: value,
      },
    });
  };

  const removeQueryParam = (paramName) => {
    delete query[paramName];

    browserHistory.push({
      pathname,
      query: {
        ...query,
      },
    });
  };

  return {
    onChangeQuery,
    removeQueryParam,
  };
};
