import React, { useState } from 'react';
import './reviewQuestion.scss';
import { t } from 'components/translation/Translation';
import { Button } from 'schola-components';

const ReviewQuestion = ({ schoolName, setState, state, decreaseStep, increaseStep }) => {
  const [reviewText, setReviewText] = useState('');

  const handleInput = (e) => {
    setReviewText(e.target.value);
    setState(e.target.value);
  };

  // let placeholder = t('review.review_placeholder');
  let placeholder = 'Tell Other Families What To Expect From This School';

  const [error, setError] = useState('');
  const handleNext = () => {
    if (!state.length) {
      setError('Please, Write a Review');
    } else {
      increaseStep();
    }
  };

  return (
    <section className="reviewQuestion" id="reviewQuestion" data-testid="reviewQuestion">
      <h2 className="heading-h2 reviewQuestion-title">{t('schoolProfile:reviews.writeAReview', { schoolName })}</h2>
      <div className="reviewQuestion__container" data-testid="reviewQuestionContainer">
        <textarea
          type="text"
          className="reviewQuestion__answer"
          id="reviewQuestionAnswer"
          placeholder={placeholder}
          name="reviewAnswer"
          data-testid="reviewQuestionAnswer"
          onChange={handleInput}
          value={state}
        />
      </div>

      <p className="warningValidation spacing-mt-32">{error}</p>

      <div className="bigModalReview_buttons spacing-mt-64">
        <Button color="primarylegacy" onClick={decreaseStep}>
          {t('schoolProfile:reviews.prevButton')}
        </Button>
        <Button color="primarylegacy" onClick={handleNext}>
          {t('schoolProfile:reviews.nextButton')}
        </Button>
      </div>
    </section>
  );
};

export default ReviewQuestion;
