import React, { useEffect } from 'react';
import { createMap, createMarker, createPanorama } from './map-service';
import styles from './map-view.module.scss';

export const MapView = ({ school }) => {
  const center = {
    lat: parseFloat(school?.latitude),
    lng: parseFloat(school?.longitude),
  };

  const panoramaOptions = {
    position: center,
    pov: {
      heading: 34,
      pitch: 10,
    },
    addressControl: false,
    zoomControl: false,
    fullscreenControl: false,
    linksControl: false,
    panControl: false,
    enableCloseButton: false,
  };

  const mapOptions = {
    center,
    zoom: 14,
    disableDefaultUI: true,
    panControl: false,
    styles: [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  const paintMap = () => {
    let map;
    let panorama;

    if (school) {
      map = createMap('main', mapOptions);
      // panorama = createPanorama('mini', panoramaOptions);
    } else {
      panorama = createPanorama('main', panoramaOptions);
      // map = createMap('mini', mapOptions);
    }

    createMarker(map, center);
    map.setStreetView(panorama);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      paintMap();
    } else {
      setTimeout(() => {
        if (window.google && window.google.maps) paintMap();
      }, 500);
    }
  }, [school]);

  return (
    <>
      <div id="main" className={styles.mapMain} />
      {/* <div id="mini" className={styles.mini} onClick={toggleStreetView}>
        {' '}
        {normalMapView && <span className={styles.street}>Street View</span>}
      </div> */}
    </>
  );
};
