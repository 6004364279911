import React, { useMemo, useState } from 'react';
import { getFeatureIcon } from 'components/pages/school-profile-page/utils/getFeatureIcon';
import styles from './feature-groups.module.scss';
import { ListFeatureGroup } from './list-feature-group';
import { Divider } from '../divider/divider';

export const FeaturesGroups = ({ featuresByCategories }) => {
  const { curriculum, extracurricular } = useMemo(() => {
    const extracurricularList = [
      'Sports & Outdoors',
      'Art & Communication',
      'Alternative',
      'Extra Features',
      'Gifted Students support',
      'Student Government & Leadership',
      'Volunteer & Community',
    ];

    const groups = Object.entries(featuresByCategories);

    const extracurricular = groups.filter(([item]) => extracurricularList.includes(item));
    const curriculum = groups.filter(([item]) => !extracurricularList.includes(item));

    return { curriculum, extracurricular };
  }, [featuresByCategories]);

  if (curriculum.length === 0 && extracurricular.length === 0) return null;

  return (
    <>
      <Group group="School Curriculum" featureGroups={curriculum} />
      <Group group="Extracurricular Activities" featureGroups={extracurricular} />
    </>
  );
};

const Group = ({ group, featureGroups }) => {
  return (
    <div className="spacing-mb-24">
      <h4 className="spacing-mb-16">{group}</h4>
      <div className={`${styles.groups} groups`} role="feature-group" aria-labelledby="feature-list-title">
        {featureGroups.map(([groupName, features], idx) => (
          <div key={idx} className={styles.group}>
            <div className={styles.header}>
              {getFeatureIcon(groupName, '#006b8f')}
              <h4>{groupName}</h4>
            </div>
            <ListFeatureGroup features={features} />
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
};
