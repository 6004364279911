import { useRef, useEffect } from 'react';

export const useClickOutside = (callback) => {
  let domRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (domRef.current !== null && !domRef.current?.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  });

  return domRef;
};
