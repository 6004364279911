import React from 'react';
import PropTypes from 'prop-types';
import styles from './divider.module.scss';

export const Divider = ({ borderColor, borderSize, marginTop, marginBottom }) => {
  return <hr className={styles.divider} />;
};
Divider.propTypes = {
  borderColor: PropTypes.string,
  borderSize: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};
