export const featuresByPersonality = {
  mastermind: [
    1, 2, 9, 10, 11, 13, 14, 30, 44, 68, 69, 70, 71, 72, 73, 74, 75, 77, 78, 81, 82, 83, 85, 87, 88, 90, 91, 92, 93, 94,
    96, 100, 101, 127, 128, 139, 140, 141, 145, 182, 187, 188, 194, 199, 201, 202, 203, 204, 211, 212, 213, 215, 217,
    220, 221, 222, 223, 227, 228, 229, 234, 235, 236, 237, 243, 246, 249, 251, 252, 262, 285, 287, 289, 292, 293, 297,
    300, 301, 302, 308, 310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352,
    353, 354, 355, 365, 366, 368, 371, 373, 374, 380, 388, 389, 390, 391, 392, 393, 406, 408, 409, 410, 412, 414, 418,
    421, 423, 425, 427, 430, 433, 436, 437, 438, 439, 449, 450, 503, 505, 516, 517, 519, 523, 527, 529, 531, 536, 543,
    544, 545, 546, 548, 550, 556, 557,
  ],
  thinker: [
    1, 6, 9, 10, 11, 13, 14, 16, 30, 44, 68, 69, 70, 71, 72, 73, 74, 75, 77, 78, 81, 82, 83, 85, 87, 88, 90, 91, 92, 93,
    94, 96, 100, 101, 127, 128, 139, 140, 141, 145, 182, 187, 188, 192, 194, 199, 201, 202, 203, 204, 211, 212, 213,
    215, 217, 220, 221, 222, 223, 227, 228, 229, 234, 235, 236, 237, 243, 246, 249, 251, 252, 262, 285, 287, 289, 292,
    293, 297, 300, 301, 302, 308, 310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345,
    350, 352, 353, 354, 355, 365, 366, 368, 371, 373, 374, 380, 388, 389, 390, 391, 392, 393, 406, 408, 409, 410, 412,
    414, 418, 421, 423, 425, 427, 430, 433, 436, 437, 438, 439, 449, 450, 503, 505, 516, 517, 519, 523, 527, 529, 531,
    536, 543, 544, 545, 546, 548, 550, 556, 557,
  ],
  captain: [
    1, 2, 10, 11, 14, 37, 44, 70, 71, 72, 73, 74, 75, 78, 81, 82, 85, 87, 88, 90, 91, 92, 94, 96, 100, 101, 127, 128,
    139, 140, 141, 145, 182, 187, 188, 194, 199, 201, 202, 203, 204, 211, 212, 213, 215, 217, 220, 221, 222, 223, 227,
    228, 229, 234, 235, 236, 237, 243, 246, 249, 251, 252, 262, 285, 286, 287, 289, 292, 293, 297, 300, 301, 302, 308,
    310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352, 353, 354, 355, 365,
    366, 368, 371, 373, 374, 380, 388, 389, 390, 391, 392, 393, 406, 408, 409, 410, 412, 414, 418, 419, 421, 423, 425,
    427, 430, 433, 436, 437, 438, 439, 449, 450, 503, 505, 516, 517, 519, 520, 523, 527, 529, 531, 536, 543, 544, 545,
    546, 548, 550, 556, 557,
  ],
  inventor: [
    1, 6, 10, 11, 14, 16, 37, 44, 70, 71, 72, 73, 74, 75, 77, 78, 79, 81, 82, 83, 85, 87, 88, 90, 91, 92, 94, 96, 100,
    101, 127, 128, 139, 140, 141, 145, 182, 187, 188, 192, 194, 199, 201, 202, 203, 204, 211, 212, 213, 215, 217, 220,
    221, 222, 223, 227, 228, 229, 234, 235, 236, 237, 243, 246, 249, 251, 252, 262, 285, 287, 289, 292, 293, 297, 300,
    301, 302, 308, 310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352, 353,
    354, 355, 365, 366, 368, 371, 373, 374, 380, 388, 389, 390, 391, 392, 393, 406, 408, 409, 410, 412, 414, 418, 419,
    421, 423, 425, 427, 430, 433, 436, 437, 438, 439, 449, 450, 503, 505, 516, 517, 519, 520, 523, 527, 529, 531, 536,
    543, 544, 545, 546, 548, 550, 556, 557,
  ],
  idealist: [
    2, 3, 4, 5, 8, 15, 16, 22, 30, 39, 40, 41, 44, 45, 68, 75, 76, 77, 78, 79, 81, 83, 84, 85, 86, 93, 94, 95, 100, 101,
    102, 127, 130, 133, 139, 145, 181, 183, 184, 185, 186, 192, 193, 194, 195, 196, 197, 198, 199, 200, 203, 204, 210,
    216, 217, 222, 223, 224, 225, 230, 231, 232, 233, 238, 239, 240, 242, 245, 247, 248, 255, 258, 259, 260, 261, 253,
    254, 256, 257, 283, 284, 286, 287, 294, 296, 297, 298, 299, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316, 317,
    318, 319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 355, 357, 358, 362,
    365, 372, 375, 377, 378, 382, 383, 384, 387, 388, 389, 394, 396, 397, 404, 407, 411, 414, 415, 416, 417, 418, 421,
    423, 425, 430, 432, 435, 440, 442, 444, 445, 448, 450, 502, 503, 507, 511, 516, 517, 518, 520, 521, 522, 523, 524,
    526, 527, 529, 531, 532, 533, 535, 536, 538, 540, 543, 546, 549, 552, 556, 557, 558, 560,
  ],
  dreamer: [
    3, 4, 5, 8, 12, 15, 19, 22, 30, 39, 40, 41, 45, 46, 68, 75, 76, 77, 78, 79, 81, 83, 84, 85, 86, 93, 94, 95, 100,
    101, 102, 127, 130, 133, 139, 145, 181, 183, 184, 185, 186, 192, 193, 194, 195, 196, 197, 198, 199, 200, 203, 204,
    210, 216, 217, 222, 223, 224, 225, 230, 231, 232, 233, 238, 239, 240, 242, 245, 247, 248, 255, 258, 259, 260, 261,
    253, 254, 256, 257, 283, 284, 286, 287, 294, 296, 297, 298, 299, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316,
    317, 318, 319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 355, 357, 358,
    362, 365, 372, 375, 377, 378, 382, 383, 384, 387, 388, 389, 394, 396, 397, 404, 407, 411, 414, 415, 416, 417, 418,
    421, 423, 425, 430, 432, 435, 440, 442, 444, 445, 448, 450, 502, 503, 507, 511, 516, 517, 518, 520, 521, 522, 523,
    524, 526, 527, 529, 531, 532, 533, 535, 536, 538, 540, 543, 546, 549, 552, 556, 557, 558, 560,
  ],
  mentor: [
    2, 5, 15, 22, 37, 39, 40, 41, 44, 45, 68, 75, 76, 77, 78, 79, 81, 83, 84, 85, 86, 93, 94, 95, 100, 101, 102, 127,
    128, 130, 133, 139, 145, 181, 183, 184, 185, 186, 192, 193, 194, 195, 196, 197, 198, 199, 200, 203, 204, 210, 216,
    217, 222, 223, 224, 225, 230, 231, 232, 233, 238, 239, 240, 242, 245, 247, 248, 255, 258, 259, 260, 261, 253, 254,
    256, 257, 283, 284, 286, 287, 294, 296, 297, 298, 299, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316, 317, 318,
    319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 355, 357, 358, 362, 365,
    372, 375, 377, 378, 382, 383, 384, 387, 388, 389, 394, 396, 397, 404, 407, 411, 414, 415, 416, 417, 418, 421, 423,
    425, 430, 432, 435, 440, 442, 444, 445, 448, 450, 502, 503, 507, 511, 516, 517, 518, 520, 521, 522, 523, 524, 526,
    527, 529, 531, 532, 533, 535, 536, 538, 540, 543, 546, 549, 552, 556, 557, 558, 560,
  ],
  explorer: [
    4, 6, 8, 12, 16, 19, 30, 37, 39, 41, 44, 45, 68, 75, 76, 77, 78, 79, 81, 83, 84, 85, 86, 93, 94, 95, 100, 101, 102,
    127, 130, 133, 139, 145, 181, 183, 184, 185, 186, 192, 193, 194, 195, 196, 197, 198, 199, 200, 203, 204, 210, 216,
    217, 222, 223, 224, 225, 230, 231, 232, 233, 238, 239, 240, 242, 245, 247, 248, 255, 258, 259, 260, 261, 253, 254,
    256, 257, 283, 284, 286, 287, 294, 296, 297, 298, 299, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316, 317, 318,
    319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 355, 357, 358, 362, 365,
    372, 375, 377, 378, 382, 383, 384, 387, 388, 389, 394, 396, 397, 404, 407, 411, 414, 415, 416, 417, 418, 421, 423,
    425, 430, 432, 435, 440, 442, 444, 445, 448, 450, 502, 503, 507, 511, 516, 517, 518, 520, 521, 522, 523, 524, 526,
    527, 529, 531, 532, 533, 535, 536, 538, 540, 543, 546, 549, 552, 556, 557, 558, 560,
  ],
  organizer: [
    2, 7, 13, 14, 18, 30, 41, 44, 68, 69, 75, 81, 82, 85, 87, 88, 90, 94, 96, 100, 101, 127, 128, 139, 140, 141, 145,
    182, 187, 188, 203, 204, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 227, 228, 229, 234, 235, 236,
    237, 243, 249, 251, 252, 262, 285, 286, 287, 292, 293, 297, 300, 301, 302, 308, 310, 311, 312, 314, 316, 319, 320,
    323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352, 353, 356, 357, 362, 365, 366, 368, 371, 373, 374, 380,
    388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 403, 406, 407, 409, 410, 414, 418, 421, 423, 424, 425, 427, 430,
    433, 436, 437, 438, 439, 446, 447, 448, 449, 502, 503, 505, 511, 516, 517, 519, 520, 523, 524, 529, 531, 536, 538,
    540, 543, 544, 548, 552, 556, 557,
  ],
  advocate: [
    3, 5, 7, 15, 18, 22, 30, 39, 40, 41, 44, 45, 68, 75, 76, 77, 78, 81, 83, 85, 86, 93, 94, 95, 100, 101, 127, 128,
    130, 133, 139, 145, 181, 183, 185, 186, 192, 194, 195, 196, 197, 198, 199, 200, 203, 204, 210, 216, 217, 218, 222,
    223, 224, 225, 231, 238, 239, 240, 242, 248, 255, 258, 259, 260, 261, 285, 286, 287, 294, 296, 297, 298, 299, 303,
    304, 306, 307, 308, 309, 310, 313, 314, 316, 317, 318, 319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340,
    341, 342, 343, 346, 349, 352, 356, 357, 358, 362, 365, 372, 375, 377, 378, 387, 388, 389, 394, 395, 396, 397, 403,
    406, 407, 409, 410, 414, 418, 421, 423, 424, 425, 427, 430, 433, 436, 437, 438, 439, 446, 447, 448, 449, 502, 503,
    505, 511, 516, 517, 519, 520, 523, 524, 529, 531, 536, 538, 540, 543, 544, 548, 552, 556, 557,
  ],
  leader: [
    2, 7, 14, 18, 30, 37, 41, 44, 48, 49, 50, 51, 52, 54, 59, 60, 61, 62, 63, 64, 65, 66, 67, 71, 75, 81, 82, 85, 87,
    88, 90, 94, 96, 100, 101, 127, 128, 139, 140, 141, 145, 182, 187, 188, 203, 204, 210, 211, 212, 213, 214, 215, 216,
    217, 218, 219, 220, 221, 227, 228, 229, 234, 235, 236, 237, 243, 249, 251, 252, 262, 285, 286, 287, 292, 293, 297,
    300, 301, 302, 308, 310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352,
    353, 356, 357, 362, 365, 366, 368, 371, 373, 374, 380, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 403, 406,
    407, 409, 410, 414, 418, 419, 421, 423, 424, 425, 427, 430, 433, 436, 437, 438, 439, 446, 447, 448, 449, 502, 503,
    505, 511, 516, 517, 519, 520, 523, 524, 529, 531, 536, 538, 540, 543, 544, 548, 552, 556, 557,
  ],
  supporter: [
    5, 7, 15, 18, 22, 30, 39, 40, 41, 44, 45, 48, 49, 50, 51, 52, 54, 59, 60, 61, 62, 63, 64, 65, 66, 67, 75, 76, 77,
    78, 81, 83, 85, 86, 93, 94, 95, 100, 101, 127, 128, 130, 133, 139, 145, 181, 183, 185, 186, 192, 194, 195, 196, 197,
    198, 199, 200, 203, 204, 210, 216, 217, 218, 222, 223, 224, 225, 231, 238, 239, 240, 242, 248, 255, 258, 259, 260,
    261, 285, 286, 287, 294, 296, 297, 298, 299, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316, 317, 318, 319, 321,
    322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 356, 357, 358, 362, 365, 372, 375,
    377, 378, 387, 388, 389, 394, 395, 396, 397, 403, 406, 407, 409, 410, 414, 418, 419, 421, 423, 424, 425, 427, 430,
    433, 436, 437, 438, 439, 446, 447, 448, 449, 502, 503, 505, 511, 516, 517, 519, 520, 523, 524, 529, 531, 536, 538,
    540, 543, 544, 548, 552, 556, 557,
  ],
  maker: [
    6, 9, 10, 11, 14, 15, 23, 24, 36, 41, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67,
    71, 72, 73, 74, 90, 91, 92, 96, 100, 127, 128, 139, 140, 141, 145, 182, 187, 188, 192, 195, 196, 197, 198, 199, 200,
    201, 202, 205, 207, 208, 211, 212, 213, 215, 217, 218, 219, 220, 221, 224, 225, 228, 229, 236, 237, 243, 249, 251,
    252, 263, 264, 265, 266, 267, 268, 269, 270, 272, 276, 277, 278, 279, 281, 282, 285, 287, 290, 292, 293, 294, 295,
    298, 300, 301, 302, 308, 310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350,
    352, 353, 365, 366, 368, 371, 373, 374, 380, 388, 389, 391, 392, 393, 397, 405, 406, 407, 408, 410, 412, 414, 416,
    418, 423, 425, 433, 436, 437, 438, 439, 441, 443, 446, 449, 450, 503, 505, 510, 511, 512, 516, 517, 519, 520, 523,
    529, 531, 536, 538, 540, 543, 544, 546, 548, 550, 556, 557, 559, 561,
  ],
  performer: [
    3, 4, 8, 12, 15, 19, 23, 24, 27, 30, 33, 41, 42, 45, 46, 47, 52, 53, 71, 72, 73, 74, 75, 76, 77, 79, 84, 89, 94, 97,
    98, 99, 100, 127, 130, 133, 139, 145, 183, 185, 186, 187, 189, 190, 191, 192, 194, 195, 196, 197, 198, 199, 200,
    201, 202, 205, 206, 207, 208, 209, 210, 216, 217, 218, 224, 225, 226, 230, 231, 232, 233, 238, 239, 241, 242, 247,
    248, 250, 256, 257, 261, 263, 264, 265, 266, 267, 268, 269, 270, 272, 274, 276, 277, 278, 279, 281, 282, 283, 285,
    287, 290, 292, 293, 294, 295, 298, 300, 303, 304, 306, 307, 308, 309, 310, 313, 314, 316, 317, 318, 319, 321, 322,
    325, 328, 329, 332, 333, 334, 336, 338, 340, 341, 342, 343, 346, 349, 352, 357, 358, 362, 365, 372, 375, 382, 383,
    384, 387, 388, 389, 394, 396, 397, 405, 406, 407, 408, 410, 412, 414, 416, 418, 423, 425, 433, 436, 437, 438, 439,
    441, 443, 446, 449, 450, 503, 505, 510, 511, 512, 516, 517, 519, 520, 523, 529, 531, 536, 538, 540, 543, 544, 546,
    548, 550, 556, 557, 559, 561,
  ],
  visionary: [
    1, 24, 25, 36, 41, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 73, 74, 79, 80, 94,
    96, 100, 127, 128, 139, 140, 141, 145, 182, 187, 188, 192, 195, 196, 197, 198, 199, 200, 201, 202, 205, 207, 208,
    211, 212, 213, 215, 217, 218, 219, 220, 221, 224, 225, 228, 229, 236, 237, 243, 249, 251, 252, 263, 264, 265, 266,
    267, 268, 269, 270, 272, 276, 277, 278, 279, 281, 282, 285, 287, 290, 292, 293, 294, 295, 298, 300, 301, 302, 308,
    310, 311, 312, 314, 316, 319, 320, 323, 326, 330, 331, 332, 336, 339, 341, 344, 345, 350, 352, 353, 365, 366, 368,
    371, 373, 374, 380, 388, 389, 391, 392, 393, 397, 405, 406, 407, 408, 410, 412, 414, 416, 418, 419, 423, 425, 433,
    436, 437, 438, 439, 441, 443, 446, 449, 450, 503, 505, 510, 511, 512, 516, 517, 519, 520, 523, 529, 531, 536, 538,
    540, 543, 544, 546, 548, 550, 556, 557, 559, 561,
  ],
  enterteiner: [
    12, 23, 24, 25, 26, 27, 28, 33, 36, 41, 42, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
    63, 64, 65, 66, 67, 73, 74, 76, 77, 79, 80, 84, 89, 94, 97, 98, 99, 100, 127, 130, 133, 139, 145, 183, 185, 186,
    187, 189, 190, 191, 192, 194, 195, 196, 197, 198, 199, 200, 201, 202, 205, 206, 207, 208, 209, 210, 216, 217, 218,
    224, 225, 226, 230, 231, 232, 233, 238, 239, 241, 242, 245, 247, 248, 250, 256, 257, 261, 263, 264, 265, 266, 267,
    268, 269, 270, 272, 274, 276, 277, 278, 279, 281, 282, 283, 285, 287, 290, 292, 293, 294, 295, 298, 300, 303, 304,
    306, 307, 308, 309, 310, 313, 314, 316, 317, 318, 319, 321, 322, 325, 328, 329, 332, 333, 334, 336, 338, 340, 341,
    342, 343, 346, 349, 352, 357, 358, 362, 365, 372, 375, 382, 383, 384, 387, 388, 389, 394, 396, 397, 405, 406, 407,
    408, 410, 412, 414, 416, 418, 419, 423, 425, 433, 436, 437, 438, 439, 441, 443, 446, 449, 450, 503, 505, 510, 511,
    512, 516, 517, 519, 520, 523, 529, 531, 536, 538, 540, 543, 544, 546, 548, 550, 556, 557, 559, 561,
  ],
};
