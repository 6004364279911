import { tString } from 'components/translation/Translation';

const getSchoolTypeTranslation = (type) => {
  let typeTranslation = '';

  switch (type) {
    case 'all schools':
      typeTranslation = tString('landing:school_types.all_schools');
      break;
    case 'public district':
      typeTranslation = tString('landing:school_types.district');
      break;
    case 'public charter':
      typeTranslation = tString('landing:school_types.charters');
      break;
    case 'private':
      typeTranslation = tString('landing:school_types.private');
      break;
    case 'vocational':
      typeTranslation = tString('landing:school_types.vocational');
      break;
    case 'public magnet':
      typeTranslation = tString('landing:school_types.public_magnet');
      break;
    case 'special':
      typeTranslation = tString('landing:school_types.special');
      break;
    case 'virtual/online':
      typeTranslation = tString('landing:school_types.virtual');
      break;
  }

  return typeTranslation;
};

export default getSchoolTypeTranslation;
