import client from '../client';

export const _searchSchools = async (params) => {
  // changed to POST to support long params
  let payload = Object.fromEntries(new URLSearchParams(params).entries());
  payload.lat = Number(payload.lat);
  payload.lon = Number(payload.lon);
  payload.radius = Number(payload.radius);
  payload.page = Number(payload.page);
  payload.pageSize = Number(payload.pageSize);

  return await client.post('schools/searchv2', payload);
};

export const _searchSchoolFeatures = async (params) => {
  return await client.get('schools/search-features', params);
};

export const _getTopNearby = async (params) => {
  return await client.get('schools/top_nearby', params);
};

export const _getTopMostPopular = async (params) => {
  return await client.get('schools/top_mostpopular', params);
};

export const _getTourTimes = async (schoolId) => {
  return await client.get(`schools/${schoolId}/tour-times`);
};

export const _sendMessageToSchool = async (schoolId, { lead_id: leadId, subject, body }) => {
  let payload = {
    lead_id: leadId,
    subject,
    body,
  };

  const res = await client.post(`school/${schoolId}/messages/send-to-school`, payload);
  return res;
};

export const _getHelpFromSPOT = async (schoolId, leadId, payload) => {
  return await client.post(`schools/${schoolId}/leads/${leadId}/contact-spot`, payload);
};

export const _updateAttendaceLead = async (schoolId, leadId, payload) => {
  return client.patch(`schools/${schoolId}/leads/${leadId}/attendance`, payload);
};
