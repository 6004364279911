import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FeatureChip } from '..';
import { Checkmark24 } from '@carbon/icons-react';
import styles from './feature-groups.module.scss';

export const ListFeatureGroup = ({ features }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(features.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = features.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className={styles.list}>
        {currentItems.map((feat) => (
          <FeatureChip key={feat.id} label={feat.name} />
        ))}
      </div>
      {features.length > itemsPerPage && (
        <div className={styles.pagination}>
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </>
  );
};
ListFeatureGroup.propTypes = {
  features: PropTypes.array.isRequired,
};
