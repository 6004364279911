import localstorage from 'store2';

export function validate(key, value) {
  let validated = false;
  switch (key) {
    case 'email':
      var emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      validated = emailRegex.test(String(value).toLowerCase());
      break;
    case 'phone':
      var phoneRegex = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)\s[0-9]{3}\s-\s[0-9]{4}$/;
      validated = phoneRegex.test(String(value).toLowerCase());
      break;
    default:
      validated = true;
  }
  return validated;
}

export const isBlank = (str) => !str || /^\s*$/.test(str);

export const isEmail = (str) =>
  !isBlank(str) &&
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(str).toLowerCase()
  );

export const isPhone = (str) =>
  str && /^(\([0-9]{3}\)|[0-9]{3}(\-|))[0-9]{3}(\-|)[0-9]{4}$$/.test(String(str).toLowerCase());

export const isZipCode = (str) => {
  let temp = !!(str !== undefined && str.length > 4);
  return str && /^\b\d{5}(-\d{4})?\b$/.test(String(str).toLowerCase()) && temp;
};

export const getResponseValue = (res) => {
  return res.json().then((value) => {
    if (value && value.error) {
      const { error } = value;
      if (error && (error.toLowerCase == 'Unauthorized' || value.statusCode == 401)) {
        localstorage.set('idToken', null);
        localstorage.set('accessToken', null);
        window.location.replace('/login');
      } else return value;
    } else return value;
  });
};
export const isValid = (str) => {
  let temp = /[^\w\s\][^,]/.test(String(str).toLowerCase());
  return (
    !isBlank(str) &&
    !['n', 'non', 'nul', 'none', 'null', null, undefined, 'undefined', 'no', 'N/A', 'n/a', 'n/', '.'].includes(
      String(str).toLowerCase()
    ) &&
    !temp
  );
};
