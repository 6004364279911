import { useDispatch } from 'react-redux';
import { useToggle } from 'hooks/useToggle';
import { removeMultipleFeatures, setMultipleFeatures } from 'redux/slices/school-results.slice';
import { Checkbox, GroupFeatures } from '..';

export const SupportPrograms = ({ featuresSelection, handleFeatureSelection, specialNeedsFeatures }) => {
  const specialNeedsIds = specialNeedsFeatures.map((feature) => feature.id);

  const dispatch = useDispatch();
  const supportFeatureGroups = [
    { id: 460, name: '504 Plan' },
    { id: 459, name: 'Individualized Educational Plan' },
    { id: 359, name: 'Applied Behavior Analysis' },
  ];

  const [isSpecialNeedsSelected, toggleSpecialNeedsSelected] = useToggle(false);
  const handleSpecialNeeds = () => {
    toggleSpecialNeedsSelected();

    if (!isSpecialNeedsSelected) {
      dispatch(setMultipleFeatures(specialNeedsIds));
    } else {
      dispatch(removeMultipleFeatures(specialNeedsIds));
    }
  };

  return (
    <>
      <GroupFeatures
        title="Support Programs"
        features={supportFeatureGroups}
        featuresSelection={featuresSelection}
        handleFeatureSelection={handleFeatureSelection}
      />
      <Checkbox
        id="special-needs"
        label="Special Needs"
        onChange={handleSpecialNeeds}
        isChecked={isSpecialNeedsSelected}
      />
    </>
  );
};
