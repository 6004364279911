import { getProfile } from 'util/auth';

const axios = require('axios');

export async function createVisitor() {
  var metadata = {
    environment: process.env.REACT_APP_ENV,
  };
  const params = {
    browser_name: navigator.userAgent,
    metadata,
  };

  // Get user data
  const profile = getProfile();
  if (profile) {
    params.email = profile.email;
    params.user_id = profile.sub;
  }

  var response = await axios.post('/api/createTrackingVisitor', params);
  const id = response.data.id;
  localStorage.setItem('visitor_id', id);
  if (profile) localStorage.setItem('visitor_user_id', params.user_id);
  return id;
}

export async function updateVisitor(visitorId) {
  // Get user data
  const profile = getProfile();

  const params = {
    email: profile.email,
    user_id: profile.sub,
    visitor_id: visitorId,
  };

  await axios.post('/api/updateTrackingVisitor', params);
}

export async function createEvent(Page, Action, url, metadata, eventType = 'pageview') {
  // return 0;
  // skip until review

  if (localStorage.getItem('visitor_id') == null || localStorage.getItem('visitor_id') == 0) {
    await this.createVisitor();
  }
  // get Ip
  var ipData = await axios.get('https://api.ipify.org/?format=json');
  // console.log('IP Data: ', ipData.data);

  metadata.page = Page;
  metadata.environment = process.env.REACT_APP_ENV;

  var paramsEvent = {
    visitor_id: Number(localStorage.getItem('visitor_id')),
    ip: ipData.data.ip,
    event_name: Action,
    event_type: eventType,
    metadata,
    url,
  };

  var event = await axios.post('/api/createTrackingEvent', paramsEvent);
  // console.log("Event Created ", event.data);
  return event.data;
}
export async function loadQueryString(parameters) {
  var query = parameters.url.split('?')[1];
  var searchString = query;
  var pairs = searchString.split('&');
  var parts;
  for (var i = 0; i < pairs.length; i++) {
    parts = pairs[i].split('=');
    var name = parts[0];
    var data = decodeURI(parts[1]);
    parameters[name] = data;
  }
  return parameters;
}
