import React, { useEffect, useRef, useState, useContext } from 'react';
import { CheckboxCheckedFilled16, Checkbox16, Close16, ArrowLeft24, Search24 } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import './searchBarSM.scss';
import { ScholaMatchContext } from '../../context/ScholaMatchContext';
import { t, tString } from 'components/translation/Translation';
import HtmlParser from 'html-react-parser';

const SearchBarSM = ({ dataArr, placeholder }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const { matchState, setMatchState } = useContext(ScholaMatchContext);
  const { interestSelectedFromSearchBar, interestNameFromSearchBar } = matchState;

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    setSelectedValue(interestSelectedFromSearchBar);
    setSelectedName(interestNameFromSearchBar);
  }, []);

  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (editMode && dropdownRef.current && !dropdownRef.current.contains(e.target)) setEditMode(false);
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [editMode]);

  const addFirstOption = (_items) => {
    _items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    let _option = _items[0]?.category;
    let newOption = { id: _option, name: `All ${_option} Extracurricular Activities`, category: _option };
    if (_option !== undefined) _items.unshift(newOption);
    return _items;
  };

  const regExpEscape = (literalString) => {
    return literalString.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  };

  useEffect(() => {
    if (dropdownSearchValue !== '') {
      let valueToSearch = regExpEscape(dropdownSearchValue);
      const _items = dataArr.filter((item) => {
        if (typeof item === 'object') {
          return (
            item.name.match(new RegExp(valueToSearch, 'gi')) || item.category.match(new RegExp(valueToSearch, 'gi'))
          );
        }
      });
      // const items = addFirstOption(_items)
      setFilteredValues(_items);
    } else {
      setFilteredValues(dataArr);
    }
  }, [dropdownSearchValue, dataArr]);

  const updateAndCleanUpOtherSelections = (selectedValue) => {
    setMatchState({
      ...matchState,
      interestSelectedFromSearchBar: selectedValue == [] ? [] : selectedValue,
      interestNameFromSearchBar: selectedName,
      interestSports: null,
      interestSportsOptions: [],
      interestArts: null,
      interestArtsOptions: [],
      interestScience: null,
      interestScienceOptions: [],
      interestEnviroment: null,
      interestEnviromentOptions: [],
      interestVolunteer: null,
      interestVolunteerOptions: [],
      interestCultural: null,
      interestCulturalOptions: [],
      interestLeadership: null,
      interestLeadershipOptions: [],
    });
  };

  useEffect(() => {
    updateAndCleanUpOtherSelections(selectedValue);
  }, [selectedValue]);

  const valueSelectionHandler = (value) => {
    if (typeof value === 'object') {
      if (selectedValue.includes(value.id)) {
        setSelectedValue(selectedValue.filter((item) => item !== value.id));
        setSelectedName(selectedName.filter((item) => item !== value.name));
      } else {
        setSelectedValue([...selectedValue, value.id]);
        setSelectedName([...selectedName, value.name]);
      }
    } else {
      setSelectedValue(value);
    }

    setDropdownSearchValue('');
    setEditMode(false);
  };

  const selectedValueCheck = (value) => {
    let _index = selectedName.indexOf(value);
    let _names = selectedName.filter((item) => item !== value);
    let _test = selectedValue.splice(_index, 1);
    let _results = selectedValue.filter((item) => item !== _test);
    setSelectedValue(_results);
    setSelectedName(_names);
  };

  const valueToBold = (match) => {
    let valueToSearch = regExpEscape(dropdownSearchValue);
    const regex = new RegExp(valueToSearch, 'gi');
    return match.replace(regex, '<strong>$&</strong>');
  };

  const handleKeyDown = (value) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedValue([...selectedValue, value]);
        setEditMode(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedValue([...selectedValue, value]);
        setEditMode(false);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedValue([...selectedValue, value]);
        setEditMode(false);

        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        ref={dropdownRef}
        className={width < breakpoint && editMode ? 'searchMobile search-barSM' : 'search-barSM'}
        data-testid="search-barSM">
        {editMode ? (
          <>
            <div className={width < breakpoint && editMode ? 'comboboxMobile_nav' : null}>
              {width < breakpoint && (
                <span className="close" onClick={() => setEditMode(false)}>
                  <ArrowLeft24 />
                </span>
              )}
              <div className="search-barSM__inputContainer">
                <input
                  className="search-barSM__input"
                  data-testid="search-barSM"
                  name="dropdown-input"
                  autoFocus
                  onChange={(e) => setDropdownSearchValue(e.target.value)}
                  value={dropdownSearchValue || ''}
                  placeholder={tString('scholaMatch:interest.selectActivities.searchPlaceholder')}
                />
                <Search24 className={width < breakpoint && editMode ? 'search-icon-mobile' : 'search-icon'} />
              </div>
            </div>
            {dropdownSearchValue && (
              <div className="search-barSM__box">
                <div className="search-barSM__list">
                  <ul tabIndex={-1} data-testid="search-barSM-result" role="listbox">
                    {filteredValues.map((value, i) => {
                      return (
                        <React.Fragment key={i}>
                          <li
                            key={value}
                            onClick={() => valueSelectionHandler(value)}
                            tabIndex={0}
                            aria-selected={selectedValue}
                            onKeyDown={handleKeyDown(value)}>
                            <span aria-hidden="true">
                              {selectedName?.some((item) => item == value.name) ? (
                                <CheckboxCheckedFilled16 fill="#0099CC" />
                              ) : (
                                <Checkbox16 fill="#6E7B91" />
                              )}
                            </span>
                            <div className="search-barSM__wrapper">
                              <span className="search-barSM__dropdown">{HtmlParser(valueToBold(value.name))}</span>
                              {value.category ? (
                                <>
                                  <span className="spacing-mr-8 separator"> - </span>
                                  <span className="search-barSM__dropdown__subtext">
                                    {HtmlParser(valueToBold(value.category))}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </li>
                          {filteredValues.length - 1 == i ? null : <span className="search-barSM__divider" />}
                        </React.Fragment>
                      );
                    })}
                    {filteredValues.length === 0 && <li className="no-result">No Results Found</li>}
                  </ul>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="search-barSM__inputContainer">
              <input
                className={`search-barSM__input 
                        `}
                onClick={() => setEditMode(true)}
                onFocus={() => setEditMode(true)}
                onChange={(e) => setDropdownSearchValue(e.target.value)}
                placeholder={tString('scholaMatch:interest.selectActivities.searchPlaceholder')}
                value={dropdownSearchValue || ''}
                data-testid="searchbar-SM-input"
              />
              <Search24 />
            </div>
          </>
        )}
        <p className="selectActivity__info">{t('scholaMatch:interest.selectActivities.subtextSearch')}</p>

        <div
          className={
            width < breakpoint && editMode
              ? 'search-barSM__chips-container chip-container-search-mobile'
              : 'search-barSM__chips-container'
          }
          data-testid="searchbar-SM-result">
          {selectedName?.map((item) => {
            return (
              <span className="search-barSM__chip" key={item} data-testid="search-barSM_chip">
                {item}
                <span className="search-barSM__chip-close" onClick={() => selectedValueCheck(item)}>
                  <Close16 />
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};

SearchBarSM.propTypes = {
  dataArr: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

export default SearchBarSM;
