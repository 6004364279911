import React, { useMemo } from 'react';
import { Building24, EventsAlt24, Person24, Chemistry20 } from '@carbon/icons-react';
import styles from './school-type.module.scss';
import { t } from 'components/translation/Translation';
import { getGradesRange } from '../../utils/get-grades-range';
import { FeatureChip } from '..';

export const SchoolType = ({ type }) => {
  return (
    <FeatureChip icon={<Building24 />} label={t('schoolProfile:characteristics.claimed.schoolType')} value={type} />
  );
};

export const GradesSchool = ({ grades }) => {
  const gradesRange = useMemo(() => (grades ? getGradesRange(grades) : null), []);

  return (
    <FeatureChip label={t('schoolProfile:characteristics.claimed.grades')} value={gradesRange} icon={<EventsAlt24 />} />
  );
};

export const LearningEnvoronment = ({ learningEnviroment }) => {
  if (!learningEnviroment) return null;

  return (
    <FeatureChip
      label={t('schoolProfile:characteristics.claimed.learningEnvironment')}
      value={learningEnviroment.map((item) => item.name).join(', ')}
      icon={<Person24 />}
    />
  );
};
