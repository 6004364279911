import client from '../client';
import { getResponseValue } from 'util/validate';

export const _getFeatures = (grades) => {
  return client.get('features', grades ? { grades } : null);
};
export const _getFeaturesCategories = (grades) => {
  return client.get('features/categories');
};
export const _handleParentSearch = async (userId, payload) => {
  return await client.post(`sf/parent/search/${userId}`, payload);
};

export const _getSpecialFeatures = async () => {
  const res = await client.get('features/special-needs');
  return await getResponseValue(res);
};
