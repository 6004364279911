import React, { useState } from 'react';
import isEmpty from 'util/isEmpty';
import Pagination from '../Pagination';
import StarsExperience from './StarsExperience';
import './listReview.scss';

const ListReviews = ({ reviews }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(5);
  const defaultLogo = 'https://static.schola.com/profile/schola-logo-icon.webp';
  const { results } = reviews;

  // Get current reviews
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = results?.slice(indexOfFirstReview, indexOfLastReview);

  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <>
      <div className="v3-review" data-testid="listReviewsContainer">
        {currentReviews?.map((review, index) => (
          <div key={`review_${index}`} className="spacing-mt-32">
            <div className="spacing-mb-24">
              <div className="v3-review-user" data-testid="listReviewsUser">
                {/* <img
                  className="avatar"
                  src={
                    !isEmpty(review.user_picture)
                      ? review.user_picture
                      : 'https://static.schola.com/happy-customer.svg'
                  }
                /> */}
                <div>
                  <h5>{review.user_name}</h5>
                  {/* <span className="body-xs-small spacing-mt-12">{formatDate(review.created_at)}</span> */}
                  {/* <span className="body-xs-small spacing-mt-12">{review.created_at}</span> */}
                </div>
              </div>
              <div className="spacing-mt-12 review-rating" data-testid="listReviewsRaiting">
                <img
                  width="20"
                  height="20"
                  className="image-logo"
                  src={review.logo ?? defaultLogo}
                  alt={`${review.logo ?? 'schola'} Logo`}
                  loading="lazy"
                />
                <StarsExperience
                  color="rgb(239, 210, 76)"
                  value={review.rate}
                  spaceBetween={5}
                  size={16}
                  notPointer
                  onlyView
                />

                {/* <StarRankingView name="ParentSatisfaction" value={(review.parent_satisfaction && review.parent_satisfaction > 0 ? ~~(review.parent_satisfaction / 20) + (review.parent_satisfaction % 20 >= 10 ? 0.5 : 0) : 0)} starCount={5} /> */}
              </div>

              <p className="body-small spacing-mt-12" data-testid="listReviewsMessage">
                {review.message}
              </p>
            </div>
          </div>
        ))}

        {results?.length <= 3 ? null : (
          <Pagination
            reviewsPerPage={reviewsPerPage}
            totalReviews={results?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
};

export default ListReviews;
