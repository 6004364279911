import React from 'react';
import PropTypes from 'prop-types';
import { Close16, ArrowLeft16 } from '@carbon/icons-react';
import classNames from 'classnames';
import './modal-mobile.scss';

export const ModalMobile = ({ onClose, title, children, typeButton = 'close', withChildContainer = true }) => {
  const closeClasses = {
    buttonIcon: true,
    close: typeButton === 'close',
    back: typeButton === 'back',
  };

  const childrenClasses = {
    modal_mobile_children_container: withChildContainer,
  };

  const icons = {
    close: <Close16 />,
    back: <ArrowLeft16 />,
  };

  return (
    <div className="modal_mobile">
      <div className="modal_mobile_header">
        <button onClick={onClose} aria-label="exit" className={classNames(closeClasses)}>
          {icons[typeButton]}
        </button>
        {title && <h3 className="modal_mobile_header_title">{title}</h3>}
      </div>
      <div data-testid="children" className={classNames('modal_mobile_children', childrenClasses)}>
        {children}
      </div>
    </div>
  );
};

ModalMobile.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
};
