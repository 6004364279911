import React from 'react';
import styles from './loading-profile.module.scss';

export const LoadingProfile = () => {
  return (
    <>
      <div className={styles.fullWidth} />
      <div className={styles.loading}>
        <div className={styles.info}>
          <div className={styles.shared}>
            <div className={styles.button} />
            <div className={styles.button} />
          </div>
          <div className={styles.header}>
            <div className={styles.picture} />
            <div className={styles.name} />
          </div>
          <div className={styles.name} />
          <div className={styles.block} />
          <div className={styles.block} />
          <div className={styles.block} />
        </div>
        <div className={styles.column}>
          <div className={styles.column_item} />
          <div className={styles.column_item_cta} />
          <div className={styles.column_item_cta} />
        </div>
      </div>
    </>
  );
};
