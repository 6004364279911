import { useEffect, useState } from 'react';
import { planName } from 'util/schoolUtils';
import { useGetFeaturesQuery } from 'redux/api/school-api';
import { useSearchSchools } from './useSearchSchools';

export const useSchoolMatch = ({ query, gradeQuery, schoolType, featuresSelected }) => {
  const { data: featuresGeneral, isLoading, isSuccess } = useGetFeaturesQuery();

  const { rawSchools, successSchools, loadingSchools, errorSchools, isFetching } = useSearchSchools({ query });
  const [schools, setSchools] = useState([]);

  const [sponsored, setSponsored] = useState([]);

  // const featuresPersonality = new Set([...featuresByPersonality, ...featuresSelected]);
  const featuresPersonality = new Set(featuresSelected);

  // separate responsabilities for filtering schools based on url change
  useEffect(() => {
    if (successSchools) {
      /** 1. Filter by grades and types selected */
      let filteredSchools = getFilteringSchools({ rawSchools, gradeQuery, schoolType });

      /** 2. Assign features matched by school according to current selection */
      if (isSuccess) {
        filteredSchools = getAssignMatches({ schools: filteredSchools, featuresGeneral, featuresPersonality });
      }

      /** 3. Apply sorting based of number of features matched */
      filteredSchools = filteredSchools.sort((a, b) => b.featuresMatchedLevel - a.featuresMatchedLevel);

      /** 4. Separate top 3 from the rest of the list */
      const organicSchools = filteredSchools.slice(0, 3);
      const remainingSchools = filteredSchools.slice(3);

      /** 5. Select schools with plan and remove it from remainingSchools list, max 3 spots, have to be out of the top organic 3 */
      let sponsoredSchools = [];

      for (let index = 0; index < 3; index++) {
        const findProSchool = remainingSchools.findIndex((school) => planName(school) !== 'scholabasics');
        if (findProSchool > -1) {
          sponsoredSchools.push(remainingSchools[findProSchool]);
          remainingSchools.splice(findProSchool, 1);
        }
      }

      setSponsored(sponsoredSchools);
      setSchools([...organicSchools, ...remainingSchools]);
    }
  }, [rawSchools, gradeQuery, schoolType, featuresSelected, featuresGeneral]);

  return {
    schools,
    sponsored,
    foundSchools: schools.length + sponsored.length,
    loadingSchools,
    successSchools,
    errorSchools,
    isFetching,
  };
};

const getFilteringSchools = ({ rawSchools, gradeQuery, schoolType }) => {
  let filteredSchools = [...rawSchools];

  if (gradeQuery !== 'all') {
    filteredSchools = filteredSchools.filter((school) => school.grades.some((g) => gradeQuery.split(',').includes(g)));
  }

  if (schoolType !== 'all' || !schoolType) {
    filteredSchools = filteredSchools.filter((school) => schoolType.split(',').includes(school.type));
  }

  return filteredSchools;
};

const getAssignMatches = ({ schools, featuresGeneral, featuresPersonality }) => {
  const matchesBySchool = schools.map((school) => {
    const matches = matchFeatures({ featuresSchool: school.features_weightage, featuresPersonality });
    let featuresMatched = [];
    matches.forEach((featureId) => {
      const name = getFeature(featureId, featuresGeneral);
      if (name) {
        featuresMatched.push(name);
      }
    });
    return {
      ...school,
      featuresMatched,
      featuresMatchedLevel: matches.length,
    };
  });
  return matchesBySchool;
};

const matchFeatures = ({ featuresSchool, featuresPersonality }) => {
  const featuresOffered = featuresSchool.map((feature) => feature.feature_id);

  return featuresOffered.filter((feature) => featuresPersonality.has(feature));
};

const getFeature = (featureId, featuresList) => {
  for (const group of featuresList) {
    const feature = group.features.find((f) => f.id === featureId);
    if (feature) {
      return {
        id: feature.id,
        name: feature.name,
        groupId: group.id,
        groupName: group.name,
      };
    }
  }

  return null;
};
