import client from '../client';

export const GET_FEATURES = 'GET_FEATURES';
export const GET_FEATURED_LIST = 'GET_FEATURED_LIST';
export const SET_USER_CLICK = 'SET_USER_CLICK';
export const SET_USER_CLICK_RESET = 'SET_USER_CLICK_RESET';
export const ADD_SUGGESTION = 'ADD_SUGGESTION';
export const SEND_TRANSPORTATION_REQUEST = 'SEND_TRANSPORTATION_REQUEST';
export const SEND_DEMO_REQUEST = 'SEND_DEMO_REQUEST';
export const SEND_HANDLE_PARENT_SEARCH = 'SEND_HANDLE_PARENT_SEARCH';

export function getFeatures(grades) {
  return {
    type: GET_FEATURES,
    promise: client.get('features', grades ? { grades } : null),
  };
}

export function setFeatures(ids) {
  return {
    type: 'SET_FEATURES_ID',
    data: ids,
  };
}

export function setSearchQuery(querydata) {
  return {
    type: 'SET_SEARCH_QUERY',
    data: querydata,
  };
}

export function getFeaturedList(params = {}) {
  return {
    type: 'GET_FEATURED_LIST',
    promise: client.get('schools/top_featured', params),
  };
}

export function setUserClick(schoolId) {
  return {
    type: 'SET_USER_CLICK',
    promise: client.post(`schools/${schoolId}/click`),
  };
}

export function reSetUserClick() {
  return {
    type: 'SET_USER_CLICK_RESET',
  };
}

export function sendSuggestion(message) {
  var payload = new FormData();
  payload.append('message', message);
  return {
    type: ADD_SUGGESTION,
    promise: client.post(`features/suggestion`, payload),
  };
}

export function sendTransportationRequest(email) {
  var payload = new FormData();
  payload.append('email', email);
  return {
    type: SEND_TRANSPORTATION_REQUEST,
    promise: client.post(`features/transportation`, payload),
  };
}

export function sendDemoRequest(name, email, day, time) {
  var payload = new FormData();
  payload.append('name', name);
  payload.append('email', email);
  payload.append('day', day);
  payload.append('time', time);

  return {
    type: SEND_TRANSPORTATION_REQUEST,
    promise: client.post(`features/demo`, payload),
  };
}

export function handleParentSearch(userId, payload) {
  return {
    type: SEND_HANDLE_PARENT_SEARCH,
    promise: client.post(`sf/parent/search/${userId}`, payload),
  };
}
