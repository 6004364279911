import React from 'react';
import styles from './school-facilities.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SchoolFacilitiesCard } from './school-facilities-card';

export const SchoolFacilities = ({ facilities, className, title }) => {
  const SchoolFacilitiesClasses = {
    [className]: className,
  };
  return (
    <div
      className={classNames(SchoolFacilitiesClasses)}
      id="school-facilities"
      aria-labelledby="school-facilities-title">
      {title && (
        <h2 id="school-facilities-title h2" className="section-title spacing-mb-20">
          {title}
        </h2>
      )}
      <div className={styles.facilities_cards}>
        {facilities.map((facility, index) => (
          <SchoolFacilitiesCard
            key={index}
            image={facility.filename}
            title={facility.name}
            description={facility.description}
          />
        ))}
      </div>
    </div>
  );
};
SchoolFacilities.propTypes = {
  lassName: PropTypes.string,
  facilities: PropTypes.array,
  title: PropTypes.string,
};
