import client from 'redux/client';

export const GET_SCHOOL_EVENTS = 'GET_SCHOOL_EVENTS';
export const SUBMIT_COMMENT_SCHOOL_REVIEWS = 'SUBMIT_COMMENT_SCHOOL_REVIEWS';

export function getSchoolEvents(schoolId, params) {
  return {
    type: GET_SCHOOL_EVENTS,
    promise: client.get(`schools/${schoolId}/upcoming-events`, params),
  };
}

export function submitCommentSchoolReviews(schoolId, reviewId, payload) {
  return {
    type: SUBMIT_COMMENT_SCHOOL_REVIEWS,
    promise: client.post(`schools/${schoolId}/user-reviews/${reviewId}/user-comments`, payload),
  };
}
