import React from 'react';
import useScholaMatch from './useScholaMatch';
import useWidthScreen from './useWidthScreen';

export const ScholaMatchContext = React.createContext({});

const ScholaMatchProvider = ({ children }) => {
  const scholaMatchState = useScholaMatch();
  const width = useWidthScreen();

  return <ScholaMatchContext.Provider value={{ ...scholaMatchState, width }}>{children}</ScholaMatchContext.Provider>;
};

export default ScholaMatchProvider;
