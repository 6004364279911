import React, { useState } from 'react';
import { Quotes32 } from '@carbon/icons-react';
import { useGetTestimonialsQuery } from 'redux/api/school-api';
import styles from './testimonial-card.module.scss';
import Slider from 'react-slick';
import { tString } from 'components/translation/Translation';
import { Divider } from '../divider/divider';

export const Testimonials = ({ schoolId }) => {
  const { isLoading, isSuccess, data: testimonials } = useGetTestimonialsQuery(schoolId);

  if (isLoading) return <div>Loading</div>;

  if (isSuccess && !testimonials.length) return null;

  const settingSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="spacing-my-32">
        <h2 className="spacing-mb-24">{tString('schoolProfile:testimonials')}</h2>
        <div>
          <Slider {...settingSlider}>
            {testimonials.map((obj, index) => (
              <div className="spacing-px-12" key={`testimonial_${index}`}>
                <TestimonialCard obj={obj} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Divider />
    </>
  );
};

const TestimonialCard = ({ obj }) => {
  const [isExtended, setIsExtended] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleExtend = () => {
    setIsExtended(!isExtended);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const limitCharacters = 485;

  const sliceTestimonial = () => {
    let slice = obj.testimonial.slice(0, limitCharacters);

    if (obj.testimonial.length > limitCharacters) {
      slice += '...';
    }
    return slice;
  };

  return (
    <article
      className={`${styles.testimonialCard} ${isDragging ? styles.grabbing : ''}`}
      data-testid="testimonialCard"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}>
      <p className={styles.testimonialCard_text} data-testid="testimonialCardText">
        {!isExtended ? sliceTestimonial() : obj.testimonial}
      </p>

      {obj.testimonial.length > limitCharacters && (
        <p onClick={handleExtend} className={styles.testimonialCard_showButton}>
          {!isExtended ? 'Show more' : 'Show less'}
        </p>
      )}

      <div className={styles.testimonialCard_info} data-testid="testimonialCardInfo">
        <div className={styles.testimonialCard_info_layout}>
          <div>
            <h3 className={styles.testimonialCard_info_name}>{obj.full_name}</h3>
            <div className={styles.testimonialCard_info_role}>{obj.role}</div>
          </div>
        </div>
        <Quotes32 />
      </div>
    </article>
  );
};
