import React from 'react';
import styles from './chip-feature.module.scss';

export const ChipFeature = ({ children, className, ...props }) => {
  return (
    <div className={`${styles.chip} ${className || ''}`} {...props}>
      {children}
    </div>
  );
};
