export const allPublics = ['public district', 'public charter', 'public magnet'];

export const schoolTypes = [
  {
    label: 'All Types',
    value: 'all',
    level: 'checkLevel0',
  },
  {
    label: 'Public',
    value: 'public',
    level: 'checkLevel1',
  },
  {
    label: 'District',
    value: 'public district',
    level: 'checkLevel2',
  },
  {
    label: 'Charter',
    value: 'public charter',
    level: 'checkLevel2',
  },
  {
    label: 'Magnet',
    value: 'public magnet',
    level: 'checkLevel2',
  },
  {
    label: 'Private',
    value: 'private',
    level: 'checkLevel1',
  },
];
