import { tString } from 'components/translation/Translation';
import { useState, useEffect } from 'react';

const STAFF_TRANSLATIONS = {
  nurse: { name: 'schoolProfile:staff.nurse' },
  psychologist: { name: 'schoolProfile:staff.psychologist' },
  social_worker: { name: 'schoolProfile:staff.social_worker' },
  law_enforcement_officer: { name: 'schoolProfile:staff.law_enforcement_officer' },
  security_guard: { name: 'schoolProfile:staff.security_guard' },
};

export const useStaffMembers = (data) => {
  const [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (data && data.school_teachers_staff && data.school_teachers_staff.length > 0) {
      const staffProperties = ['nurse', 'psychologist', 'social_worker', 'law_enforcement_officer', 'security_guard'];

      const updatedStaffMembers = staffProperties.map((property, idx) => {
        if (data.school_teachers_staff[0] && data.school_teachers_staff[0][property] !== 'no') {
          return {
            id: idx,
            name: tString(STAFF_TRANSLATIONS[property].name),
            group: { name: 'staff' },
          };
        }
        return null;
      });

      if (isMounted) {
        setStaffMembers(updatedStaffMembers.filter(Boolean));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  return staffMembers;
};
