import client from '../client';

export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const GET_BULLETIN = 'GET_BULLETIN';

export function getLandingPage(location) {
  return {
    type: GET_LANDING_PAGE,
    promise: client.get(`landing-pages/${location}`),
  };
}

// bulletin board api
export function getBulletinBoard(params) {
  return {
    type: GET_BULLETIN,
    promise: client.get(`schools/bulletin_board`, params),
  };
}
