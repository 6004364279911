import { planName } from 'util/schoolUtils';
import { CONTACT_TYPE } from 'redux/slices/types';

export const getContactType = (school, isMultiple = false) => {
  if (isMultiple) return CONTACT_TYPE.multiple;

  if (!school.claimed) return CONTACT_TYPE.unclaimed;

  const plan = planName(school);

  if (plan === 'scholarecruiterpro') return CONTACT_TYPE.recruiterPro;

  if (school.type === 'public district') return CONTACT_TYPE.publicDistrict;

  return CONTACT_TYPE.standard;
};
