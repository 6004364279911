import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSchool, onOpenAddBoard } from 'redux/board/board-actions';
import { t } from 'components/translation/Translation';
import { Favorite16, FavoriteFilled16 } from '@carbon/icons-react';
import { Buttonv2 as Button } from 'schola-components';
import { useResponsiveRender } from 'hooks/useResponsiveRender';
import { isFavorite } from 'components/SchoolFavorites/utils';
import { getProfile } from 'util/auth';
import './galleryButtons.scss';
import { toggleShare } from 'redux/slices/school-profile.slice';
import { ShareAltOutlined } from '@ant-design/icons';

const GalleryButtons = ({ schoolId, nav }) => {
  const dispatch = useDispatch();
  const favoritesState = useSelector((state) => state.favorites);

  const { boards, favoritesIDs } = favoritesState;

  const handleOpen = () => dispatch(onOpenAddBoard(schoolId));

  const profile = useMemo(() => getProfile(), []);

  const remove = async () => {
    const findBoard = boards.boardSchools.find((item) => item.school_id === schoolId);

    dispatch(removeSchool(profile?.sub, findBoard.board_id, schoolId));
  };
  const handleShare = () => dispatch(toggleShare());

  const { renderMobile: isMobile } = useResponsiveRender({ breakpoint: 525 });

  const saveIcon = isFavorite(favoritesIDs, schoolId) ? (
    <Button icon color="secondary" onClick={remove} className="galleryButtons_button">
      <FavoriteFilled16 fill="#006B8F" />
    </Button>
  ) : (
    <Button icon color="secondary" className="galleryButtons_button" onClick={handleOpen}>
      <Favorite16 />
      {!isMobile && <p>{t('schoolProfile:gallery.save')}</p>}
    </Button>
  );

  return (
    <div className={`${nav ? 'galleryButtons navButtons' : 'galleryButtons'}`}>
      <Button icon color="secondary" size="small" className="galleryButtons_button" onClick={handleShare}>
        <ShareAltOutlined />
        {!isMobile && <p>{t('schoolProfile:gallery.share')}</p>}
      </Button>

      {saveIcon}
    </div>
  );
};

export default GalleryButtons;
