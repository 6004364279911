import moment from 'moment';

export const isRecruiterPro = ({
  plan_id: planId,
  subscription_months: subscriptionMonths,
  subscription_end: subscriptionEnd,
}) => {
  return (
    (planId === 'monthlypro' && moment(subscriptionEnd).isAfter()) ||
    (planId === 'yearlypro' && moment(subscriptionEnd).isAfter())
  );
};
