import client from '../client';

export const _createSchoolReview = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/user-reviews/save`, payload);
};

export const _getSchoolReviews = async (schoolId) => {
  return await client.get(`schools/${schoolId}/user-reviews/list`);
};

export const _getSchoolsReviews = async (schools) => {
  return await client.post(`schools/user-reviews/list`, schools);
};
