import { useEffect, useState } from 'react';
import { isAnyChildArrayNotEmpty } from 'util/isEmpty';

export const useDisplayFeatures = (data) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    let mounted = true;

    const checkDisplay = () => {
      if (isAnyChildArrayNotEmpty(data)) {
        if (mounted) {
          setDisplay(true);
        }
      } else if (mounted) {
        setDisplay(false);
      }
    };

    checkDisplay();

    return () => {
      mounted = false;
    };
  }, [data]);

  return display;
};
