import {
  question4Features as extracurricular,
  question7Features as specializedPrograms,
} from 'components/pages/scholamatch/schola-match-v5';

export const categoriesRequested = (featuresRequested) => {
  const isTypeMultiple = ['extracurricular', 'specializedPrograms'];
  const multipleOptions = {
    extracurricular,
    specializedPrograms,
  };

  return Object.entries(featuresRequested)
    .map(([type, values]) =>
      values.labelId.map((label) => ({
        ...values,
        bucket: isTypeMultiple.includes(type) ? multipleOptions[type][label.answer] : values.bucket,
        type,
        label: label.label,
        offeredBy: [],
      }))
    )
    .flat()
    .filter((item) => item.label);
};

export const transformSchoolsForTable = ({ schools = [], features }) => {
  const featuresRequested = { ...features };
  delete featuresRequested.labels;

  const newListFeatures = categoriesRequested(featuresRequested);

  const transformedTypes = newListFeatures[0] ? newListFeatures[0].bucket.map((item) => item.replace(/\+/g, ' ')) : '';

  const areSchoolClose = { label: '🚶‍♀️ Close to Home!', offeredBy: [] };

  // Validate each field by school
  const schoolsTransformed = schools
    .map((school) => {
      const { featuresBySchool } = school;

      /** Item on 0 is school type and is filtered different from other buckets */
      const type = setFeatureOffered({
        validation: transformedTypes.includes(school.type),
        finalList: newListFeatures[0] ? newListFeatures[0].offeredBy : [],
        schoolId: school.id,
      });

      // Close to:
      const closeTo = setFeatureOffered({
        validation: school.distance <= 2.5,
        finalList: areSchoolClose.offeredBy,
        schoolId: school.id,
      });

      const listOffered = [];
      newListFeatures.slice(1).forEach((category) => {
        const isFeatureOffered = setFeatureOffered({
          validation: featuresBySchool.some((feature) => category.bucket.includes(feature)),
          finalList: category.offeredBy,
          schoolId: school.id,
        });
        listOffered.push(isFeatureOffered);
      });

      const offerBySchool = { id: school.id, feats: [type, ...listOffered, closeTo] };

      const sortByCompleted = offerBySchool.feats.filter((item) => item).length;

      return {
        ...school,
        features: featuresBySchool,
        score: school.score,
        matchLevel: school.matchLevel,
        photo: school.profile_image,
        sortByCompleted,
      };
    })
    .sort((a, b) => b.sortByCompleted - a.sortByCompleted);
  const finalOfferings = [...newListFeatures, areSchoolClose];

  return { schoolsTransformed, newListFeatures: finalOfferings };
};

export const transformSponsoredSchool = (school, features) => {
  const featuresRequested = { ...features };
  delete featuresRequested.labels;

  const labelsRequested = categoriesRequested(featuresRequested);

  /** Item on 0 is school type and is filtered different from other buckets */
  const transformedTypes = labelsRequested[0]?.bucket.map((item) => item.replace(/\+/g, ' '));

  const { featuresBySchool } = school;

  const topics = [];

  if (transformedTypes) {
    setFeatureOffered({ validation: transformedTypes.includes(school.type), finalList: topics });
  }

  labelsRequested.slice(1).forEach((category) =>
    setFeatureOffered({
      validation: featuresBySchool.some((feature) => category.bucket.includes(feature)),
      finalList: topics,
    })
  );
  setFeatureOffered({ validation: school.distance <= 2.5, finalList: topics });

  return { featuresBySponsered: topics };
};

const setFeatureOffered = ({ validation, finalList = [], schoolId }) => {
  const item = { check: validation, schoolId };
  finalList.push(item);
  return validation;
};
