import { useEffect, useState } from 'react';

export const useTrackApps = (applications, onLoadSchool) => {
  const [showFollowup, setShowFollowup] = useState(false);

  const [selectedApplication, setSelectedApplication] = useState({});
  const [studentSelected, setStudentSelected] = useState({});

  const [applicationsForSelectedStudent, setApplicationsForSelectedStudent] = useState([]);
  const [enrolledForSelectedStudent, setEnrolledForSelectedStudent] = useState([]);
  const [canceledApplications, setCanceledApplications] = useState([]);

  const onSelectApplication = (app) => {
    setSelectedApplication(app);
  };
  const onSelectStudent = (student) => {
    setStudentSelected(student);
    studentSelected?.name !== student?.name && onResetAppPanel();
    getDisplayLeads();
  };

  const getDisplayLeads = () => {
    if (applications?.length > 0 && studentSelected?.name) {
      let filterPerStudent = applications.filter((lead) =>
        studentSelected.name.includes(`${lead.child_first_name} ${lead.child_last_name}`)
      );
      const activeApplications = filterPerStudent.filter((app) => app.status !== 'archived');
      setApplicationsForSelectedStudent(activeApplications);

      const enrolledApplications = filterPerStudent.filter((app) => app.status === 'accepted');
      setEnrolledForSelectedStudent(enrolledApplications);

      const canceledApp = filterPerStudent.filter((app) => app.status === 'archived');
      setCanceledApplications(canceledApp);
    }
  };

  useEffect(() => {
    getDisplayLeads();
  }, [applications, studentSelected]);

  const viewProgressAction = (application) => {
    setShowFollowup(true);
    onSelectApplication(application);

    if (selectedApplication?.school_id !== application.school_id) {
      onLoadSchool(application.school_id);
    }
  };

  const onResetAppPanel = () => {
    setShowFollowup(false);
    onSelectApplication({});
  };

  const closeFollowup = () => {
    onResetAppPanel();
  };

  return {
    // states:
    showFollowup,
    selectedApplication,
    studentSelected,
    applicationsForSelectedStudent,
    enrolledForSelectedStudent,
    canceledApplications,
    // methods:
    onSelectApplication,
    onSelectStudent,
    viewProgressAction,
    closeFollowup,
    getDisplayLeads,
  };
};
