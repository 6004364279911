import {
  BuildingInsights_320 as BuildingInsights320,
  CurrencyDollar20,
  Basketball20,
  Book20,
  Chemistry20,
  Education20,
  Certificate20,
  HelpDesk20,
  Hearing20,
  Time20,
  Translate20,
  Partnership20,
  PaintBrush20,
  WatsonHealthAiStatusComplete20,
  Collaborate20,
  Sun20,
  Transgender20,
  FaceSatisfied20,
  CalendarHeatMap20,
  Rocket20,
  Events20,
  UserSpeaker20,
  WatsonHealthHangingProtocol20,
  Bus20,
} from '@carbon/icons-react';

export const getFeatureIcon = (featureName, color = '#5F6A7D') => {
  const ICONS = {
    'High School-Specific Programs': <Education20 fill={color} />,
    'School Learning Environment': <Partnership20 fill={color} />,
    'Additional language classes': <Translate20 fill={color} />,
    'Art & Communication': <PaintBrush20 fill={color} />,
    'Core Knowledge': <Certificate20 fill={color} />,
    Cultural: <Collaborate20 fill={color} />,
    Environmental: <Sun20 fill={color} />,
    Gender: <Transgender20 fill={color} />,
    'High school only': <Education20 fill={color} />,
    'High School-Specific Classes': <Education20 fill={color} />,
    'Language Education': <Translate20 fill={color} />,
    'Religious Affiliation': <FaceSatisfied20 fill={color} />,
    Schedule: <Time20 fill={color} />,
    'Scholarship Programs': <CurrencyDollar20 fill={color} />,
    'School Facilities': <BuildingInsights320 fill={color} />,
    'School Classes': <Book20 fill={color} />,
    'School Accreditations': <Certificate20 fill={color} />,
    'School Supports': <HelpDesk20 fill={color} />,
    'Sciences & Technology': <Rocket20 fill={color} />,
    'Special Needs': <Hearing20 fill={color} />,
    'Special Needs Support': <Events20 fill={color} />,
    'Specific Educational Approach': <Education20 fill={color} />,
    Teachers: <Education20 fill={color} />,
    'Sports & Outdoors': <Basketball20 fill={color} />,
    'Student Government & Leadership': <UserSpeaker20 fill={color} />,
    Traditional: <Education20 fill={color} />,
    Uniforms: <WatsonHealthHangingProtocol20 fill={color} />,
    Transportation: <Bus20 fill={color} />,
    'Volunteer & Community': <Events20 fill={color} />,
  };

  return ICONS[featureName] ? ICONS[featureName] : <Chemistry20 fill={color} />;
};
