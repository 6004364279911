import { GroupFeatures } from '..';

export const LearningEnvironments = ({ featuresSelection, handleFeatureSelection }) => {
  const learningEnvironments = [
    { id: 9, name: 'Online Only' },
    { id: 513, name: 'In-Person Only' },
    { id: 408, name: 'Hybrid (Online + In-Person)' },
    { id: 447, name: 'Homeschool' },
    { id: 446, name: 'Homeschool with On-Site Support' },
    { id: 342, name: 'Boarding School' },
  ];
  /**
    Online Only - Fully virtual classes, accessible from home or anywhere with internet.
    In-Person Only - Traditional on-campus learning.
    Hybrid (Online + In-Person) - A mix of online and on-campus classes.
    Homeschool - Parent-directed education at home.
    Homeschool with On-Site Support - Home-based learning combined with some on-campus programs.
    Boarding School - Full-time on-campus living and learning.
   */

  return (
    <GroupFeatures
      title="School Learning Environment"
      features={learningEnvironments}
      featuresSelection={featuresSelection}
      handleFeatureSelection={handleFeatureSelection}
    />
  );
};
