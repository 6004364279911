import React, { useEffect, useState } from 'react';
import { getProfile } from 'util/auth';
import { CloseOutlined, LoadingOutlined, CheckCircleFilled, MessageFilled } from '@ant-design/icons';
import styles from './survey-feedback.module.scss';
import { ButtonRetool } from '..';
import { useForm } from 'hooks/useForm';
import { useSendFeedbackMutation } from 'redux/api/sm-api';

export const SurveyFeedback = ({ searchHistoryId }) => {
  const [showFeedBack, setShowFeedBack] = useState(false);

  const userId = getProfile()?.sub;

  const { handleChange, handleValue, values } = useForm({
    initialValue: {
      like: '',
      message: '',
      from: 'ScholaMatch - v4',
      history_id: searchHistoryId,
      user_id: userId || '',
      created_at: new Date().toLocaleString(),
    },
  });

  useEffect(() => {
    if (searchHistoryId) {
      handleValue('history_id', searchHistoryId);
    }
  }, [searchHistoryId]);

  const [createFeedback, { isLoading, isSuccess }] = useSendFeedbackMutation();

  const submitFeedback = () => createFeedback(values);

  if (!showFeedBack) {
    return (
      <button className={`${styles.container} ${styles.popButton}`} onClick={() => setShowFeedBack(true)}>
        <MessageFilled />
      </button>
    );
  }

  return (
    <>
      {showFeedBack && (
        <div className={`${styles.container} ${styles.formBox}`}>
          <button onClick={() => setShowFeedBack(false)} className={styles.close_icon_container}>
            <CloseOutlined style={{ cursor: 'pointer' }} />
          </button>
          <div className={styles.content}>
            {isSuccess ? (
              <FeedbackResponse />
            ) : (
              <>
                <p className={styles.question}>Did You Like These School Results?</p>
                <div className={styles.buttons}>
                  <button
                    id="yes"
                    name="like"
                    value="yes"
                    onClick={handleChange}
                    className={`${styles.button} ${values.like === 'yes' && styles.currentSelected}`}>
                    Yes
                  </button>
                  <button
                    id="no"
                    name="like"
                    value="no"
                    onClick={handleChange}
                    className={`${styles.button} ${values.like === 'no' && styles.currentSelected}`}>
                    No
                  </button>
                </div>
                <p className={styles.question}>How Can We Make Them Better?</p>
                <textarea
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  className={styles.textarea}
                />
                <div className={styles.buttonContainer}>
                  <ButtonRetool className={styles.button_send} onClick={submitFeedback} disabled={isLoading}>
                    Send Feedback
                  </ButtonRetool>
                  {isLoading && <LoadingOutlined />}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const FeedbackResponse = () => {
  return (
    <div className={styles.success}>
      <CheckCircleFilled />
      <h5>Thanks for share your feedback with us!</h5>
    </div>
  );
};
