import { useToggle } from 'hooks/useToggle';
import { CaretDownFilled } from '@ant-design/icons';
import styles from './school-selector.module.scss';
import { useClickOutside } from 'hooks/useClickOutside';

export const SelectSchool = ({ schools, handleSchool, currentSchool }) => {
  const [isDropOpen, toggleDrop] = useToggle(false);
  const ref = useClickOutside(() => {
    if (isDropOpen) toggleDrop();
  });

  return (
    <div className={styles.selector} onClick={toggleDrop} ref={ref}>
      <span className={styles.current}>{currentSchool?.name}</span>
      <CaretDownFilled />

      {isDropOpen && (
        <ul className={styles.droplist}>
          {schools?.map((school) => (
            <li key={school.id} onClick={() => handleSchool(school.id)} className={styles.item}>
              {school.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const TabsSelector = ({ schools, handleSchool, currentSchool }) => {
  return (
    <div className={styles.tabs}>
      {schools.map((school) => (
        <div
          key={school.id}
          onClick={() => handleSchool(school.id)}
          className={`${styles.tab} ${school.id === currentSchool?.id && styles.currentTab}`}>
          {school.name}
        </div>
      ))}
    </div>
  );
};
