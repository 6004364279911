import _ from 'lodash';

export default function isEmpty(value) {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export const isAnyChildArrayNotEmpty = (data) => {
  if (_.isArray(data)) {
    return !_.isEmpty(data);
  }
  if (_.isObject(data) && !_.isNull(data)) {
    return _.some(data, (value) => {
      if (_.isArray(value)) {
        return !_.isEmpty(value);
      }
      if (_.isObject(value)) {
        return isAnyChildArrayNotEmpty(value);
      }
      return false;
    });
  }
  return false;
};
