import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeContact, handleContact, handleStudent, resetContactForm } from 'redux/slices/contact-school.slice';
import { ModalMobile } from 'components/pages/parent-portal/track/components/modal-mobile/modal-mobile';
import { usePreventBodyScroll } from 'components/pages/parent-portal/track/hooks';
import { useResponsiveRender } from 'hooks/useResponsiveRender';
import { CONTACT_STAGES, CONTACT_TYPE } from 'redux/slices/types';
import { ListActions, ProfileHelpBanner } from 'components/pages/school-profile/components';
import { ModalDesktop } from 'components/pages/school-profile/components/modal-desktop/modal-desktop';
import { ContactSchool, getContactTitle } from '../../contact-school';
import styles from '../../contact-school.module.scss';

export const ContactSchoolModal = ({
  school,
  source,
  searchHistoryId,
  externalCampaignId,
  autoFillForm,
  metaFbclid,
  googleGclid,
  isMultiple,
  schoolIds,
}) => {
  const dispatch = useDispatch();
  const { isContactOpen, stage, contactType } = useSelector((state) => state.contactSchool);

  useAutoFillContactForm({
    firstName: autoFillForm?.firstName,
    lastName: autoFillForm?.lastName,
    email: autoFillForm?.email,
    zipcode: autoFillForm?.zipcode,
    studentName: autoFillForm?.studentName,
    grade: autoFillForm?.grade,
    phone: autoFillForm?.phone,
  });

  const title = getContactTitle(stage, contactType === CONTACT_TYPE.unclaimed);

  const { renderMobile } = useResponsiveRender({ breakpoint: 767 });

  const withActions = useMemo(
    () => stage === CONTACT_STAGES.initial && contactType !== CONTACT_TYPE.multiple,
    [stage, renderMobile]
  );

  const handleClose = () => {
    dispatch(closeContact());
    dispatch(resetContactForm());
  };

  usePreventBodyScroll(isContactOpen);

  if (renderMobile) {
    return (
      <ModalMobile typeButton="close" onClose={handleClose} withChildContainer={false}>
        <ContactSchool
          includeNavigation
          school={school}
          source={source}
          searchHistoryId={searchHistoryId}
          externalCampaignId={externalCampaignId}
          metaFbclid={metaFbclid}
          googleGclid={googleGclid}
          isModal
          isMultiple={isMultiple}
          schoolIds={schoolIds}
        />
        {withActions && school !== undefined && (
          <div className={styles.other}>
            <hr className="spacing-my-32" />
            <div className={styles.other_list}>
              <ListActions school={school} />
            </div>
            <hr className="spacing-my-32" />
            <ProfileHelpBanner className="spacing-mt-24" claimed={school.claimed} />
          </div>
        )}
      </ModalMobile>
    );
  }

  return (
    <ModalDesktop
      className={`modal-contact-school modal-stage-${stage}`}
      title={title}
      isVisible={isContactOpen}
      onClose={handleClose}>
      <ModalDesktop.BarContainer>
        <ContactSchool.BarProgress />
      </ModalDesktop.BarContainer>
      <ModalDesktop.Body>
        <ContactSchool
          school={school}
          source={source}
          externalCampaignId={externalCampaignId}
          metaFbclid={metaFbclid}
          googleGclid={googleGclid}
          isModal
          isMultiple={isMultiple}
          schoolIds={schoolIds}
        />
      </ModalDesktop.Body>
      <ModalDesktop.Footer>
        <ContactSchool.ActionButtons
          source={source}
          searchHistoryId={searchHistoryId}
          externalCampaignId={externalCampaignId}
          metaFbclid={metaFbclid}
          googleGclid={googleGclid}
          isModal
        />
      </ModalDesktop.Footer>
    </ModalDesktop>
  );
};

export const useAutoFillContactForm = ({ firstName, lastName, email, zipcode, studentName, grade, phone }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleContact({ name: 'firstName', value: firstName || '' }));
    dispatch(handleContact({ name: 'lastName', value: lastName || '' }));
    dispatch(handleContact({ name: 'email', value: email || '' }));
    dispatch(handleContact({ name: 'zipcode', value: zipcode || '' }));
    dispatch(handleContact({ name: 'phone', value: phone || '' }));

    // Student values with default id
    dispatch(handleStudent({ id: '1', name: 'name', value: studentName }));
    dispatch(handleStudent({ id: '1', name: 'grade', value: grade }));
  }, [firstName, lastName, email, zipcode, studentName, grade]);
};
