import Client, { jwtPlugin } from '@synapsestudios/fetch-client';
import localstorage from 'store2';

const client = new Client({ url: '/api', encoding: 'json' });

client.addPlugin(jwtPlugin);
client.setJwtTokenGetter(() => localstorage.get('idToken') || null);

client.on('REQUEST_FAIL', (request, response) => {
  if (response.message.toLowerCase() == 'expired token' && response.statusCode == 401) {
    localstorage.set('accessToken', null);
    localstorage.set('idToken', null);
    // window.location.replace('/login');
  }
});

client.on('REQUEST_ERROR', (request, response) => {
  localstorage.set('accessToken', null);
  localstorage.set('idToken', null);
  window.location.replace('/login');
});

export default client;
