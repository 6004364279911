import React from 'react';
import { useSelector } from 'react-redux';
import { Buttonv2 as Button } from 'schola-components';
import { CONTACT_TYPE, STATUS_LEAD } from 'redux/slices/types';
import styles from './feedback-response.module.scss';
import { t } from 'components/translation/Translation';
import { browserHistory } from 'react-router';
import Lottie from 'lottie-react';
import scholaLottie from './schola-lottie.json';
import checkLottie from './check-lottie.json';
import { useContactSchoolMutation } from 'redux/api/school-api';

export const FeedbackResponse = () => {
  const { statusLead, contactType } = useSelector((state) => state.contactSchool);

  const isUnclaimed = contactType === CONTACT_TYPE.unclaimed;
  const getResponse = isUnclaimed ? CONTACT_TYPE.unclaimed : statusLead;

  const isCreateAccount = statusLead === STATUS_LEAD.success && contactType !== CONTACT_TYPE.unclaimed;

  const responseMessage = {
    success: t('contactSchool:feedback.success'),
    duplicate: t('contactSchool:feedback.duplicate'),
    unclaimed: t('contactSchool:feedback.success'),
  };

  const [_, { isLoading }] = useContactSchoolMutation({
    fixedCacheKey: 'lead-post',
  });

  const handleButton = () => {
    browserHistory.push('/signup');
  };

  return (
    <div className={styles.feedback}>
      <SuccessAnimation isLoading={isLoading} />

      {!isLoading && (
        <div className={styles.content}>
          <h5 className="spacing-mt-24">{t('contactSchool:feedback.success')}</h5>
          <hr className="spacing-my-48" style={{ border: '1px solid #C6CAD1' }} />
          <h5>{t('contactSchool:feedback.appProgress')}</h5>
          <p className="body-small">{t('contactSchool:feedback.createDescription')}</p>
          <Button className="spacing-my-24" color="primary" onClick={handleButton} fullWidth>
            {t('contactSchool:feedback.createAccount')}
          </Button>
        </div>
      )}
    </div>
  );
};

const SuccessAnimation = ({ isLoading }) => {
  const animation = isLoading ? scholaLottie : checkLottie;

  return <Lottie animationData={animation} style={{ height: '150px' }} loop={isLoading} />;
};
