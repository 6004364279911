import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { tString } from 'components/translation/Translation';
import { FeatureList } from 'components/feature-list/feature-list';
import { useDisplayFeatures } from '../../hooks/useDisplayFeatures';
import { TOP_STYLE_CATEGORIES } from '../../constants/top-style-categories';
import { CATEGORIES_SUBTITLES } from '../../constants/categories-subtitles';
import { SchoolCurriculum } from '../../school-curriculum/school-curriculum';
import { AboutBanner } from '..';

import styles from './category-features.module.scss';
import { browserHistory } from 'react-router';

export const CategoryFeature = ({ title, features, description, curriculum, academics }) => {
  const featureListType = (key) => {
    if (TOP_STYLE_CATEGORIES.includes(key)) {
      return 'TOP';
    }
    if (key === CATEGORIES_SUBTITLES.programs) {
      return 'PROGRAM';
    }

    return 'CHECK';
  };

  const sectionStyle = {
    [styles.categoryContainer]: !academics && title !== tString('schoolProfile:specialNeeds.title'),
    [styles.academicsContainer]: academics,
    [styles.specialNeedsContainer]: title === tString('schoolProfile:specialNeeds.title'),
  };

  const listContainerStyle = {
    [styles.academicWrapper]: academics,
  };

  const featuresContainerStyle = {
    [styles.featuresContainer]: title !== tString('schoolProfile:specialNeeds.title'),
    [styles.specialNeedsFeaturesContainer]: title === tString('schoolProfile:specialNeeds.title'),
    [styles.activitiesContainer]: title === tString('schoolProfile:extracurricularActivities.title'),
  };

  const { pathname } = browserHistory.getCurrentLocation();

  return (
    <section className={classNames(sectionStyle)} data-testid="category-feature">
      <h3 className={styles.categoryTitle}>{title}</h3>
      {description && (
        <p className={styles.categoryDescription} data-testid="category-description">
          {description}
        </p>
      )}
      <div className={classNames(listContainerStyle)}>
        <div className={classNames(featuresContainerStyle)}>
          {Object.entries(features).map(([key, value]) => {
            const displayFeatures = useDisplayFeatures(value);
            if (key === CATEGORIES_SUBTITLES.schoolCurriculum) {
              if (displayFeatures) {
                return <SchoolCurriculum key={key} features={value} subTitle={key} curriculum={curriculum} />;
              }
              return null;
            }
            return (
              displayFeatures && <FeatureList key={key} features={value} subTitle={key} type={featureListType(key)} />
            );
          })}
        </div>
        {academics && pathname !== '/schola-match' && (
          <div className={styles.aboutContainer}>
            <AboutBanner
              shape="square"
              title={tString('schoolProfile:academics.about.title')}
              text={tString('schoolProfile:academics.about.text')}
            />
          </div>
        )}
      </div>
    </section>
  );
};

CategoryFeature.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  features: PropTypes.object.isRequired,
};
