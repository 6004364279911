import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const scholaApi = createApi({
  reducerPath: 'scholaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = getState().auth.token;
      const otpToken = localStorage.getItem('otpToken');

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      if (endpoint === 'getParticipant') {
        headers.set('authorization', `Bearer ${otpToken}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createOTP: builder.mutation({
      query: ({ method, leadId, participantId }) => ({
        url: 'otp/createOtp',
        method: 'post',
        body: { method, lead_id: leadId, participant_id: participantId },
      }),
    }),
    validateOTP: builder.mutation({
      query: ({ codeId, code }) => ({
        url: 'otp/verifyOtp',
        method: 'post',
        body: { codeId, code },
      }),
    }),
    getContactMethods: builder.query({
      query: (leadId) => `otp/contact-methods/${leadId}`,
    }),
    getParticipant: builder.query({
      query: (leadId) => `lottery/participant-status/${leadId}`,
    }),
    getSearchSchools: builder.query({
      query: (payload) =>
        `schools/searchv2?address=${payload.address}&lat=${payload.lat}&lon=${payload.lon}&page=${payload.page}&pageSize=${payload.pageSize}&radius=${payload.radius}&state=${payload.state}&zip=${payload.zip}`,
    }),
  }),
});

export const {
  useCreateOTPMutation,
  useValidateOTPMutation,
  useGetParticipantQuery,
  useGetContactMethodsQuery,
  useGetSearchSchoolsQuery,
} = scholaApi;
