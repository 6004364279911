import React from 'react';
import PropTypes from 'prop-types';
import { tString } from 'components/translation/Translation';
import { Location16 } from '@carbon/icons-react';
import { MapView } from '..';

import styles from './school-map.module.scss';

export const SchoolMap = ({ school, padding }) => {
  if (school.type === 'virtual/online') return null;
  return (
    <div className={styles.profileMapSection} id="location" style={padding}>
      <h3 className={styles.title}>{tString('schoolProfile:location')}</h3>
      <div className={styles.addressContainer}>
        <Location16 />
        <p className={styles.address}>{school.address}</p>
      </div>

      <div className={styles.profileMap}>
        <MapView school={school} />
      </div>
    </div>
  );
};

SchoolMap.propTypes = {
  school: PropTypes.object.isRequired,
  padding: PropTypes.object,
};
