import React from 'react';
import styles from './feature-list-item.module.scss';

export const FeatureListItem = ({ children }) => {
  return (
    <div role="region" aria-labelledby="feature-list-title" className={styles.list_item}>
      {children}
    </div>
  );
};
