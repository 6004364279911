import { combineReducers } from 'redux';
import Immutable from 'immutable';

const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: Immutable.Map(),
  error: false,
  validationErrors: Immutable.Map(),
  locationData: Immutable.Map(),
});

const location = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_LOCATION_SUCCESS':
      let location = {};
      if (action.json.results[0]) {
        action.json.results[0].address_components.map((data) => {
          if (data.types[0] === 'route') {
            location.itemRoute = data.long_name;
          }
          if (data.types[0] === 'locality') {
            location.itemLocality = data.long_name;
          }
          if (data.types[0] === 'administrative_area_level_1') {
            location.itemState = data.short_name;
          }
          if (data.types[0] === 'country') {
            location.itemCountry = data.long_name;
            location.countryCode = data.short_name;
          }
          if (data.types[0] === 'postal_code_prefix') {
            location.itemPc = data.long_name;
          }
          if (data.types[0] === 'street_number') {
            location.itemSnumber = data.long_name;
          }
          return location;
        });
      }
      return location;
    default:
      return state;
  }
};

const geoFieldLocation = (state = defaultState, action) => {
  let location = {};
  switch (action.type) {
    case 'SET_GEO_LOCATION':
      location = action.payload;
      return location;
    default:
      return state;
  }
};

export default combineReducers({
  location,
  geoFieldLocation,
});
