import Client, { jwtPlugin } from '@synapsestudios/fetch-client';
import localstorage from 'store2';

const client = new Client({ url: '/api' });

client.addPlugin(jwtPlugin);
client.setJwtTokenGetter(() => localstorage.get('accessToken') || null);

client.on('REQUEST_FAILURE', async (request, response) => {
  const res = await response.json();
  if (res.statusCode == 401) {
    localstorage.set('accessToken', null);
    localstorage.set('idToken', null);
    // window.location.replace('/login');
  }
});

export default client;
