import React, { useMemo, useEffect, useState } from 'react';
import { LeafletMap } from 'components/leaflet-map/leaflet-map';
import { LocationCompanyFilled24 } from '@carbon/icons-react';
import { gradesLabel, handleRouteSchool } from 'components/card-school/utils';
import styles from './map-matches.module.scss';
import { ButtonRetool } from 'components/pages/schola-match-results/components';
import { useMap } from 'react-leaflet';
import { handleChangeLocation } from '../../utils/school-search-params';

const MapUpdater = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      map.setView(center, zoom, {
        animate: true,
        duration: 1,
      });
    }
  }, [map, center, zoom]);

  return null;
};

export const MapMatches = ({ lat, lon, schools, sponsored }) => {
  const center = useMemo(() => [lat, lon], [lat, lon]);
  const zoom = 13;

  const [newCenter, setNewCenter] = useState({});

  const handleCenterChange = (newCenter) => {
    const { lat, lng } = newCenter;
    setNewCenter({ lat, lng });
  };

  const searchArea = () => {
    handleChangeLocation(newCenter);
  };

  return (
    <div className={styles.mapContainer}>
      <button onClick={searchArea} className={styles.searchButton}>
        Search in this area
      </button>
      <LeafletMap center={center} height="750px" zoom={zoom} onCenterChange={handleCenterChange}>
        <MapUpdater center={center} zoom={zoom} />
        {schools?.length > 0 && schools.map((school) => <SchoolMarker key={school.id} school={school} />)}
        {sponsored?.length > 0 &&
          sponsored.map((school) => <SchoolMarker key={`sponsor-${school.id}`} school={school} />)}
      </LeafletMap>
    </div>
  );
};

const SchoolMarker = ({ school }) => {
  const { name, type, address, grades, city, latitude, longitude } = school;
  return (
    <LeafletMap.Marker position={[latitude, longitude]} Icon={LocationCompanyFilled24}>
      <div className={styles.marker}>
        <h6 className={styles.title}>{name}</h6>
        <div className={styles.text}>
          <p>
            {type} | {gradesLabel(grades)}
          </p>
          <p>{`${address}, ${city}`}</p>
        </div>
        <ButtonRetool onClick={() => handleRouteSchool(school)}>View Profile</ButtonRetool>
      </div>
    </LeafletMap.Marker>
  );
};
