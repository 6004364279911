import {
  careerPaths,
  edApproaches,
  personalityMapping,
  skillsDefinitions,
  learningApproachByPersonality,
  learningMethods,
  learningModels,
  classSizesDescriptions,
} from './personality-mapping';

export const getPersonalityData = (personality) => {
  return personalityMapping[personality];
};

export const getSkills = (personality, studentName) => {
  const personalityData = getPersonalityData(personality);

  return skillsDefinitions(studentName).filter((skill) => personalityData.skillsHighlight.includes(skill.name));
};

export const getCareerPaths = (personality) => {
  const personalityData = getPersonalityData(personality);

  return careerPaths.filter((career) => personalityData.careers.includes(career.name));
};

export const getLearnignApproach = (personalityId) => {
  const personality = learningApproachByPersonality[personalityId];
  const educationalApproach = [
    {
      keyName: 'Top Educational Approach',
      name: personality.EducationalApproach,
      description: edApproaches[personality.EducationalApproach],
    },
    {
      keyName: 'Top Learning Model',
      name: personality.LearningModel,
      description: learningModels[personality.LearningModel],
    },
    {
      keyName: 'Top Learning Method',
      name: personality.LearningMethod,
      description: learningMethods[personality.LearningMethod],
    },
    {
      keyName: 'Ideal Class Size',
      name: personality.ClassSize,
      description: classSizesDescriptions[personality.ClassSize],
    },
  ];

  const alternatives = [
    { key: 'Additional Educational Approaches', value: personality['Additional Educational Approaches'] },
    { key: 'Additional Learning Models', value: personality['Additional Learning Models'] },
    { key: 'Additional Learning Methods', value: personality['Additional Learning Methods'] },
  ];

  return { educationalApproach, alternatives };
};
