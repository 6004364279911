import React, { useState } from 'react';
import { tString } from 'components/translation/Translation';
import { Buttonv2 as Button } from 'schola-components';
import { STATUS_ICON } from '../constants/status-icon';
import { FeatureModal } from '../components/feature-modal/feature-modal';
import { useDisplayFeatures } from '../hooks/useDisplayFeatures';

import styles from './school-curriculum.module.scss';

export const SchoolCurriculum = ({ features, subTitle, curriculum }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCurriculumModal = () => {
    setIsModalVisible(true);
  };

  const handleOnClose = () => {
    setIsModalVisible(false);
  };
  return (
    <div className={styles.schoolCurriculumContainer} data-testid="school-curriculum-test">
      {subTitle && (
        <div className={styles.subtitle}>
          <span>{STATUS_ICON[subTitle]}</span>
          <h4 className={`body-large ${styles.subtitleText}`}>{tString(subTitle)}</h4>
        </div>
      )}
      <div className={styles.curriculumContainer}>
        <div className={styles.buttonsContainer}>
          <Button color="secondary" className={styles.seeSchoolButton} onClick={handleCurriculumModal}>
            {tString('schoolProfile:academics.schoolCurriculum.seeSchoolClasses')}
          </Button>
          {curriculum && (
            <Button className={styles.curriculumButton}>
              {tString('schoolProfile:academics.schoolCurriculum.curriculum')}
            </Button>
          )}
        </div>
      </div>
      {useDisplayFeatures(features) && (
        <FeatureModal
          isVisible={isModalVisible}
          features={features}
          type="MODAL"
          onClose={handleOnClose}
          title="Curriculum"
        />
      )}
    </div>
  );
};
