export const CONTACT_STAGES = {
  initial: 'initial',
  students: 'students',
  contact: 'contact',
  additionalMatch: 'additionalMatch',
  response: 'response',
};

export const CONTACT_TYPE = {
  standard: 'standard',
  publicDistrict: 'publicDistrict',
  recruiterPro: 'recruiterPro',
  multiple: 'multiple',
  unclaimed: 'unclaimed',
};
export const STATUS_LEAD = {
  success: 'success',
  duplicate: 'duplicate',
  error: 'error',
};
