import { ButtonRetool, MatchLabel, ProfileImage } from '..';
import styles from './school-header-tab.module.scss';
import { CheckCircleFilled } from '@ant-design/icons';
import { browserHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';

export const SchoolHeaderTab = ({ school, handleContact, isScholaPro, className }) => {
  const { pathname } = browserHistory.getCurrentLocation();
  return (
    <div className={`${styles.schoolHeader} ${className}`}>
      <figure className={styles.image}>
        <ProfileImage photo={school.photo || school?.profile_image} name={school.name} />
      </figure>

      <MatchLabel className={styles.match} matchLevel={isScholaPro ? 'partner' : school.matchLevel} />
      <div className={styles.content}>
        <h3 className={styles.name}>{school.name}</h3>
        {school.claimed && (
          <>
            <CheckCircleFilled
              data-tip
              data-for="tooltip-claimed"
              className={styles.content_check}
              style={{ color: 'rgba(0, 107, 143, 1)' }}
            />
            <ReactTooltip id="tooltip-claimed" effect="solid" place="right" type="dark">
              <p className={styles.tooltip}>Verified School</p>
            </ReactTooltip>
          </>
        )}
      </div>
      <ButtonRetool onClick={handleContact} color={isScholaPro && 'pro'}>
        {pathname === '/schola-match-b' ? 'Request Info ' : 'Start Application'}
      </ButtonRetool>
    </div>
  );
};
