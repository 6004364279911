import { getProfile } from 'util/auth';

export const leadAdapter = ({ students, contact, language, schoolId }) => {
  const studentsTransform = students.map((student) => ({
    child_first_name: student.name.split(' ')[0],
    child_last_name: student.name.split(' ')[1] ?? ' ',
    grade: student.grade,
  }));

  const payload = {
    created_by: getProfile()?.sub || '',
    parent_first_name: contact.firstName,
    parent_last_name: contact.lastName,
    phone: contact.phone,
    email: contact.email,
    zipcode: contact.zipcode || '',
    school_id: schoolId,
    lead_source_id: '-10',
    preferred_contact: 'either',
    parent_relationship: 'guardian',
    language,
    students: studentsTransform,
  };

  return payload;
};
