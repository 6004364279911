/* eslint-disable */ 
import { combineReducers } from 'redux';
import getApiReducer from 'redux/util/get-api-reducer';

import {
    GET_TEACHERS,
    ADD_TEACHER,
    DELETE_TEACHER,
    GET_TEACHER,
    UPDATE_TEACHERS,
} from './teacher-actions';

const reducers = combineReducers({
    getTeachers: getApiReducer(GET_TEACHERS),
    getTeacher: getApiReducer(GET_TEACHER),
    addTeacher: getApiReducer(ADD_TEACHER),
    deleteTeacher: getApiReducer(DELETE_TEACHER),
    updateTeacher: getApiReducer(UPDATE_TEACHERS),
    
});

export default reducers;
