import React, { useState } from 'react';
import { Close20, ChevronDown16, RadioButton16, Education16, ChevronRight16 } from '@carbon/icons-react';
import { useDispatch } from 'react-redux';
import { handleStudent, removeStudent } from 'redux/slices/contact-school.slice';
import styles from './students-stage.module.scss';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Dropdown, InputText } from 'schola-components';
import { useToggle } from 'hooks/useToggle';
import { t } from 'components/translation/Translation';

export const StudentInputs = ({ number, student, grades, showHeader, savedStudents, isUserLogin, ...props }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch(handleStudent({ id: student.id, value, name }));
  };

  const handleSelectClick = ({ name, value }) => {
    dispatch(handleStudent({ id: student.id, value, name }));
    toggleDrop();
  };

  const [isNewStudent, setIsNewStudent] = useState(false);

  const [isOpenDrop, toggleDrop] = useToggle(false);

  return (
    <div className="spacing-mt-24" {...props}>
      {showHeader && (
        <div className={styles.studentHeader}>
          <p className="body-small">
            {t('contactSchool:students.studentLabel')} {number}
          </p>

          <button className="" onClick={() => dispatch(removeStudent(student.id))} data-testid="removeButton">
            <Close20 />
          </button>
        </div>
      )}

      {isUserLogin && savedStudents?.length > 0 && !isNewStudent ? (
        <div className={styles.selectBox}>
          <div
            className={styles.placeSelection}
            onClick={() => toggleDrop()}
            role="button"
            data-testid="student-selection">
            {student.name && (
              <>
                <UserAvatar user={student.name} size="small" />
                <span className={styles.placeSelection_name}>{student.name}</span>
              </>
            )}
            <ChevronDown16 className={styles.placeSelection_arrow} />
          </div>

          {isOpenDrop && (
            <div className={styles.dropContainer} role="listbox">
              <div className={styles.dropContainer_scroll}>
                {savedStudents.map((item, idx) => (
                  <button
                    key={`saved_studetn_${idx}`}
                    data-testid="student-option"
                    className={styles.dropContainer_button}
                    value={item.name}
                    id="name"
                    name="name"
                    onClick={() => handleSelectClick({ name: 'name', value: item.name })}>
                    <div
                      className={styles.dropContainer_name}
                      onClick={() => handleSelectClick({ name: 'name', value: item.name })}>
                      <UserAvatar user={item.name} />
                      {item.name}
                    </div>
                    <RadioButton16 onClick={() => handleSelectClick({ name: 'name', value: item.name })} />
                  </button>
                ))}
              </div>

              <button onClick={() => setIsNewStudent(true)} className={styles.addStudent}>
                <div className={styles.addStudent_name}>
                  <Education16 />
                  <span>{t('contactSchool:students.newStudent')}</span>
                </div>
                <ChevronRight16 />
              </button>
            </div>
          )}
        </div>
      ) : (
        <InputText
          className={styles.inputText}
          label={t('contactSchool:students.name')}
          id="name"
          name="name"
          value={student.name}
          onChange={handleChange}
          error={props.iserror && !student.name}
          errorMessage="This field is required"
        />
      )}

      <Dropdown
        aria-label="grade"
        label={t('contactSchool:students.grade')}
        options={grades}
        id="grade"
        name="grade"
        value={student.grade}
        onChange={handleChange}
      />
      {props.iserror && !student.grade && (
        <span className={`spacing-mb-32 ${styles.errorLabel}`}>This field is required</span>
      )}
    </div>
  );
};
