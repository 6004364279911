import React from 'react';
import { CheckmarkFilled32 } from '@carbon/icons-react';
import { t } from 'components/translation/Translation';

const ThanksForYourReview = ({ schoolName }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CheckmarkFilled32 fill="#0099CC" width={56} height={56} />
      <h1 className="spacing-mt-24 spacing-mb-24">{t('schoolProfile:reviews.thanks')} </h1>,
      <p className="body-large spacing-mb-16">
        {/* You’ve Helped Other Families Find Out If {schoolName} Is the Best School for Them. */}
        {t('schoolProfile:reviews.helpedOtherFamilies', { schoolName })}
      </p>
    </div>
  );
};

export default ThanksForYourReview;
