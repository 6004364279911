import { combineReducers } from 'redux';
import getApiReducer, { defaultState } from 'redux/util/get-api-reducer';
import Immutable from 'immutable';
import {
  GET_AUTOCOMPLETE_SCHOOLS,
  GET_ENDORSEMENTS,
  GET_SCHOOL,
  GET_SCHOOL_APPLICATION,
  GET_USER_APPLICATIONS,
  SEARCH_SCHOOLS,
  SUBMIT_APPLICATION,
  UPDATE_ENDORSEMENTS,
  SUBMIT_REVIEW,
  GET_SCHOOL_FEATURES,
  GET_SCHOOL_REVIEWS,
  SUBMIT_SCHOOL_REVIEWS,
  GET_SCHOOL_ACADEMICS,
  GET_SCHOOL_SEATS,
  GET_SCHOOL_SEATS_GRADE,
  GET_SCHOOL_TESTIMONIAL,
  GET_SCHOOL_SITES_REVIEWS,
  ADD_SCHOOL_SCHEDULE_TOUR,
  GET_SCHOOL_SCHEDULE_TOUR,
  GET_SCHOOL_SCHEDULE_TOUR_ID,
  GET_SCHOOL_TOUR_TIMES,
  UPDATE_CONNECT_REQUEST,
  SEARCH_SCHOOLS_LOADING,
  UPDATE_SCHOOL_STATUS_DATE,
  SEND_EMAIL_SCHOOL_STATUS_DATE,
} from './school-actions';

const changeEndorsementCount = (state, action, amount) => {
  const path = ['data', 'endorsements'];
  let newState = state;
  const matchingEndorsementEntry = state
    .getIn(path)
    .findEntry((val) => val.get('feature_id') === parseInt(action.featureId, 10));

  if (amount > 0 && !matchingEndorsementEntry) {
    // If brand new endorsement, we need to add it
    newState = newState.setIn(
      path,
      state.getIn(path).push(
        Immutable.fromJS({
          feature_id: parseInt(action.featureId, 10),
          name: action.json.feature.name,
          count: 1,
        })
      )
    );
  } else if (amount < 0 && matchingEndorsementEntry[1].get('count') === 1) {
    // If count dropped to zero, remove it
    newState = newState.setIn(path, newState.getIn(path).remove(matchingEndorsementEntry[0]));
  } else {
    // Otherwise, increment or decrement
    newState = newState.setIn(
      path,
      newState.getIn(path).map((endorsement) => {
        if (endorsement.get('feature_id') === parseInt(action.featureId, 10)) {
          return endorsement.set('count', endorsement.get('count') + amount);
        }
        return endorsement;
      })
    );
  }
  return newState.setIn(
    path,
    newState.getIn(path).sort((a, b) => b.get('count') - a.get('count'))
  );
};
const incrementEndorsementCount = (state, action) => {
  return changeEndorsementCount(state, action, 1);
};
const decrementEndorsementCount = (state, action) => {
  return changeEndorsementCount(state, action, -1);
};

const getSchool = (state = defaultState, action) => {
  state = getApiReducer(GET_SCHOOL)(state, action);

  switch (action.type) {
    case 'ENDORSE_SUCCESS':
    case 'UNENDORSE_FAILURE':
      if (state.getIn(['data', 'id']) === parseInt(action.schoolId, 10)) {
        return incrementEndorsementCount(state, action);
      }
      return state;
    case 'UNENDORSE_SUCCESS':
    case 'ENDORSE_FAILURE':
      if (state.getIn(['data', 'id']) === parseInt(action.schoolId, 10)) {
        return decrementEndorsementCount(state, action);
      }
      return state;
    default:
      return state;
  }
};

const searchReducer = (state = defaultState, action) => {
  state = getApiReducer(SEARCH_SCHOOLS)(state, action);

  if (action.type === 'SEARCH_SCHOOLS_SUCCESS' || action.type === 'SEARCH_SCHOOLS_LOADING') {
    state = state.set('query', action.query);
  }

  return state;
};

const reducers = combineReducers({
  endorsements: getApiReducer(GET_ENDORSEMENTS),
  getAutocompleteSchools: getApiReducer(GET_AUTOCOMPLETE_SCHOOLS),
  getSchool,
  getSchoolApplication: getApiReducer(GET_SCHOOL_APPLICATION),
  getUserApplications: getApiReducer(GET_USER_APPLICATIONS),
  search: searchReducer,
  submitApplication: getApiReducer(SUBMIT_APPLICATION),
  updateEndorsements: getApiReducer(UPDATE_ENDORSEMENTS),
  submitReview: getApiReducer(SUBMIT_REVIEW),
  getSchoolFeatures: getApiReducer(GET_SCHOOL_FEATURES),
  getSchoolReviews: getApiReducer(GET_SCHOOL_REVIEWS),
  submitSchoolReviews: getApiReducer(SUBMIT_SCHOOL_REVIEWS),
  getSchoolAcademics: getApiReducer(GET_SCHOOL_ACADEMICS),
  getSchoolSeats: getApiReducer(GET_SCHOOL_SEATS),
  getSchoolSeatsGrade: getApiReducer(GET_SCHOOL_SEATS_GRADE),
  getSchoolTestimonials: getApiReducer(GET_SCHOOL_TESTIMONIAL),
  getSchoolSitesReviews: getApiReducer(GET_SCHOOL_SITES_REVIEWS),
  addSchoolScheduleTour: getApiReducer(ADD_SCHOOL_SCHEDULE_TOUR),
  getSchoolScheduleTour: getApiReducer(GET_SCHOOL_SCHEDULE_TOUR),
  getSchoolScheduleTourById: getApiReducer(GET_SCHOOL_SCHEDULE_TOUR_ID),
  getSchoolTourTimes: getApiReducer(GET_SCHOOL_TOUR_TIMES),
  responseConnectRequest: getApiReducer(UPDATE_CONNECT_REQUEST),
  updateSchoolStatusDate: getApiReducer(UPDATE_SCHOOL_STATUS_DATE),
  sendReviewBusinessStatus: getApiReducer(SEND_EMAIL_SCHOOL_STATUS_DATE),
});

export default reducers;
