import React, { useEffect, useState, useRef } from 'react';
import { Checkmark24, Close24, Warning24 } from '@carbon/icons-react';
import './notification.scss';
import classNames from 'classnames';

const AlertNotification = ({ state, setState, message, status, seconds, zIndex }) => {
  const alert = useRef(null);

  const alertClasses = {
    alertReferral_success: status === 'success',
    alertReferral_error: status === 'error',
    alertReferral_warning: status === 'warning',
  };

  const timeInSeconds = seconds * 1000;

  useEffect(() => {
    if (state) {
      const timer = setTimeout(() => {
        alert.current?.classList.add('alertReferral_remove');

        const setFalsyTimer = setTimeout(() => {
          setState({
            ...state,
            isActive: false,
          });
        }, 1000);
        return () => clearTimeout(setFalsyTimer);
      }, timeInSeconds);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [state]);

  return (
    <div className={classNames('alertReferral', alertClasses)} ref={alert} style={{ zIndex }}>
      {status === 'success' && <Checkmark24 />}
      {status === 'error' && <Close24 />}
      {status === 'warning' && <Warning24 />}

      <span>{message}</span>
    </div>
  );
};

export default AlertNotification;
