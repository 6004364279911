import React, { useMemo } from 'react';
import { isRecruiterPro } from 'util/is-recruiter-pro';
import SchoolActionItem from './school-action-item';
import { tString } from 'components/translation/Translation';
import { handleApplyNow } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTourModal } from 'redux/slices/school-profile.slice';
import { useToggle } from 'hooks/useToggle';
import { PhoneModal } from './phone-modal';

export const ListActions = ({ school }) => {
  const {
    plan_id: planId,
    subscription_end: subscriptionEnd,
    subscription_months: subscriptionMonths,
    website,
    phone,
    open_enrollment: openEnrollment,
    name,
    address,
    claimed,
  } = school;

  const dispatch = useDispatch();

  const { availableTours } = useSelector((state) => state.profile);
  const handleTour = () => dispatch(toggleTourModal());

  const isScholaRecruiterPro = isRecruiterPro({
    plan_id: planId,
    subscription_end: subscriptionEnd,
    subscription_months: subscriptionMonths,
  });

  const _website = !website?.includes('https://') && !website?.includes('http://') ? `http://${website}` : website;

  const SPOTphone = '8336202223';
  const phoneTo = isScholaRecruiterPro ? `tel:${SPOTphone}` : `tel:${phone}`;
  const phoneString = isScholaRecruiterPro ? SPOTphone : phone;

  const [isPhoneShowing, togglePhoneShowing] = useToggle(false);

  const baseUrl = 'https://www.google.com/search?q=';

  const _url = `${name} ${address}`.replace(/\s+/g, '%20');
  const googleUrl = `${baseUrl}${_url}`;

  const fields = [availableTours, openEnrollment, phone, isScholaRecruiterPro, website, googleUrl];
  const renderCondition = fields.some((item) => item == true || item?.length > 1);

  if (!renderCondition) return null;

  const isMobile = useMemo(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), []);

  return (
    <ul data-testid="actionsList">
      {availableTours && (
        <SchoolActionItem data={tString('parent:form.schedule_tour')} icon="tour" onClick={handleTour} />
      )}

      {/* {openEnrollment && (
        <SchoolActionItem
          data={tString('parent:boards.apply_now')}
          icon="apply"
          onClick={() => handleApplyNow(school)}
        />
      )} */}

      {/* {(phone || isScholaRecruiterPro) && (
        <SchoolActionItem
          data={tString('parent:track.callTheSchool')}
          icon="phone"
          href={isMobile && phoneTo}
          onClick={!isMobile && togglePhoneShowing}
        />
      )} */}

      {/* {isPhoneShowing && (
        <PhoneModal
          name={name}
          isPhoneShowing={isPhoneShowing}
          phone={phoneString}
          togglePhoneShowing={togglePhoneShowing}
        />
      )} */}

      {/* {website && (
        <SchoolActionItem data={tString('parent:track.visitSchoolWebsite')} icon="web" href={_website} externalLink />
      )}

      {!claimed && (
        <SchoolActionItem data={tString('parent:track.searchInfoGoogle')} icon="search" href={googleUrl} externalLink />
      )} */}
    </ul>
  );
};
