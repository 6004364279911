import React from 'react';
import styles from './school-closed.module.scss';
import { NearbySchoolsCaroussel } from '..';

export const SchoolClosed = ({ school, renderMobile }) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.closed}>
        <div className={styles.content}>
          <img className={styles.image} src="https://static.schola.com/closed_school.png" alt="School closed" />
          <div>
            <h1>Unfortunately, This School Is Closed</h1>
            <p className="body-large spacing-mt-16">
              This school has closed its doors, but don't worry, there are more educational options to explore.
            </p>
          </div>
        </div>
      </div>
      <NearbySchoolsCaroussel school={school} renderMobile={renderMobile} />
    </section>
  );
};
