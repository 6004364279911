import {
  Accessibility20,
  Education20,
  Badge20,
  Book20,
  Certificate20,
  AccessibilityAlt20,
  ColorPalette20,
  Basketball20,
  Chemistry24,
  Education24,
  BuildingInsights_320 as BuildingInsights320,
  HelpDesk20,
} from '@carbon/icons-react';
import { CATEGORIES_SUBTITLES } from './categories-subtitles';

export const STATUS_ICON = {
  [CATEGORIES_SUBTITLES.sports]: <Basketball20 />,
  [CATEGORIES_SUBTITLES.clubs]: <ColorPalette20 />,
  [CATEGORIES_SUBTITLES.facilities]: <BuildingInsights320 />,
  [CATEGORIES_SUBTITLES.staff]: <HelpDesk20 />,
  [CATEGORIES_SUBTITLES.programs]: <Accessibility20 />,
  [CATEGORIES_SUBTITLES.specialNeedsSupported]: <AccessibilityAlt20 />,
  [CATEGORIES_SUBTITLES.specificEducationalApproach]: <Education20 />,
  [CATEGORIES_SUBTITLES.coreKnowledge]: <Certificate20 />,
  [CATEGORIES_SUBTITLES.highSchoolPrograms]: <Badge20 />,
  [CATEGORIES_SUBTITLES.schoolCurriculum]: <Book20 />,
  [CATEGORIES_SUBTITLES.schoolClasses]: <Education24 />,
  [CATEGORIES_SUBTITLES.highSchoolSpecificClasses]: <Chemistry24 />,
};
