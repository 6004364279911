import React, { Suspense } from 'react';
import './animationLoading.scss';

const LoadingSvgComponent = React.lazy(() => import('./SVGLoader/SVGLoader'));

const AnimationLoadingWidthoutParams = () => {
  return (
    <div className="loadingWrapper">
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <LoadingSvgComponent />
        </Suspense>
      </div>
    </div>
  );
};

export default AnimationLoadingWidthoutParams;
