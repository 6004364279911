import React, { useState, useEffect, useMemo } from 'react';
import { _getSchoolReviews } from 'controllers/school-profile/reviews_controller';
import { _addSchoolToBoard } from 'controllers/boards/boards-controllers';
import {
  ChevronDown24,
  ChevronUp24,
  StarFilled16,
  ConnectionReceive16,
  Location16,
  Time16,
  CheckmarkFilled16,
} from '@carbon/icons-react';
import schoolUrl from 'util/schoolUrl';
import { Link } from 'react-router';
import { t } from 'components/translation/Translation';
import getSchoolTypeTranslation from 'util/getSchoolTypeTranslation';
import useWidthScreen from 'hooks/useWidthScreen';
import { getBadge } from 'util/getBadge';
import './card-school.scss';

const CardSchool = ({
  school,
  children,
  bestMatch,
  ranking,
  getFeatureName,
  isFromScholaMatch,
  showMatchPercent,
  handleMouseEnter,
  handleMouseLeave,
  userName,
  className,
  classNameImage,
}) => {
  const badge = useMemo(() => getBadge(school), []);
  const {
    name: schoolName,
    address,
    city,
    state,
    distance,
    type,
    claimed,
    profile_image: profileImage,
    review,
    matchpercent,
    featuresmatched,
    open_enrollment: openEnrollment,
    offer_transportation: offerTransportation,
    transportation_offered: transportationOffered,
    headerImage,
  } = school;

  const sUrl = schoolUrl.build(school).replace('?', '');
  const requestInfoUrl = `${sUrl}?requestInfoResult=true`;
  const _review = review || { length: 0, average: 0 };

  const [grades, setGrades] = useState([]);
  const [showMoreFeatures, setShowMoreFeatures] = useState(false);
  const width = useWidthScreen();

  const toggleFeaturesDisplay = () => {
    setShowMoreFeatures(!showMoreFeatures);
  };

  const getLimit = (param) => {
    let minToDisplay = width > 472 ? 4 : 3;
    const limit = param ? undefined : minToDisplay;
    return limit;
  };

  const numberOfFeaturesDisplayed = getLimit(showMoreFeatures);

  const intGrade = (grade) => {
    let ordinal = 0;
    switch (grade) {
      case 'Pre-K':
        ordinal = -3;
        break;
      case 'TK':
        ordinal = -2;
        break;
      case 'HS':
        ordinal = -1;
        break;
      case 'K':
        ordinal = 0;
        break;
      default:
        ordinal = parseInt(grade, 10);
        break;
    }
    return ordinal;
  };

  const getGrades = (school) => {
    if (school.grades && school.grades !== undefined && school.grades !== null) {
      let grades;
      let rangeBegin = '';
      let rangeEnd = '';
      let ranges = [];
      let lastIndex;
      const _grades = typeof school.grades === 'string' ? school.grades.split(',') : school.grades;
      _grades.forEach((grade) => {
        grade = grade === 'HS' ? 'Head Start' : grade;
        if (rangeBegin === '') {
          rangeBegin = grade;
        } else if (lastIndex + 1 !== intGrade(grade)) {
          const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
          ranges.push(_range);
          rangeBegin = grade;
          rangeEnd = grade;
        }
        rangeEnd = grade;
        lastIndex = intGrade(grade);
      });
      const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
      ranges.push(_range);
      grades = ranges.join(',');
      setGrades(grades);
    } else if (school.min_grade && school.max_grade) {
      const gradesDisplay =
        school.min_grade === school.max_grade ? school.min_grade : `${school.min_grade} - ${school.max_grade}`;
      setGrades(gradesDisplay);
    }
  };

  useEffect(() => {
    getGrades(school);
  }, [school]);

  const dummyMatches = ['Soccer', 'Dance', 'Football', 'Field Hokey', 'Cross Country', 'Gymnastics'];
  const bannerProfile = profileImage || headerImage || 'https://static.schola.com/banners/Banner1-480px-01.webp';
  let largeUrl = bannerProfile;
  if (!bannerProfile.startsWith('https://static')) {
    const filenameArr = bannerProfile.split('/');
    const largeName = `/s-${filenameArr.pop()}`;
    largeUrl = filenameArr.join('/') + largeName;
  }
  return (
    <div
      className={`${bestMatch && 'bestmatch'} school-card ${className}`}
      id={`school_${school.id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ minHeight: isFromScholaMatch ? '12.5rem' : '9.25rem' }}>
      <Link to={sUrl} aria-label={`Read more about ${school.name}`}>
        <div className={`school-image ${classNameImage}`}>
          {
            // Badge Plan
            badge && (
              <span className="school-info-badgeContainer">
                <img src={badge} alt="badge" loading="lazy" />
              </span>
            )
          }
          <img
            width={width < 768 ? 763 : 302}
            height={width < 768 ? 335 : 133}
            className="school-image"
            src={bannerProfile}
            alt={`school-${school.id}`}
            loading="lazy"
          />
        </div>
      </Link>

      <div className="school-info spacing-pt-16 spacing-pl-16">
        <Link to={sUrl} className="school-card-text">
          {bestMatch && (
            <p className="school-info-bestMatch spacing-mb-4">
              #{ranking} {t('results:cardMatchTitle')}
              {userName}
            </p>
          )}

          {!bestMatch && school?.sponsored && (
            <p className="school-info-bestMatch spacing-mb-4"># {t('results:sponsored')}</p>
          )}
          <div className="school-info-title">
            <h3>{schoolName}</h3>

            {claimed && <CheckmarkFilled16 fill="#0099CC" />}
          </div>

          <div className="school-info-address spacing-mt-8">
            <p style={{ color: '#5f6a7d' }}>
              {address}, {city}, {state}{' '}
              {distance && (
                <span>
                  | <Location16 /> {distance?.toFixed(1)} Miles
                </span>
              )}
            </p>
          </div>

          <div className="school-info-type spacing-mt-16">
            {_review.length > 0 && (
              <div className="school-info-reviews spacing-mr-8">
                <StarFilled16 fill={_review.length > 0 ? '#F0AD1B' : '#C7CCD4'} />
                <span>
                  {' '}
                  {_review.average} ({_review.length} {t('results:reviews')}
                  {_review.length > 1 && 's'})
                </span>
              </div>
            )}

            <p style={{ color: '#5f6a7d' }}>
              {getSchoolTypeTranslation(type)} | {grades}
            </p>
          </div>
        </Link>
        <div className="school-info-chipsContainer spacing-my-16">
          {openEnrollment && (
            <p className="school-info-chip spacing-mt-8">
              <ConnectionReceive16 />
              &nbsp;
              {t('results:schoolIsEnrolling')}
            </p>
          )}
          {offerTransportation ||
            (transportationOffered && (
              <Link to={requestInfoUrl}>
                <p className="school-info-chip">
                  <svg
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 32 32"
                    aria-hidden="true">
                    <path d="M27 11H29V15H27zM3 11H5V15H3zM20 20H22V22H20zM10 20H12V22H10z" />
                    <path d="M21,4H11A5.0059,5.0059,0,0,0,6,9V23a2.0023,2.0023,0,0,0,2,2v3h2V25H22v3h2V25a2.0027,2.0027,0,0,0,2-2V9A5.0059,5.0059,0,0,0,21,4Zm3,6,.0009,6H8V10ZM11,6H21a2.995,2.995,0,0,1,2.8157,2H8.1843A2.995,2.995,0,0,1,11,6ZM8,23V18H24.0012l.0008,5Z" />
                    <title>Bus</title>
                  </svg>
                  {t('results:transportationAvailable')}
                </p>
              </Link>
            ))}
        </div>

        {showMatchPercent && (
          <div className="school-card-match">
            <p className="school-card-match__divider" />
            <div className="school-card-match__title">
              <p className="school-card-match__title-overview">{t('results:cardOverview')}</p>
              <p className="school-card-match__title-textDivider">·</p>
              <p className="school-card-match__title-percentage">
                {matchpercent} % {t('results:cardMatch')}
              </p>
            </div>

            <div className="school-card-match__chipsWrapper">
              <div className="school-card-match__chipsContainer">
                {featuresmatched?.slice(0, numberOfFeaturesDisplayed).map((item) => {
                  return (
                    <span className="school-card-match__chip" key={item}>
                      {getFeatureName(item.toString())}
                    </span>
                  );
                })}
              </div>

              {featuresmatched.join().length > 45 && (
                <span className="school-card-match_toggle" onClick={() => toggleFeaturesDisplay()}>
                  {showMoreFeatures ? <ChevronUp24 /> : <ChevronDown24 />}
                </span>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="school-card_action">{children}</div>
    </div>
  );
};

export default CardSchool;
