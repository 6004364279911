import { browserHistory } from 'react-router';
import { allPublics } from './constants';

const updateSearchParam = (updates) => {
  const searchParams = new URLSearchParams(location.search);
  // Update all provided search parameters
  Object.entries(updates).forEach(([name, values]) => {
    searchParams.set(name, Array.isArray(values) ? values.join(',') : values);
  });
  const newUrl = `${location.pathname}?${searchParams.toString()}`;

  browserHistory.push(newUrl);

  const scrollPosition = window.pageYOffset;
  requestAnimationFrame(() => {
    window.scrollTo(0, scrollPosition);
  });
};

export const searchParamsController = (query) => {
  const { grades: gradeQuery, radius, school_types: schoolType, address } = query;

  const handleGrade = (grade) => {
    let gradesList = gradeQuery.split(',');

    if (!gradesList.includes(grade)) {
      if (grade === 'all') {
        gradesList = [grade];
      } else {
        if (gradesList.includes('all')) {
          gradesList = gradesList.filter((item) => item !== 'all');
        }
        gradesList.push(grade);
      }
    } else {
      if (gradesList.length === 1) return;

      gradesList = gradesList.filter((item) => item !== grade);
    }

    updateSearchParam({ grades: gradesList });
  };

  const handleType = (type) => {
    const updateTypeList = (currentTypes) => {
      if (type === 'all') return ['all'];

      let newTypes = currentTypes.filter((t) => t !== 'all');

      if (type === 'public') {
        const hasAllPublics = allPublics.every((t) => newTypes.includes(t));
        if (hasAllPublics) {
          // Remove all public types if they're all currently selected
          return newTypes.filter((t) => !allPublics.includes(t));
        }
        return [...new Set([...newTypes, ...allPublics])];
      }

      if (newTypes.includes(type)) {
        return newTypes.length > 1 ? newTypes.filter((t) => t !== type) : newTypes;
      }

      return [...newTypes, type];
    };

    const typeList = updateTypeList(schoolType.split(','));

    updateSearchParam({ school_types: typeList });
  };

  const handleDistance = (operator) => {
    const defaultValue = 2.5;
    let radiusValue = Number(radius);

    if (operator === 'plus') {
      radiusValue += defaultValue;
    } else {
      if (radiusValue === defaultValue) return;

      radiusValue -= defaultValue;
    }

    updateSearchParam({ radius: radiusValue });
  };

  const handlePlace = (place) => {
    const { address, lat, lng, formattedData } = place;

    /** update query only once */
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('address', address);
    searchParams.set('lat', lat);
    searchParams.set('lon', lng);
    searchParams.set('state', formattedData.state);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    browserHistory.push(newUrl);
  };

  return { handleGrade, handleDistance, handleType, gradeQuery, address, schoolType, radius, handlePlace };
};

export const handleChangeLocation = ({ lat, lng }) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set('lat', lat);
  searchParams.set('lon', lng);
  const newUrl = `${location.pathname}?${searchParams.toString()}`;
  browserHistory.push(newUrl);

  const scrollPosition = window.pageYOffset;
  requestAnimationFrame(() => {
    window.scrollTo(0, scrollPosition);
  });
};
