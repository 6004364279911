import jwtDecode from 'jwt-decode';
import localstorage from 'store2';
import get from 'lodash/get';

const getDecodedToken = (token) => {
  let decoded;

  try {
    decoded = jwtDecode(token);
  } catch (error) {
    decoded = null;
  }

  return decoded;
};

export function _isSuperadmin() {
  const token = localstorage.get('idToken');
  return isSuperadmin(token);
}

export function isSuperadmin(token) {
  const groups = get(getDecodedToken(token), 'app_metadata.authorization.groups');
  return Array.isArray(groups) && groups.indexOf('superadmin') >= 0;
}

export function getProfile(ownProfile) {
  if (ownProfile) return _getProfile();
  return _getParnertProfile();
}

function _getParnertProfile() {
  let partner = localstorage.get('_partnerUser');
  if (partner) return { ...partner, sub: partner.user_id };
  return _getProfile();
}

const _getProfile = () => {
  try {
    const token = localstorage.get('idToken');

    if (!token) {
      return null;
    }

    const profile = getDecodedToken(token);

    profile.isAuth0User = profile.sub.indexOf('auth0|') === 0;
    profile.picture = profile?.user_metadata?.picture || profile.picture;
    profile.providerName = getProviderName(profile);

    return profile;
  } catch (e) {
    console.log(e);
    // window.location.replace('/login')
  }
};

function getProviderName(profile) {
  let providerName = null;
  const providerId = profile?.identities?.[0]?.provider;

  if (providerId) {
    const providerPrefix = providerId.split('-')[0];

    switch (providerPrefix) {
      case 'google':
        providerName = 'Google+';
        break;
      case 'facebook':
        providerName = 'Facebook';
        break;
      default:
        break;
    }
  }

  return providerName;
}
