import config from 'config';

export const _getLocation = async (lat, long, search) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${config.google.places.apiKey}&latlng=${lat},${long}&sensor=true&result_type=${search}`
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};
export const _getLocationByZipCode = async (zipCode) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}, US&key=${config.google.places.apiKey}`
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};
