import React from 'react';
import { useScrollVisibility } from '../../hooks/useScrollVisibility';

export const MobileLayout = ({ children }) => {
  const isVisible = useScrollVisibility();

  if (!isVisible) return null;

  return <div>{children}</div>;
};
