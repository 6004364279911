import { getFeatureIcon } from 'components/pages/school-profile-page/utils/getFeatureIcon';
import { FeatureChip } from '..';
import styles from './highlight-features.module.scss';
import { Divider } from '../divider/divider';

export const HighlightFeatures = ({ highlightFeatures }) => {
  if (highlightFeatures.length === 0) return null;

  return (
    <>
      <div className="spacing-mt-32">
        <h4 className="spacing-mb-16">Highlights From The School</h4>
        <div className={styles.container}>
          {highlightFeatures?.map((feat) => (
            <FeatureChip key={feat.id} icon={getFeatureIcon(feat.group.name, '#3D7E94')} label={feat.name} />
          ))}
        </div>
      </div>
      <Divider />
    </>
  );
};
