import { SchoolHeaderTab } from '..';
import styles from './best-matches-table.module.scss';
import { useEffect, useState } from 'react';
import { transformSponsoredSchool } from '../../utils';

export const BestMatchesTable = ({
  schools = [],
  sponsoredSchool,
  handleContact,
  newListFeatures,
  featuresBySponsored,
}) => {
  const sortedIds = schools.schoolsTransformed?.map((school) => school.id);

  return (
    <section className={styles.container} style={{ display: sponsoredSchool ? 'grid' : 'block' }}>
      <div>
        <div className={styles.header}>
          <div className={styles.startHere}>
            <img src="https://static.schola.com/schola-match-results/start-here.png" alt="start here" />
          </div>
          {schools.schoolsTransformed?.map((school) => (
            <SchoolHeaderTab
              key={school.id}
              school={school}
              className={styles.headerFirst}
              handleContact={() => handleContact(school)}
            />
          ))}
        </div>
        <div className={styles.table}>
          <div className={styles.leftBlock}>
            {newListFeatures?.map((item, idx) => (
              <span key={idx} className={styles.leftLabel}>
                {item.label}
              </span>
            ))}
          </div>

          {newListFeatures?.map((feature, idx) => (
            <div key={idx} className={styles.box}>
              <div className={styles.label}>{feature.label}</div>

              {feature.offeredBy?.map((isOffered, idx) => {
                const findMatch = feature.offeredBy.find((item) => item.schoolId === sortedIds[idx]);

                return (
                  <span key={idx} className={styles.ceil}>
                    {findMatch.check ? (
                      <img
                        src="https://static.schola.com/schola-match-results/check-circle.png"
                        alt="feature incluided"
                      />
                    ) : (
                      <img
                        src="https://static.schola.com/schola-match-results/close-circle.png"
                        alt="feature not incluided"
                      />
                    )}
                  </span>
                );
              })}
            </div>
          ))}
        </div>
      </div>

      {sponsoredSchool && (
        <div className={styles.sponsored}>
          <div style={{ position: 'sticky', top: 0 }}>
            <SchoolHeaderTab
              isScholaPro
              school={sponsoredSchool}
              matchLevel="partner"
              handleContact={() => handleContact(sponsoredSchool)}
            />
          </div>
          <div>
            {featuresBySponsored?.map((item, idx) => (
              <span key={idx} className={styles.ceil}>
                {item.check ? (
                  <img src="https://static.schola.com/schola-match-results/check-circle.png" alt="feature incluided" />
                ) : (
                  <img
                    src="https://static.schola.com/schola-match-results/close-circle.png"
                    alt="feature not incluided"
                  />
                )}
              </span>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
