import schoolUrl from 'util/schoolUrl';
import { browserHistory } from 'react-router';

export const gradesLabel = (grades) => {
  return `${grades.at(0)} - ${grades.at(-1)}`;
};

export const handleRouteSchool = (school) => {
  const urlSchool = schoolUrl.build(school);
  browserHistory.push(urlSchool);
};
