import Auth0Lock from 'auth0-lock';
import { store } from 'redux/store';
import config from 'config';
import client from 'redux/client';
import localstorage from 'store2';
import { _addLog } from 'controllers/logauths/logauths_controller';
import queryString from 'query-string';

const visitor = require('visitor');

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

let lock = new Auth0Lock(config.auth0.clientId, config.auth0.domain, config.auth0.lockOptions);

export function login() {
  lock.show({
    rememberLastLogin: config.auth0.rememberLastLogin,
    initialScreen: 'login',
  });

  return {
    type: LOGIN,
  };
}

export function loginSuccess(result) {
  const { idToken, idTokenPayload } = result;

  lock.hide();
  // save login
  if (window.freshpaint) {
    window.freshpaint.identify(idTokenPayload.user_id, {
      email: idTokenPayload.email,
    });
    window.freshpaint.track(
      'login-successful',
      { email: idTokenPayload.email, user_id: idTokenPayload.user_id },
      { integrations: { All: true } }
    );
  }

  let payload = {
    user_id: idTokenPayload.user_id,
    email: idTokenPayload.email,
    geolocation: '',
    userAgent: navigator.userAgent,
    language: navigator.language,
    appsite: 'parents_website',
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // success function
        payload.geolocation = `lat:${position.coords.latitude},lon:${position.coords.longitude}`;
        _addLog(payload);
      },
      () => {
        // error function
        _addLog(payload);
      },
      { enableHighAccuracy: true } // parameters passed
    );
  } else {
    _addLog(payload);
  }

  // update anonymous tracking
  if (
    localStorage.getItem('visitor_id') !== null &&
    localStorage.getItem('visitor_id') !== 0 &&
    !localStorage.getItem('visitor_user_id')
  ) {
    visitor.updateVisitor(localStorage.getItem('visitor_id'));
  }

  return {
    type: LOGIN_SUCCESS,
    idToken,
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

export function logout() {
  localstorage.remove('visitor_id');
  localstorage.remove('visitor_user_id');
  if (window.freshpaint) {
    window.freshpaint.reset();
  }

  return {
    type: LOGOUT,
  };
}

// pipe auth0 events to redux actions
lock
  .on('authenticated', (result) => {
    localstorage.set('idToken', result.idToken);
    localstorage.set('accessToken', result.accessToken);
    store.dispatch(loginSuccess(result));
    client.patch(`leads/associate/${result.idTokenPayload.sub}`);
  })
  .on('authorization_error', (error) => {
    store.dispatch(loginFailure(error));
  })
  .on('unrecoverable_error', (error) => {
    store.dispatch(loginFailure(error));
  })
  .on('signup success', (results) => {
    if (window.freshpaint) {
      window.freshpaint.identify(`auth0|${results.Id}`, {
        email: results.email,
      });
      window.freshpaint.track(
        'signup-successful',
        { email: results.email, user_id: `auth0|${results.Id}` },
        { integrations: { All: true } }
      );
      if (window.location.href.indexOf('fbclid=') > -1) {
        const parsed = queryString.parse(window.location.search);
        window.freshpaint.track(
          'signup-successful-fbclid',
          { email: results.email, user_id: `auth0|${results.Id}`, fbclid: parsed.fbclid },
          { integrations: { All: true } }
        );
      }
    }
  })
  .on('hide', () => {
    store.dispatch({ type: LOGIN, hide: true });
  });

export function signUp() {
  lock.show({
    rememberLastLogin: config.auth0.rememberLastLogin,
    initialScreen: 'signUp',
  });

  return {
    type: LOGIN,
  };
}

// SCHOL-197 hide the Auth0Lock when login page is closed
// added to solve issue of loading when trying to open login page more than once . i.e. login => signup => login
export function hide() {
  lock.hide();
  return {
    type: LOGIN,
  };
}
