import client from 'redux/client';

export const GET_TEACHERS = 'GET_TEACHERS';
export const GET_TEACHER = 'GET_TEACHER';
export const ADD_TEACHER = 'ADD_TEACHER';
export const UPDATE_TEACHERS = 'UPDATE_TEACHERS';
export const DELETE_TEACHER = 'DELETE_TEACHER';

export function getTeachers(schoolId) {
  return {
    type: GET_TEACHERS,
    promise: client.get(`teachers/${schoolId}`),
  };
}

export function getTeacher(schoolId, teacherId) {
  return {
    type: GET_TEACHER,
    promise: client.get(`teachers/${schoolId}/${teacherId}`),
  };
}

export function addTeacher(schoolId, teacherData) {
  const formData = new FormData();
  formData.append('name', teacherData.name);
  formData.append('taught', teacherData.taught);
  formData.append('email', teacherData.email);
  formData.append('grades', teacherData.grades);
  formData.append('rate', teacherData.rate);
  formData.append('comments', teacherData.comments);
  formData.append('file', teacherData.file);

  return {
    type: ADD_TEACHER,
    promise: client.post(`teachers/${schoolId}`, formData),
  };
}

export function updateTeacher(schoolId, teacherId, teacherData) {
  const formData = new FormData();
  formData.append('name', teacherData.name);
  formData.append('taught', teacherData.taught);
  formData.append('email', teacherData.email);
  formData.append('grades', teacherData.grades);
  formData.append('rate', teacherData.rate);
  formData.append('comments', teacherData.comments);
  formData.append('file', teacherData.file);

  return {
    type: UPDATE_TEACHERS,
    promise: client.post(`teachers/${schoolId}/${teacherId}`, formData),
  };
}

export function deleteTeacher(schoolId, teacherId) {
  return {
    type: DELETE_TEACHER,
    promise: client.delete(`teachers/${schoolId}/${teacherId}`),
  };
}
