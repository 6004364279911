import client from '../client';
import { getResponseValue } from 'util/validate';

export const _sendNotMatchForm = async (form) => {
  const res = await client.post('user/school-search/not-match', form);
  return await getResponseValue(res);
};

export const _saveSearchHistory = async (payload) => {
  const res = await client.post(`schools/savesearchhistory`, payload);
  return await getResponseValue(res);
};
