import React, { useState, useEffect } from 'react';
import { Close24 } from '@carbon/icons-react';
import { Button } from 'schola-components';
import './formReview.scss';
import RateExperience from './RateExperience';
import LikeQuestion from './LikeQuestion';
import ReviewQuestion from './ReviewQuestion';

import RelationshipWithSchool from './RelationshipWithSchool';
import ThanksForYourReview from './ThanksForYourReview';

import { _getSchoolReviews } from '../../../../../controllers/school-profile/reviews_controller';

const FormReview = ({ onClose, schoolName, school_id: schoolId, loggedIn, setFlag, rateGeneral, setGeneralRating }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(1);
  }, []);

  const totalSteps = 5;

  const increaseStep = () => {
    if (step < totalSteps) {
      setStep((state) => state + 1);
    }
  };
  const decreaseStep = () => {
    if (step > 1) {
      setStep((state) => state - 1);
    }
  };
  const progress = (step * 100) / totalSteps;

  const [rates, setRates] = useState({
    general: rateGeneral.general,
    academics: 0,
    teachers: 0,
    schoolEnvironment: 0,
    schoolCulture: 0,
    sports: 0,
    clubsAndActivities: 0,
    diversityCulture: 0,
    facilities: 0,
  });
  const [reviewText, setReviewText] = useState('');
  const [selectedLikes, setSelectedLikes] = useState([]);
  const [relationship, setRelationship] = useState(null);

  const likes = selectedLikes.map((item) => ({ item }));

  // const ratesArr = Object.values(rates)

  // const ratesSum = ratesArr.reduce((sum, value) => {
  //     return sum + value
  //   }, 0)

  // const ratesAverage = Math.round(ratesSum / ratesArr.length)

  const finalReview = {
    topics: [
      {
        rate: rates.academics,
        item: 'academics',
      },
      {
        rate: rates.teachers,
        item: 'teachers',
      },
      {
        rate: rates.schoolEnvironment,
        item: 'schoolEnvironment',
      },
      {
        rate: rates.schoolCulture,
        item: 'schoolCulture',
      },
      {
        rate: rates.sports,
        item: 'sports',
      },
      {
        rate: rates.clubsAndActivities,
        item: 'clubsAndActivities',
      },
      {
        rate: rates.diversityCulture,
        item: 'diversityCulture',
      },
      {
        rate: rates.facilities,
        item: 'facilities',
      },
    ],
    rate: rates.general,
    message: reviewText,
    likes,
    relationship,
  };

  useEffect(() => {
    document.body.classList = 'layoutv3 schola__bodyfixed';

    return () => {
      document.body.className = 'layoutv3';
    };
  }, []);

  // Replace with individual questions components
  const questionsComponents = [
    <RateExperience state={rates} setState={setRates} increaseStep={increaseStep} />,
    <LikeQuestion
      schoolName={schoolName}
      state={selectedLikes}
      setState={setSelectedLikes}
      increaseStep={increaseStep}
      decreaseStep={decreaseStep}
    />,
    <ReviewQuestion
      schoolName={schoolName}
      state={reviewText}
      setState={setReviewText}
      increaseStep={increaseStep}
      decreaseStep={decreaseStep}
    />,
    <RelationshipWithSchool
      loggedIn={loggedIn}
      schoolName={schoolName}
      state={relationship}
      setState={setRelationship}
      increaseStep={increaseStep}
      decreaseStep={decreaseStep}
      finalReview={finalReview}
      school_id={schoolId}
      setFlag={setFlag}
    />,
    <ThanksForYourReview schoolName={schoolName} />,
  ];

  return (
    <section className="bigModalReview">
      <div className="spacing-pb-24 spacing-pl-40 spacing-pt-24">
        <div onClick={onClose} className="bigModalReview_tab">
          <Close24 />
        </div>
      </div>

      <div className="bigModalReview_bar spacing-mb-32" style={{ width: `${progress}%` }} />

      <div className="bigModalReview_content">{questionsComponents[step - 1]}</div>
    </section>
  );
};

export default FormReview;
