import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import styles from './header.module.scss';
import { getStudent } from '../../utils/get-student';
import { useSelector } from 'react-redux';

export const Header = ({ personality, caption, pathname }) => {
  const student = getStudent();

  const { schoolsPath, overviewPath } = useSelector((state) => state.schoolResults);

  return (
    <div className={styles.header}>
      <div className={styles.header_container}>
        <div className={styles.logo}>
          <img src="https://static.schola.com/scholamatch/Logo-sm-results.svg" alt="ScholaMatch Results" />
        </div>
        <h1 className="font-700">
          <span className="underline">{student}</span> is a <span className={styles.personality}>{personality}</span>
        </h1>
        <p>{caption}</p>
      </div>
      <div className={styles.tabContainer}>
        <div className={styles.tabs}>
          <Tab pathname={pathname} pathKey="overview" to={overviewPath}>
            Overview
          </Tab>
          <Tab pathname={pathname} pathKey="schools" to={schoolsPath}>
            Schools for you
          </Tab>
        </div>
      </div>
    </div>
  );
};

const Tab = ({ children, pathname, pathKey, to }) => {
  const tabStyles = {
    [styles.tab]: true,
    [styles.selected]: pathname.includes(pathKey),
    [styles.default]: !pathname.includes(pathKey),
    [styles.results_tab]: pathname.includes('schools'),
  };

  return (
    <Link to={to} className={classNames(tabStyles)}>
      {children}
    </Link>
  );
};
