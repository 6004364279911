import styles from '../column-filters/column-filters.module.scss';

export const Checkbox = ({ label, id, onChange, isChecked, className }) => {
  return (
    <label htmlFor={id} className={`${styles.checkLevel0} ${className || ''}`}>
      <span>{label}</span>
      <input
        style={{ height: '16px', width: '16px' }}
        type="checkbox"
        className={styles.check}
        id={id}
        name={id}
        value={id}
        onChange={onChange}
        checked={isChecked}
      />
    </label>
  );
};
