import React from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import './gallery-modal.scss';
import { ModalMobile } from 'components/pages/parent-portal/track/components/modal-mobile/modal-mobile';

export const GalleryModal = ({ schoolName, images, isVisible, openViewPhotos }) => {
  let schoolGalleryImages = images?.map((o) => {
    const filenameArr = o?.filename.split('/');
    const largeName = `/l-${filenameArr.pop()}`;

    const largeUrl = filenameArr.join('/') + largeName;

    return { ...o, original: largeUrl, thumbnail: largeUrl.replace('/l-', '/s-') };
  });
  return (
    <ModalMobile
      typeButton="close"
      isVisible={isVisible}
      className="gallery-modal__title"
      title={`Photos Of ${schoolName}`}
      onClose={openViewPhotos}>
      <ImageGallery
        items={schoolGalleryImages}
        onImageError={(event) => {
          console.log(event.target.src);
          // if exist an error loading the large or small image then try load the default
          if (event.target.src.indexOf('/l-') !== -1) {
            event.target.src = event.target.src.replace('/l-', '/');
          } else if (event.target.src.indexOf('/s-') !== -1) {
            event.target.src = event.target.src.replace('/s-', '/');
          }
        }}
        onThumbnailError={(event) => {
          console.log(event.target.src);
          // if exist an error loading the large or small image then try load the default
          if (event.target.src.indexOf('/l-') !== -1) {
            event.target.src = event.target.src.replace('/l-', '/');
          } else if (event.target.src.indexOf('/s-') !== -1) {
            event.target.src = event.target.src.replace('/s-', '/');
          }
        }}
        showFullscreenButton={false}
        showPlayButton={false}
        infine={false}
        lazyLoad
      />
    </ModalMobile>
  );
};

GalleryModal.propTypes = {
  school: PropTypes.object.isRequired,
  openViewPhotos: PropTypes.func,
};
