import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'components/translation/Translation';
import { fetchBoardsData, onOpenAddBoard, removeSchool } from 'redux/board/board-actions';
import { getProfile } from 'util/auth';
import Slider from 'react-slick';
import { Favorite16, FavoriteFilled16, ChevronRight24, ChevronLeft24 } from '@carbon/icons-react';
import CardSchool from 'components/card-school/CardSchool';
import { isFavorite } from 'components/SchoolFavorites/utils';
import { useGetNearbySchoolsQuery } from 'redux/api/school-api';
import './nearby-school-caroussel.scss';

export const NearbySchoolsCaroussel = ({ school, renderMobile }) => {
  const loggedIn = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();
  const userId = useMemo(() => getProfile()?.sub, [loggedIn]);

  const { longitude, latitude } = school;

  const { data, isSuccess } = useGetNearbySchoolsQuery({ lon: longitude, lat: latitude });

  const schools = useMemo(() => data?.results?.filter((item) => item.id !== school.id), [data]);

  useEffect(() => {
    if (loggedIn) dispatch(fetchBoardsData(userId));
  }, []);

  const favoritesState = useSelector((state) => state.favorites);
  const { boards, favoritesIDs } = favoritesState;

  const handleAddFavorite = (school) => {
    dispatch(onOpenAddBoard(school.id));
  };

  const remove = async (schoolId) => {
    if (loggedIn) {
      const findBoard = boards.boardSchools.find((item) => item.school_id === schoolId);

      dispatch(removeSchool(userId, findBoard.board_id, schoolId));
    }
  };

  const settingsFeaturedSchool = {
    dots: renderMobile,
    arrows: !renderMobile,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    infinite: false,
    speed: 500,
    slidesToShow: 3.15,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (schools?.length === 0) {
    return null;
  }

  // React slick is bugged we need to listen resize the page to get the correct width
  useEffect(() => {
    const resizeWindow = () => {
      window.dispatchEvent(new Event('resize'));
    };

    const timeoutId = setTimeout(resizeWindow, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className="caroussel-card spacing-mb-48" id="nearby-slider" data-testid="nearby-slider">
        <h3 className="title-nearby spacing-mb-24 spacing-mt-24">{t('schoolProfile:nearbySchools')}</h3>
        <Slider {...settingsFeaturedSchool}>
          {isSuccess &&
            schools.map((school, index) => (
              <div key={index} className="card">
                <CardSchool school={school}>
                  <div className="schools_slider__buttons">
                    {isFavorite(favoritesIDs, school.id) ? (
                      <div style={{ cursor: 'pointer' }} onClick={() => remove(school.id)}>
                        <FavoriteFilled16 fill="#006B8F" />
                      </div>
                    ) : (
                      <div style={{ cursor: 'pointer' }} onClick={() => handleAddFavorite(school)}>
                        <Favorite16 />
                      </div>
                    )}
                  </div>
                </CardSchool>
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
};

NearbySchoolsCaroussel.propTypes = {
  school: PropTypes.object.isRequired,
};

const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <div className="custom-button-slider prev-btn">
    <div style={{ cursor: 'pointer' }} {...props} className="prev" aria-disabled={currentSlide === 0}>
      <ChevronLeft24 />
    </div>
  </div>
);
const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <div className="custom-button-slider next-btn">
    <div style={{ cursor: 'pointer' }} {...props} className="next" aria-disabled={currentSlide === slideCount - 1}>
      <ChevronRight24 />
    </div>
  </div>
);
