import ReactPixel from 'react-facebook-pixel';

const fbPixelAdvancedMatching = {};
const fbPixelOptions = { autoConfig: true, debug: false };
if (process.env.REACT_APP_ENV === 'production') {
  ReactPixel.init('1367208973344498', fbPixelAdvancedMatching, fbPixelOptions);
} else {
  ReactPixel.init('2593234050969081', fbPixelAdvancedMatching, fbPixelOptions);
}
ReactPixel.pageView();
