import { useDispatch, useSelector } from 'react-redux';
import { toggleFilters, toggleMap } from 'redux/slices/school-results.slice';
import { SettingsAdjust16, Map16 } from '@carbon/icons-react';
import styles from './settings.module.scss';
import { Buttonv2 as Button } from 'schola-components';

const SettingButton = ({ label, icon, onClick }) => {
  return (
    <Button icon color="secondary" onClick={onClick}>
      {icon}
      <span>{label}</span>
    </Button>
  );
};

const FiltersToggle = () => {
  const dispatch = useDispatch();
  const onOpenFilters = () => dispatch(toggleFilters());

  return <SettingButton onClick={onOpenFilters} label="Filters" icon={<SettingsAdjust16 />} />;
};

export const Settings = () => {
  return (
    <div className={styles.settings}>
      <FiltersToggle />
      <MapToggle />
    </div>
  );
};

export const MapToggle = () => {
  const dispatch = useDispatch();
  const onOpenMap = () => dispatch(toggleMap());
  const { isMapOpen } = useSelector((state) => state.schoolResults);

  return <SettingButton onClick={onOpenMap} label={`View on ${!isMapOpen ? 'Map' : 'List'}`} icon={<Map16 />} />;
};
