export const ArrowRight = (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M14.25 10.9165L8 17.1665L7.125 16.2915L12.5 10.9165L7.125 5.5415L8 4.6665L14.25 10.9165Z" fill="#5F6A7D" />
  </svg>
);

export const BlobSneakPeek = ({ className }) => (
  <svg
    width="566"
    height="546"
    className={className}
    viewBox="0 0 566 546"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M226.239 526.556C158.633 524.712 84.5598 522.094 38.7511 472.396C-6.0168 423.827 -2.64489 350.836 3.69751 285.122C9.31139 226.956 35.7518 176.866 71.7219 130.781C114.872 75.4963 156.058 0.136568 226.239 0.000183105C296.444 -0.13625 329.577 82.8243 381.358 130.179C437.701 181.708 528.793 209.373 538.006 285.122C547.549 363.588 486.796 434.483 424.257 482.914C368.724 525.92 296.48 528.473 226.239 526.556Z"
      fill="#0099CC"
      fillOpacity="0.08"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.535 544.544C174.047 542.647 97.913 539.954 50.8295 488.841C4.81577 438.89 8.28153 363.822 14.8004 296.237C20.5706 236.415 47.7468 184.9 84.7179 137.504C129.069 80.6451 171.401 3.14042 243.535 3.00016C315.694 2.85984 349.749 88.1817 402.971 136.885C460.882 189.88 554.509 218.332 563.978 296.237C573.787 376.937 511.343 449.85 447.063 499.659C389.985 543.889 315.731 546.515 243.535 544.544Z"
      stroke="#99D6EB"
      strokeWidth="2"
      strokeDasharray="8 8"
    />
  </svg>
);

export const BlobHero = ({ className }) => (
  <svg
    className={className}
    width="196"
    height="225"
    viewBox="0 0 196 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M144.639 110.273C129.538 79.3934 94.6622 44.4035 58.6011 72.6869C22.54 100.97 51.0209 142.261 56.7105 147.965C62.4001 153.67 71.1735 160.469 76.0323 168.672C80.8911 176.876 86.9905 197.171 97.5675 204.024C108.145 210.877 126.19 216.581 143.967 191.092C159.055 169.465 163.152 148.128 144.639 110.273Z"
        fill="#DBF1F8"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M146.055 176.269L146.055 176.269C134.089 192.112 122.636 198.533 112.681 200.153C102.707 201.776 94.0663 198.607 87.6969 194.923C81.495 191.335 76.7607 183.713 72.8382 175.59C71.1563 172.107 69.6439 168.577 68.2337 165.287C68.0081 164.76 67.7852 164.24 67.5645 163.727C65.9748 160.03 64.4943 156.681 63.0497 154.232C60.1625 149.339 56.0035 144.989 51.756 141.08C49.7049 139.193 47.6198 137.397 45.6374 135.689L45.4183 135.5C43.365 133.731 41.4328 132.062 39.742 130.464C38.1259 128.937 34.8126 124.834 31.492 118.936C28.1796 113.053 24.8957 105.446 23.2872 96.9271C21.6798 88.4147 21.7481 79.0107 25.113 69.4952C28.4762 59.9846 35.1596 50.2862 46.9001 41.2209C70.3341 23.1265 92.2901 23.8834 110.538 33.5584C128.862 43.2733 143.499 62.02 152.005 79.9483C162.46 101.984 165.996 119.354 164.444 134.521C162.892 149.679 156.244 162.781 146.055 176.269Z"
        stroke="#0099CC"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const highlightPartner = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6466 16.2509C12.1744 15.8351 13.7739 15.6254 15.3256 15.4333C15.6598 15.393 15.97 15.6178 15.9939 15.9345C16.0416 16.2514 15.8028 16.5412 15.4925 16.5815C13.9886 16.7665 12.4371 16.9644 10.9809 17.3647C10.6706 17.4515 10.3362 17.2728 10.2408 16.9653C10.1453 16.6581 10.3363 16.338 10.6466 16.2509Z"
      fill="#5D3276"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01593 10.3563C8.47468 7.92297 11.2438 5.80288 13.6548 3.30427C13.8696 3.07092 14.2516 3.05842 14.4903 3.27626C14.729 3.49434 14.7528 3.86103 14.5141 4.09439C12.103 6.60017 9.33396 8.72721 6.87521 11.1677C6.63649 11.3955 6.25465 11.3987 6.01593 11.1747C5.80109 10.9508 5.77722 10.5841 6.01593 10.3563Z"
      fill="#5D3276"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.40816 0.996842C1.33654 2.38956 1.26507 3.78227 1.19346 5.17522C1.19346 5.49423 0.906859 5.74055 0.57266 5.72504C0.23846 5.7093 -0.000113845 5.43775 -0.000113845 5.11851C0.0715003 3.72347 0.142975 2.32867 0.214589 0.933873C0.23846 0.614863 0.524963 0.370165 0.859163 0.387527C1.16949 0.40489 1.43203 0.678063 1.40816 0.996842Z"
      fill="#5D3276"
    />
  </svg>
);
