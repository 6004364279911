import React, { useState } from 'react';
import classNames from 'classnames';
import ModalAdapter from 'components/modal-adapter/modal-adapter';
import { SchoolTypes } from '../../school-types/school-types';
import { useResponsiveRender } from 'hooks/useResponsiveRender';
import { schoolTypes } from '../../utils';
import { t, tString } from 'components/translation/Translation';
import { FeatureListItem } from 'components/feature-list/feature-list-item/feature-list-item';
import Button from 'components/button/Button';
import styles from './about-banner.module.scss';

/**
 * @param {object} props
 * @param {string | object} props.title
 * @param {string} props.text
 * @param {string} props.textLink
 * @param {string} props.shape - One of ['square', 'rectangle']
 */

export const AboutBanner = ({ title, text, textLink, shape, className, chips }) => {
  const BannerClasses = {
    [className]: className,
    [styles.banner]: true,
  };
  const scoutBannerImg = 'https://static.schola.com/profile/Scout_pose-32.webp';
  const [toggleModal, setToggleModal] = useState(false);
  const { renderMobile } = useResponsiveRender({ breakpoint: 525 });
  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };
  return (
    <div className={classNames(BannerClasses)}>
      <div className={styles.wrapper}>
        {shape === 'square' && (
          <img
            className={styles.top_scout}
            src={scoutBannerImg}
            alt="Schola® Scout"
            data-testid="scout-about"
            width="64"
            height="68"
            loading="lazy"
          />
        )}
        <div className={`${styles['about-banner']} ${styles[shape]}`}>
          {shape === 'rectangle' && (
            <img width="64" height="68" src={scoutBannerImg} alt="Schola® Scout" data-testid="scout-about" />
          )}
          <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            {chips && (
              <div className={styles.chipsContainer}>
                {chips.map((chip, idx) => (
                  <FeatureListItem feature={{ name: chip, id: idx }} type="TOP" key={idx} />
                ))}
              </div>
            )}
            <p className={styles.text}>{text}</p>
            {textLink && (
              <Button color="green" onClick={handleToggleModal}>
                {textLink}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ModalAdapter
        onClose={handleToggleModal}
        renderMobile={renderMobile}
        isVisible={toggleModal}
        classNameContent={styles.schoolTypes}>
        <SchoolTypes />
      </ModalAdapter>
    </div>
  );
};

export const SchoolTypeBanner = ({ type, shape = 'rectangle', className }) => {
  return (
    <AboutBanner
      className={className}
      title={t('schoolProfile:schoolTypeBanner.title', { schoolType: tString(schoolTypes[type].title) })}
      text={schoolTypes[type].description}
      chips={schoolTypes[type].chips}
      textLink={tString('schoolProfile:learnMore')}
      shape={shape}
    />
  );
};
