import React from 'react';
import styles from './schola-partner-banner.module.scss';
import { Checkmark16 } from '@carbon/icons-react';
import { t } from 'components/translation/Translation';
import { highlightPartner } from 'components/pages/school-profile/assets/svg-assets';

export const ScholaPartnerBanner = ({ className }) => {
  return (
    <div className={`${styles.boxContainer} ${className}`}>
      <span className={styles.boxContainer_title}>
        <img src="https://static.schola.com/profile/scout-partner.webp" width="24" height="25" alt="Scout Partner" />
        <div className={styles.titleContainer}>
          <p className={styles.bannerTitle}>{t('contactSchool:message.partner.title')}</p>
          {highlightPartner}
        </div>
      </span>
      <p className={styles.bannerDescription}>{t('contactSchool:message.partner.subtitle')}</p>
      <ul>
        <ListItem>{t('contactSchool:message.partner.item1')}</ListItem>
        <ListItem>{t('contactSchool:message.partner.item2')}</ListItem>
        <ListItem>{t('contactSchool:message.partner.item3')}</ListItem>
      </ul>
      <img className={styles.bubbleShape} src="https://static.schola.com/profile/bubble.svg" alt="bubble shape" />
    </div>
  );
};

const ListItem = ({ children }) => {
  return (
    <li className={styles.item}>
      <Checkmark16 /> <span>{children}</span>
    </li>
  );
};
