import { t } from 'components/translation/Translation';

export const schoolTypes = {
  'public district': {
    title: 'schoolProfile:scholaTypes.publicDistrict.title',
    description: t('schoolProfile:scholaTypes.publicDistrict.description'),
    chips: [
      t('schoolProfile:scholaTypes.publicDistrict.firstChip'),
      t('schoolProfile:scholaTypes.publicDistrict.secondChip'),
      t('schoolProfile:scholaTypes.publicDistrict.thirdChip'),
    ],
  },
  'public charter': {
    title: 'schoolProfile:scholaTypes.publicCharter.title',
    description: t('schoolProfile:scholaTypes.publicCharter.description'),
    chips: [
      t('schoolProfile:scholaTypes.publicCharter.firstChip'),
      t('schoolProfile:scholaTypes.publicCharter.secondChip'),
      t('schoolProfile:scholaTypes.publicCharter.thirdChip'),
    ],
  },
  'public magnet': {
    title: 'schoolProfile:scholaTypes.publicMagnet.title',
    description: t('schoolProfile:scholaTypes.publicMagnet.description'),
    chips: [
      t('schoolProfile:scholaTypes.publicMagnet.firstChip'),
      t('schoolProfile:scholaTypes.publicMagnet.secondChip'),
      t('schoolProfile:scholaTypes.publicMagnet.thirdChip'),
    ],
  },
  private: {
    title: 'schoolProfile:scholaTypes.privateSchool.title',
    description: t('schoolProfile:scholaTypes.privateSchool.description'),
    chips: [
      t('schoolProfile:scholaTypes.privateSchool.firstChip'),
      t('schoolProfile:scholaTypes.privateSchool.secondChip'),
      t('schoolProfile:scholaTypes.privateSchool.thirdChip'),
    ],
  },
};

export const validTypes = ['public district', 'public charter', 'public magnet', 'private'];
