import React, { useEffect, useState } from 'react';
import styles from './profile-map.module.scss';
import { LocationCompanyFilled24, LocationPersonFilled24 } from '@carbon/icons-react';
import useLocation from 'hooks/useLocation';
import { LeafletMap } from 'components/leaflet-map/leaflet-map';
import { Divider } from '../divider/divider';

export const ProfileMap = ({ school }) => {
  const { latitude, longitude, name, address } = school;
  const { location } = useLocation();

  const [distance, setDistance] = useState(null);
  useEffect(() => {
    if (latitude && longitude && location) {
      const calculatedDistance = calculateDistance(latitude, longitude, location.currentLat, location.currentLong);
      setDistance(calculatedDistance);
    }
  }, [latitude, longitude, location]);

  return (
    <>
      <div className="spacing-mb-32">
        <h4 className="spacing-mb-16">School Location</h4>
        <div className={styles.description}>
          <LocationCompanyFilled24 fill="#0099CC" /> <p>{address}</p> {distance && <p> | Distance: {distance} miles</p>}
        </div>
        <LeafletMap center={[latitude, longitude]}>
          <LeafletMap.Marker position={[latitude, longitude]} Icon={LocationCompanyFilled24}>
            <span>
              {name}: {address}
            </span>
          </LeafletMap.Marker>
          {location?.currentLat && (
            <LeafletMap.Marker
              position={[location.currentLat, location.currentLong]}
              Icon={LocationPersonFilled24}
              aria-label="User Location Marker">
              <span>Your Location</span>
            </LeafletMap.Marker>
          )}
        </LeafletMap>
      </div>
      <Divider />
    </>
  );
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c; // Distance in kilometers
  const distanceInMiles = distanceInKm * 0.621371; // Convert to miles
  return distanceInMiles.toFixed(2); // Return distance rounded to 2 decimal places
};
