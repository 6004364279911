import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './userAvatar.scss';

// name:string by default for development and designing
const UserAvatar = ({ user = 'name', size = 'big' }) => {
  const COLORS = [
    { backgound: '#E6F5FA', border: '#4DB8DB', color: '#006B8F' },
    { backgound: '#F5FAEB', border: '#B8DB70', color: '#6B8F24' },
    { backgound: '#FFF7EF', border: '#FFC48C', color: '#B3773F' },
    { backgound: '#FBE9ED', border: '#F37B94', color: '#A72E47' },
    { backgound: '#EEF1F9', border: '#859FD8', color: '#38538B' },
    { backgound: '#E7EFF2', border: '#9EBFC9', color: '#3D7E94' },
  ];

  const random = useMemo(() => Math.round(Math.random() * 5), []);

  const name = user[0].toUpperCase();

  const sizePx = size === 'big' ? '40px' : '24px';
  const fontPx = size === 'big' ? '16px' : '12px';
  const getSize = {
    width: sizePx,
    height: sizePx,
    fontSize: fontPx,
  };

  return (
    <div
      className="userAvatar"
      style={{
        backgroundColor: COLORS[random].backgound,
        border: `0.8px solid ${COLORS[random].border}`,
        color: COLORS[random].color,
        ...getSize,
      }}>
      {name}
    </div>
  );
};

export default UserAvatar;

UserAvatar.propTypes = {
  user: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
};
