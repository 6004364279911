import { useEffect, useState } from 'react';
import SearchBarSM from '../components/search-bar-sm/SearchBarSM';
import { _getFeatures } from '../../../../controllers/features/features_controller';

function useScholaMatch() {
  const emptyMatchState = {
    name: '',
    grade: null,
    year: '',
    address: {},
    schoolType: null,
    learningEnvironment: null,
    schoolWithSpecificPrograms: null,
    wantSpecialities: null,
    withReligiousAffiliation: null,
    religiousAffiliation: null,
    singleSex: null,
    specialties: [],
    howDoYouFeelExtracurricular: null,
    wantToExplore: null,
    interestSelectedFromSearchBar: [],
    interestNameFromSearchBar: [],
    features: null,
    interestSports: null,
    interestSportsOptions: [],
    interestArts: null,
    interestArtsOptions: [],
    interestScience: null,
    interestScienceOptions: [],
    interestEnviroment: null,
    interestEnviromentOptions: [],
    interestVolunteer: null,
    interestVolunteerOptions: [],
    interestCultural: null,
    interestCulturalOptions: [],
    interestLeadership: null,
    interestLeadershipOptions: [],
    travelDistance: null,
    canTransport: null,
    firstLanguageIsEnglish: null,
    childNeedSchoolSpecialEducationServices: null,
    childNeedEnglishLearningSupport: null,
    childHaveSpecialEducationNeeds: [],
    specificSchool: null,
    specificSchoolSelected: null,
    completedSections: [],
    reviewableQuestions: [],
    selectCharacteristicIdealSchool: null,
    whichCharacteristicBeNiceSchoolHave: null,
    isOnlyOneFeatureMustHave: null,
    sendEmailMatches: null,
    emailToSendMatches: '',
  };
  const [matchState, setMatchState] = useState(emptyMatchState);

  const clear = () => {
    setMatchState(emptyMatchState);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setMatchState({
      ...matchState,
      [name]: value,
    });
  };

  const handleSelect = (item, selectionArray, key) => {
    const allOption = 'Open To All Options';

    if (item == allOption) {
      if (selectionArray.includes(allOption) && item !== allOption) {
        setMatchState({
          ...matchState,
          [key]: selectionArray.filter((obj) => obj !== allOption),
        });
      } else {
        setMatchState({
          ...matchState,
          [key]: [item],
        });
      }
    } else if (selectionArray.includes(allOption)) {
      setMatchState({
        ...matchState,
        [key]: [item],
      });
    } else if (selectionArray.includes(item)) {
      setMatchState({
        ...matchState,
        [key]: selectionArray.filter((obj) => obj !== item),
      });
    } else {
      setMatchState({
        ...matchState,
        [key]: [...selectionArray, item],
      });
    }
  };

  const activateSearch = (dataArr, searchValueName, placeholder) => {
    if (dataArr.length > 8) {
      return (
        <div className="searchContainer spacing-ml-64 spacing-mr-64 spacing-mb-32">
          <SearchBarSM dataArr={dataArr} searchValueName={searchValueName} placeholder={placeholder} />
        </div>
      );
    }
  };

  const updateState = (key, value) => {
    setMatchState({
      ...matchState,
      [key]: value,
    });
  };

  const localSave = (matchState) => {
    if (Object.values(matchState).some((item) => item?.length > 0) == true) {
      localStorage.setItem('scholamatchInfo', JSON.stringify(matchState));
    }
  };

  const localRetrieve = () => {
    if (localStorage.scholamatchInfo) {
      let scholamatchFromLocal = JSON.parse(localStorage.scholamatchInfo);

      if (
        Object.values(scholamatchFromLocal).some((item) => item?.length > 0) == true &&
        Object.values(matchState).some((item) => item?.length > 0) == false
      ) {
        setMatchState(scholamatchFromLocal);
      }
    }
  };

  const removeLocalSM = () => {
    localStorage.removeItem('scholamatchInfo');
  };

  useEffect(() => {
    localSave(matchState);
    localRetrieve();
  }, [matchState]);

  return {
    matchState,
    setMatchState,
    clear,
    handleInput,
    handleSelect,
    activateSearch,
    updateState,
    removeLocalSM,
  };
}

export default useScholaMatch;
