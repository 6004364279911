import { useEffect, useState } from 'react';
import { _getNotesByLead } from 'controllers/leads/leads_controller';

export const useNotes = (leadId) => {
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    _getNotesByLead(leadId)
      .then((res) => res.json())
      .then((data) => setNotes(data));
  }, [leadId]);

  return notes;
};
