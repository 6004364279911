/* eslint-disable */ 
import { combineReducers } from 'redux';
import getApiReducer from 'redux/util/get-api-reducer';
import Immutable from 'immutable';

import {
  UPLOAD_IMAGE,
  GET_USER_AVATARS,
  GET_USER_CONNECTIONS,
  ACCEPT_USER_CONNECTIONS,
  DECLINE_USER_CONNECTIONS,
  GET_CONNECTION_COUNT,
  GET_USER_EMAIL,
  ADD_USER,
  ADD_SEARCH,
  GET_SEARCHES,
  GET_TOURS_BY_USER,
  GET_LEADS_BY_USER,   
  UPDATE_USER_APPLICATION,
  GET_USER_APPLICATIONS,
  UPDATE_USER_INFO,
  UPDATE_USER_SCHOOL_DEPARTMENT,
  UPDATE_USER_PASSWORD
} from './user-actions';

const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: Immutable.Map(),
  error: false,
  validationErrors: Immutable.Map(),
});

const avatars = (state = defaultState, action) => {
  const newAvatars = getApiReducer(GET_USER_AVATARS)(state, action);
  switch (action.type) {
    case 'GET_USER_AVATARS_SUCCESS':
      return newAvatars;
    default: 
      return state;
  }
};

const reducers = combineReducers({
  uploadImage: getApiReducer(UPLOAD_IMAGE),
  avatars: avatars,
  connections: getApiReducer(GET_USER_CONNECTIONS),
  acceptUserConnections: getApiReducer(ACCEPT_USER_CONNECTIONS),
  declineUserConnections: getApiReducer(DECLINE_USER_CONNECTIONS),
  connectionCount : getApiReducer(GET_CONNECTION_COUNT),
  getUserIdByEmail : getApiReducer(GET_USER_EMAIL),
  createUser: getApiReducer(ADD_USER),
  addSearch : getApiReducer(ADD_SEARCH),
  getSearches : getApiReducer(GET_SEARCHES),
  getTourList : getApiReducer(GET_TOURS_BY_USER),
  getUserLeads : getApiReducer(GET_LEADS_BY_USER),  
  getUserApplication : getApiReducer(UPDATE_USER_APPLICATION),
  getUserApplications : getApiReducer(GET_USER_APPLICATIONS),
  updateUserInfo : getApiReducer(UPDATE_USER_INFO),
  updateUserPassword : getApiReducer(UPDATE_USER_PASSWORD),
  updateUserSchoolDepartment : getApiReducer(UPDATE_USER_SCHOOL_DEPARTMENT),
});

export default reducers;
