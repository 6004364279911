import {
  question1SchoolType as schoolTypeBucket,
  question2Features as subjectBucket,
  question3Features as learningPreferencesBucket,
  question4Features as extracurricularActivitiesBucket,
  question5Features as specialNeedsBucket,
  question6Features as languageNeedsBucket,
  question7Features as specializedProgramsBucket,
  question9Environment,
} from 'components/pages/scholamatch/schola-match-v5';

export const CATEGORY_LABELS = {
  type: 'type',
  subject: 'subject',
  learningPreferences: 'learningPreferences',
  extracurricular: 'extracurricular',
  specialNeeds: 'specialNeeds',
  languageNeeds: 'languageNeeds',
  specializedPrograms: 'specializedPrograms',
  environment: 'environment',
  distance: 'distance',
};

const categoriesLabel = {
  [CATEGORY_LABELS.type]: {
    a: '🏫 Public School',
    b: '💻 Private School',
    c: '📚 Public or Private',
    d: '🤝 Other',
    // e: '🏰 Boarding School',
    // f: '🤷‍♀️ Undecided',
  },
  [CATEGORY_LABELS.subject]: {
    a: '🔬 Math and Science',
    b: '🎨 Art & Music',
    c: '🌎 History & Social Studies',
    d: '✍️ Reading & Writing',
  },
  [CATEGORY_LABELS.learningPreferences]: {
    a: '👐 Doing things hands-on',
    b: '👥 Talking and working in groups',
    c: '🤔 Reading and working independently through problems',
    d: '📺 Watching videos and seeing things visually',
  },
  [CATEGORY_LABELS.extracurricular]: {
    a: '⚽️ Sports',
    b: '🎨 Arts & Crafts',
    c: '🎮 Technology or Gaming',
    d: '🏫 Academic-Focused Clubs',
    e: '🎭 Music or Drama',
    f: '👐 Helping in the Community',
    g: 'Undecided',
  },
  [CATEGORY_LABELS.specialNeeds]: {
    a: 'Specific Needs Support',
    b: 'Support Programs',
    c: 'Additional Offerings',
  },
  [CATEGORY_LABELS.languageNeeds]: {
    a: 'English Language Learning Support',
    b: 'Language Classes',
  },
  [CATEGORY_LABELS.specializedPrograms]: {
    a: 'Gifted Programs',
    b: 'Advanced Courses',
    c: 'JROTC',
  },
  [CATEGORY_LABELS.distance]: {
    a: '🚶‍♀️ Very Important',
    b: '🚗 Somewhat Important',
    c: '🚌 Not Very Important',
  },
  [CATEGORY_LABELS.environment]: {
    a: 'In Person',
    b: 'Online/Alternative',
    c: 'Blended Learning',
    d: 'Open to Explore',
  },
};

export const getSelectionsFeatures = ({ answers }) => {
  const featuresBucket = {
    labels: [],
    [CATEGORY_LABELS.type]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.environment]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.subject]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.learningPreferences]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.extracurricular]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.specialNeeds]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.languageNeeds]: { label: [], labelId: [], bucket: [] },
    [CATEGORY_LABELS.specializedPrograms]: { label: [], labelId: [], bucket: [] },
  };

  const mappedBuckets = {
    [CATEGORY_LABELS.type]: schoolTypeBucket,
    [CATEGORY_LABELS.subject]: subjectBucket,
    [CATEGORY_LABELS.learningPreferences]: learningPreferencesBucket,
    [CATEGORY_LABELS.extracurricular]: extracurricularActivitiesBucket,
    [CATEGORY_LABELS.specialNeeds]: specialNeedsBucket,
    [CATEGORY_LABELS.languageNeeds]: languageNeedsBucket,
    [CATEGORY_LABELS.specializedPrograms]: specializedProgramsBucket,
    [CATEGORY_LABELS.environment]: question9Environment,
  };

  if (answers) {
    Object.entries(answers)?.forEach(([key, value]) => {
      const category = categoriesLabel[key];

      if (category) {
        const values = value.split(',');
        values.map((item) => {
          const _item = item.trim();
          featuresBucket.labels.push(category[_item]);
          featuresBucket[key]?.bucket.push(...mappedBuckets[key][_item]);
          featuresBucket[key]?.label.push(category[_item]);
          featuresBucket[key]?.labelId.push({ label: category[_item], answer: _item });
        });
      }
    });

    const subjectSelection = subjectBucket[answers[CATEGORY_LABELS.subject]];
    const learningSelection = learningPreferencesBucket[answers[CATEGORY_LABELS.learningPreferences]];

    const selectedFeatures = new Set([
      ...subjectSelection,
      ...learningSelection,
      ...featuresBucket.extracurricular.bucket,
      ...featuresBucket.languageNeeds.bucket,
      ...featuresBucket.specialNeeds.bucket,
      ...featuresBucket.environment.bucket,
    ]);

    return {
      selectedFeatures,
      featuresBucket,
    };
  }
  return {
    selectedFeatures: new Set(),
    featuresBucket,
  };
};
