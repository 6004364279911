export const personalityMapping = {
  mastermind: {
    id: 'INTJ',
    caption:
      'Masterminds are strategic thinkers with a high need for competence and independence. They are imaginative yet decisive, ambitious, and prefer planning to spontaneous action.',
    subjects: ['Science', 'Technology', 'Mathematics', 'History', 'Philosophy', 'Economics'],
    approach:
      'Masterminds thrive in individualized and challenging learning environments that promote independence and personalized education. They benefit from smaller to medium class sizes (1-30 students), which allow for deeper exploration of topics and personalized feedback.',
    recommendations:
      'Independent Study, Challenging Lessons, Personalized Education, Montessori, Small to Medium Class Sizes (1-30 students)',
    extracurricular: ['Chess Club', 'Science Club', 'Debate Team', 'Robotics Club', 'Academic Decathlon'],
    skillsValues: [
      { skill: 'Imagination', value: 85 },
      { skill: 'Ideas', value: 55 },
      { skill: 'Thinking', value: 70 },
      { skill: 'Planning', value: 75 },
      { skill: 'Feelings', value: 45 },
      { skill: 'Caring', value: 50 },
      { skill: 'Remembering', value: 65 },
      { skill: 'Noticing', value: 60 },
    ],
    skillsHighlight: ['Imagination', 'Thinking', 'Planning', 'Remembering'],
    careers: ['Research Scientist', 'Engineer', 'Strategic Planner', 'Software Developer'],
    strengths: {
      Planning: 90,
      Independence: 85,
      'Decision Making': 80,
      Learning: 87,
      Ideation: 88,
    },
    weaknesses: {
      'Expressing Feelings': 65,
      'Adapting to Changes': 55,
      'Handling Uncertainty': 60,
      Sensitivity: 70,
      Impatience: 60,
    },
  },
  thinker: {
    id: 'INTP',
    caption:
      'Thinkers are analytical and abstract thinkers who enjoy theoretical and philosophical discussions. They are curious, independent, and enjoy exploring new ideas.',
    subjects: ['Science', 'Technology', 'Mathematics', 'Philosophy', 'History', 'Logic'],
    approach:
      'Thinkers excel with self-paced and exploratory learning, focusing on deep understanding and discovery. Small class sizes (1-15 students) are ideal for them as they allow for individual exploration and in-depth discussions.',
    recommendations:
      'Self-Paced Learning, Exploration, Learning by Discovery, Montessori, Small Class Sizes (1-15 students)',
    extracurricular: ['Math Club', 'Coding Club', 'Science Olympiad', 'Philosophy Club', 'Chess Club'],
    skillsValues: [
      { skill: 'Imagination', value: 80 },
      { skill: 'Ideas', value: 50 },
      { skill: 'Thinking', value: 75 },
      { skill: 'Planning', value: 70 },
      { skill: 'Feelings', value: 50 },
      { skill: 'Caring', value: 45 },
      { skill: 'Remembering', value: 60 },
      { skill: 'Noticing', value: 55 },
    ],
    skillsHighlight: ['Imagination', 'Thinking', 'Planning', 'Remembering'],
    careers: ['Philosopher', 'Theoretical Physicist', 'Programmer', 'Academic Researcher'],
    strengths: {
      Learning: 95,
      Inventive: 90,
      Independence: 85,
      Logical: 88,
      'Open-Mindedness': 87,
    },
    weaknesses: {
      'Everyday Tasks': 55,
      'Expressing Feelings': 65,
      'Finishing Tasks': 60,
      Procrastination: 70,
      'Being Social': 60,
    },
  },
  captain: {
    id: 'ENTJ',
    caption:
      'Captains are natural leaders who are assertive, strategic, and goal-oriented. They excel in organizing and executing plans and thrive in competitive environments.',
    subjects: ['Business', 'Economics', 'Leadership', 'Technology', 'History', 'Mathematics'],
    approach:
      'Captains succeed in goal-oriented and structured settings, focusing on achieving specific objectives and skill development. They perform best in medium to large class sizes (16-31+ students), which provide a dynamic environment for leadership and competition.',
    recommendations:
      'Goal-Oriented Learning, Structured Lessons, Skills-Based Learning, Medium to Large Class Sizes (16-31+ students)',
    extracurricular: ['Student Government', 'Debate Team', 'Business Club', 'Model UN', 'Mock Trial'],
    skillsValues: [
      { skill: 'Imagination', value: 45 },
      { skill: 'Ideas', value: 75 },
      { skill: 'Thinking', value: 55 },
      { skill: 'Planning', value: 85 },
      { skill: 'Feelings', value: 55 },
      { skill: 'Caring', value: 60 },
      { skill: 'Remembering', value: 55 },
      { skill: 'Noticing', value: 60 },
    ],
    skillsHighlight: ['Ideas', 'Planning', 'Caring', 'Noticing'],
    careers: ['Executive', 'Entrepreneur', 'Lawyer', 'Project Manager'],
    strengths: {
      Leading: 95,
      Planning: 90,
      Efficient: 85,
      'Decision Making': 87,
      'Problem Solving': 88,
    },
    weaknesses: {
      'Expressing Feelings': 65,
      'Adapting to Changes': 55,
      Patience: 60,
      Listening: 70,
      'Work-Life Balance': 60,
    },
  },
  inventor: {
    id: 'ENTP',
    caption:
      'Inventors are innovative and enthusiastic problem-solvers who enjoy debating and generating new ideas. They are curious, adaptable, and enjoy intellectual challenges.',
    subjects: ['Debate', 'Entrepreneurship', 'Technology', 'Science', 'History', 'Philosophy'],
    approach:
      'Inventors flourish in debate-based and interactive lessons, engaging in problem-solving activities. Medium class sizes (16-30 students) are ideal as they offer enough diversity of thought and interaction to stimulate their creativity and debate skills.',
    recommendations: 'Debate-Based Learning, Interactive Lessons, Problem-Solving, Medium Class Sizes (16-30 students)',
    extracurricular: ['Debate Team', 'Entrepreneurship Club', 'Drama Club', 'Science Club', 'Model UN'],
    skillsValues: [
      { skill: 'Imagination', value: 60 },
      { skill: 'Ideas', value: 80 },
      { skill: 'Thinking', value: 50 },
      { skill: 'Planning', value: 80 },
      { skill: 'Feelings', value: 60 },
      { skill: 'Caring', value: 55 },
      { skill: 'Remembering', value: 50 },
      { skill: 'Noticing', value: 65 },
    ],
    skillsHighlight: ['Ideas', 'Planning', 'Noticing', 'Thinking'],
    careers: ['Entrepreneur', 'Lawyer', 'Market Analyst', 'Inventor'],
    strengths: {
      Inventive: 95,
      Communicative: 85,
      Planning: 80,
      Enthusiastic: 87,
      'Adapting to Changes': 88,
    },
    weaknesses: {
      'Staying Focused': 60,
      'Everyday Tasks': 65,
      'Keeping Routine': 60,
      'Finishing Tasks': 70,
      'Handling Criticism': 60,
    },
  },
  idealist: {
    id: 'INFJ',
    caption:
      'Idealists are empathetic and visionary individuals who seek to make a positive impact. They are intuitive, compassionate, and dedicated to helping others.',
    subjects: ['Literature', 'Psychology', 'Art', 'Social Studies', 'Philosophy', 'Creative Writing'],
    approach:
      'Idealists excel with meaningful projects and service learning in a caring environment. They benefit most from small class sizes (1-15 students), which allow for personal connections and in-depth discussions.',
    recommendations:
      'Projects that Matter, Service Learning, Caring Learning Environment, Montessori, Small Class Sizes (1-15 students)',
    extracurricular: ['Community Service Club', 'Writing Club', 'Art Club', 'Environmental Club', 'Peer Counseling'],
    skillsValues: [
      { skill: 'Imagination', value: 70 },
      { skill: 'Ideas', value: 60 },
      { skill: 'Thinking', value: 45 },
      { skill: 'Planning', value: 65 },
      { skill: 'Feelings', value: 85 },
      { skill: 'Caring', value: 75 },
      { skill: 'Remembering', value: 75 },
      { skill: 'Noticing', value: 75 },
    ],
    skillsHighlight: ['Imagination', 'Feelings', 'Caring', 'Noticing'],
    careers: ['Counselor', 'Psychologist', 'Writer', 'Human Resources Manager'],
    strengths: {
      Caring: 90,
      Insightful: 85,
      Dedicated: 80,
      Creative: 87,
      'Good Listener': 88,
    },
    weaknesses: {
      'Everyday Tasks': 55,
      'Handling Stress': 65,
      'Decision Making': 60,
      'Setting Boundaries': 70,
      'Avoiding Conflict': 60,
    },
  },
  dreamer: {
    id: 'INFP',
    caption:
      'Dreamers are idealistic and creative individuals who are deeply in touch with their values. They are empathetic, open-minded, and driven by a desire for authenticity.',
    subjects: ['Literature', 'Art', 'Music', 'History', 'Creative Writing', 'Philosophy'],
    approach:
      'Dreamers thrive in creative projects and nurturing environments that develop multiple skills. Small class sizes (1-15 students) enable deep connections and thorough exploration of ideas, fostering their creativity and individual growth.',
    recommendations:
      'Creative Projects, Nurturing Environment, Multiple Skills Model, Montessori, Small Class Sizes (1-15 students)',
    extracurricular: ['Creative Writing Club', 'Art Club', 'Drama Club', 'Music Club', 'Philosophy Club'],
    skillsValues: [
      { skill: 'Imagination', value: 65 },
      { skill: 'Ideas', value: 45 },
      { skill: 'Thinking', value: 65 },
      { skill: 'Planning', value: 60 },
      { skill: 'Feelings', value: 80 },
      { skill: 'Caring', value: 80 },
      { skill: 'Remembering', value: 70 },
      { skill: 'Noticing', value: 70 },
    ],
    skillsHighlight: ['Feelings', 'Caring', 'Imagination', 'Remembering'],
    careers: ['Writer', 'Artist', 'Counselor', 'Librarian'],
    strengths: {
      Creative: 90,
      Idealistic: 85,
      Caring: 80,
      'Open-Mindedness': 87,
      'Adapting to Changes': 88,
    },
    weaknesses: {
      'Keeping Routine': 65,
      'Realistic Thinking': 60,
      'Avoiding Conflict': 60,
      Vulnerability: 70,
      'Staying Focused': 60,
    },
  },
  mentor: {
    id: 'ENFJ',
    caption:
      'Mentors are charismatic and empathetic leaders who inspire and guide others. They are socially adept, organized, and driven by a desire to help and improve communities.',
    subjects: ['Social Studies', 'Drama', 'Literature', 'History', 'Psychology', 'Leadership'],
    approach:
      'Mentors thrive with group learning and a whole-child approach, excelling in cooperative environments. Medium class sizes (16-30 students) are ideal, allowing for group activities and shared experiences.',
    recommendations: 'Group Learning, Whole Child, Learning Together Model, Medium Class Sizes (16-30 students)',
    extracurricular: ['Student Government', 'Drama Club', 'Peer Mentoring', 'Public Speaking Club', 'Volunteer Groups'],
    skillsValues: [
      { skill: 'Imagination', value: 55 },
      { skill: 'Ideas', value: 70 },
      { skill: 'Thinking', value: 70 },
      { skill: 'Planning', value: 55 },
      { skill: 'Feelings', value: 65 },
      { skill: 'Caring', value: 85 },
      { skill: 'Remembering', value: 85 },
      { skill: 'Noticing', value: 85 },
    ],
    skillsHighlight: ['Remembering', 'Imagination', 'Noticing', 'Ideas'],
    careers: ['Teacher', 'Counselor', 'Human Resources Specialist', 'Public Relations Specialist'],
    strengths: {
      Communicative: 90,
      Leading: 85,
      Caring: 80,
      Organized: 87,
      Charismatic: 88,
    },
    weaknesses: {
      'Everyday Tasks': 55,
      'Being Fair': 65,
      'Critical Thinking': 60,
      'Self-Neglect': 70,
      'Handling Criticism': 60,
    },
  },
  explorer: {
    id: 'ENFP',
    caption:
      'Explorers are enthusiastic and creative individuals who are driven by a sense of possibility. They are sociable, spontaneous, and enjoy exploring new ideas and experiences.',
    subjects: ['Drama', 'Creative Writing', 'Music', 'Social Studies', 'Art', 'Philosophy'],
    approach:
      'Explorers flourish in dynamic experiences and creative projects, benefiting from a multiple skills model. Medium class sizes (16-30 students) provide the diversity and interaction needed to keep them engaged and motivated.',
    recommendations:
      'Creative Projects, Dynamic Experiences, Multiple Skills Model, Medium Class Sizes (16-30 students)',
    extracurricular: ['Drama Club', 'Creative Writing Club', 'Environmental Club', 'Music Club', 'Debate Team'],
    skillsValues: [
      { skill: 'Imagination', value: 60 },
      { skill: 'Ideas', value: 75 },
      { skill: 'Thinking', value: 45 },
      { skill: 'Planning', value: 50 },
      { skill: 'Feelings', value: 70 },
      { skill: 'Caring', value: 70 },
      { skill: 'Remembering', value: 80 },
      { skill: 'Noticing', value: 70 },
    ],
    skillsHighlight: ['Ideas', 'Caring', 'Remembering', 'Noticing'],
    careers: ['Journalist', 'Counselor', 'Teacher', 'Actor'],
    strengths: {
      Enthusiastic: 90,
      Creative: 85,
      Caring: 80,
      Social: 87,
      'Adapting to Changes': 88,
    },
    weaknesses: {
      'Staying Focused': 65,
      'Keeping Routine': 60,
      'Keeping Routine': 60,
      Overthinking: 70,
      'Expressing Feelings': 60,
    },
  },
  organizer: {
    id: 'ISTJ',
    caption:
      'Organizers are reliable and methodical individuals who value tradition and order. They are practical, detail-oriented, and prefer structured environments.',
    subjects: ['Business', 'Mathematics', 'Science', 'History', 'Law', 'Political Science'],
    approach:
      'Organizers benefit from structured play and clear lessons in knowledge-based settings. Medium to large class sizes (16-31+ students) are ideal, providing a structured environment with clear guidelines.',
    recommendations: 'Structured Play, Clear Lessons, Knowledge-Based, Medium to Large Class Sizes (16-31+ students)',
    extracurricular: ['Academic Decathlon', 'History Club', 'Business Club', 'JROTC', 'Debate Team'],
    skillsValues: [
      { skill: 'Imagination', value: 50 },
      { skill: 'Ideas', value: 35 },
      { skill: 'Thinking', value: 60 },
      { skill: 'Planning', value: 70 },
      { skill: 'Feelings', value: 60 },
      { skill: 'Caring', value: 75 },
      { skill: 'Remembering', value: 80 },
      { skill: 'Noticing', value: 75 },
    ],
    skillsHighlight: ['Planning', 'Caring', 'Remembering', 'Noticing'],
    careers: ['Accountant', 'Auditor', 'Judge', 'Lawyer'],
    strengths: {
      Reliable: 95,
      Organized: 90,
      Practical: 85,
      'Detail-Oriented': 87,
      Hardworking: 88,
    },
    weaknesses: {
      'Adapting to Changes': 60,
      'Expressing Feelings': 65,
      Flexibility: 55,
      'Avoiding Risks': 70,
      'Being Social': 60,
    },
  },
  advocate: {
    id: 'ISFJ',
    caption:
      'Advocates are compassionate and dependable individuals who seek to help others. They are detail-oriented, loyal, and enjoy creating harmonious environments.',
    subjects: ['Biology', 'History', 'Literature', 'Education', 'Psychology', 'Health Education'],
    approach:
      'Caregivers excel with small-group learning and a whole-child approach in caring environments. Small to medium class sizes (1-30 students) provide personalized attention and support, which they thrive on.',
    recommendations:
      'Small-Group Learning, Whole Child, Caring Learning Environment, Small to Medium Class Sizes (1-30 students)',
    extracurricular: ['Community Service Club', 'Nursing/Health Club', 'Art Club', 'Book Club', 'Peer Counseling'],
    skillsValues: [
      { skill: 'Imagination', value: 40 },
      { skill: 'Ideas', value: 50 },
      { skill: 'Thinking', value: 65 },
      { skill: 'Planning', value: 75 },
      { skill: 'Feelings', value: 65 },
      { skill: 'Caring', value: 80 },
      { skill: 'Remembering', value: 75 },
      { skill: 'Noticing', value: 70 },
    ],
    skillsHighlight: ['Planning', 'Caring', 'Remembering', 'Noticing'],
    careers: ['Nurse', 'Teacher', 'Counselor', 'Librarian'],
    strengths: {
      Hardworking: 95,
      Caring: 90,
      Practical: 85,
      Organized: 87,
      Patient: 88,
    },
    weaknesses: {
      'Adapting to Changes': 60,
      'Adapting to Changes': 65,
      Assertiveness: 55,
      'Handling Criticism': 70,
      'Self-Care': 60,
    },
  },
  leader: {
    id: 'ESTJ',
    caption:
      'Leaders are practical and organized leaders who value efficiency and order. They are assertive, goal-oriented, and excel in managing and organizing projects.',
    subjects: ['Business', 'Law', 'Physical Education', 'Mathematics', 'History', 'Economics'],
    approach:
      'Leaders thrive with lecture-based and clear lessons in direct teaching settings. Medium to large class sizes (16-31+ students) offer a structured environment with clear objectives, suitable for their learning style.',
    recommendations:
      'Lecture-Based Learning, Clear Lessons, Direct Teaching, Medium to Large Class Sizes (16-31+ students)',
    extracurricular: ['Student Government', 'Business Club', 'Debate Team', 'Sports Teams', 'Law Club'],
    skillsValues: [
      { skill: 'Imagination', value: 35 },
      { skill: 'Ideas', value: 45 },
      { skill: 'Thinking', value: 55 },
      { skill: 'Planning', value: 80 },
      { skill: 'Feelings', value: 70 },
      { skill: 'Caring', value: 85 },
      { skill: 'Remembering', value: 70 },
      { skill: 'Noticing', value: 65 },
    ],
    skillsHighlight: ['Planning', 'Caring', 'Feelings', 'Remembering'],
    careers: ['Manager', 'Lawyer', 'Military Officer', 'Judge'],
    strengths: {
      Leading: 90,
      Organized: 85,
      Practical: 80,
      Efficient: 87,
      Reliable: 88,
    },
    weaknesses: {
      'Expressing Feelings': 65,
      'Adapting to Changes': 60,
      Creativity: 55,
      Tolerance: 70,
      'Adapting to Changes': 60,
    },
  },
  supporter: {
    id: 'ESFJ',
    caption:
      'Supporters are warm and social individuals who thrive in environments where they can help others. They are organized, loyal, and excel in creating harmonious relationships.',
    subjects: ['Social Studies', 'Literature', 'Psychology', 'History', 'Education', 'Health Education'],
    approach:
      'Supporters excel with group learning and a whole-child approach, thriving in cooperative environments. Medium class sizes (16-30 students) provide the interaction and diversity needed to keep them engaged.',
    recommendations: 'Group Learning, Whole Child, Learning Together Model, Medium Class Sizes (16-30 students)',
    extracurricular: [
      'Community Service Club',
      'Drama Club',
      'Peer Mentoring',
      'Volunteer Groups',
      'Student Government',
    ],
    skillsValues: [
      { skill: 'Imagination', value: 55 },
      { skill: 'Ideas', value: 65 },
      { skill: 'Thinking', value: 60 },
      { skill: 'Planning', value: 85 },
      { skill: 'Feelings', value: 75 },
      { skill: 'Caring', value: 70 },
      { skill: 'Remembering', value: 65 },
      { skill: 'Noticing', value: 60 },
    ],
    skillsHighlight: ['Planning', 'Feelings', 'Caring', 'Ideas'],
    careers: ['Nurse', 'Teacher', 'Event Planner', 'Public Relations Specialist'],
    strengths: {
      Communicative: 90,
      Caring: 85,
      Hardworking: 80,
      Social: 87,
      Practical: 88,
    },
    weaknesses: {
      'Adapting to Changes': 60,
      'Adapting to Changes': 65,
      'Critical Thinking': 55,
      'Self-Worth': 70,
      Vulnerability: 60,
    },
  },
  maker: {
    id: 'ISTP',
    caption:
      'Makers are practical and observant individuals who enjoy hands-on activities. They are independent, adaptable, and excel in problem-solving and working with tools.',
    subjects: ['Physical Education', 'Shop Class', 'Engineering', 'Technology', 'Science', 'Mathematics'],
    approach:
      'Makers thrive with hands-on learning and blended environments that emphasize practical application. Small to medium class sizes (1-30 students) allow for more hands-on activities and personalized instruction.',
    recommendations:
      'Hands-On Learning, Blended Learning, Learning by Doing, Montessori, Small to Medium Class Sizes (1-30 students)',
    extracurricular: ['Robotics Club', 'Sports Teams', 'Auto Club', 'Outdoor Adventure Club', 'Tech Club'],
    skillsValues: [
      { skill: 'Imagination', value: 65 },
      { skill: 'Ideas', value: 70 },
      { skill: 'Thinking', value: 40 },
      { skill: 'Planning', value: 55 },
      { skill: 'Feelings', value: 80 },
      { skill: 'Caring', value: 75 },
      { skill: 'Remembering', value: 60 },
      { skill: 'Noticing', value: 55 },
    ],
    skillsHighlight: ['Imagination', 'Ideas', 'Feelings', 'Caring'],
    careers: ['Engineer', 'Mechanic', 'Pilot', 'Carpenter'],
    strengths: {
      'Problem Solving': 90,
      'Adapting to Changes': 85,
      Independence: 80,
      'Practical Skills': 87,
      'Risk-Taking': 88,
    },
    weaknesses: {
      'Expressing Feelings': 65,
      'Long-Term Planning': 60,
      Patience: 55,
      Sensitivity: 70,
      Commitment: 60,
    },
  },
  performer: {
    id: 'ISFP',
    caption:
      'Performers are sensitive and artistic individuals who value personal expression and creativity. They are gentle, adaptable, and prefer environments that allow for artistic freedom.',
    subjects: ['Art', 'Music', 'Drama', 'Literature', 'Psychology', 'Creative Writing'],
    approach:
      'Performers thrive with creative expression in nurturing and caring environments that foster personal growth. Small class sizes (1-15 students) enable deep connections and thorough exploration of ideas, ideal for their learning style.',
    recommendations:
      'Creative Expression, Nurturing Environment, Caring Learning Environment, Montessori, Small Class Sizes (1-15 students)',
    extracurricular: ['Art Club', 'Music Club', 'Drama Club', 'Photography Club', 'Dance Club'],
    skillsValues: [
      { skill: 'Imagination', value: 60 },
      { skill: 'Ideas', value: 75 },
      { skill: 'Thinking', value: 45 },
      { skill: 'Planning', value: 60 },
      { skill: 'Feelings', value: 85 },
      { skill: 'Caring', value: 80 },
      { skill: 'Remembering', value: 55 },
      { skill: 'Noticing', value: 45 },
    ],
    skillsHighlight: ['Ideas', 'Feelings', 'Caring', 'Imagination'],
    careers: ['Artist', 'Musician', 'Chef', 'Fashion Designer'],
    strengths: {
      Creative: 90,
      Caring: 85,
      'Adapting to Changes': 80,
      'Detail-Oriented': 87,
      Independence: 88,
    },
    weaknesses: {
      'Keeping Routine': 65,
      'Decision Making': 60,
      'Planning Ahead': 55,
      'Avoiding Conflict': 70,
      'Keeping Routine': 60,
    },
  },
  visionary: {
    id: 'ESTP',
    caption:
      'Visionaries are energetic and action-oriented individuals who enjoy challenges and excitement. They are practical, spontaneous, and excel in dynamic environments.',
    subjects: ['Physical Education', 'Business', 'Drama', 'Marketing', 'Law', 'Political Science'],
    approach:
      'Visionaries benefit from active learning and flipped classrooms, excelling in problem-solving models. Medium to large class sizes (16-31+ students) provide a dynamic environment with enough diversity to keep them engaged and motivated.',
    recommendations:
      'Active Learning, Flipped Classroom, Problem-Solving, Medium to Large Class Sizes (16-31+ students)',
    extracurricular: ['Art Club', 'Music Club', 'Drama Club', 'Photography Club', 'Dance Club'],
    skillsValues: [
      { skill: 'Imagination', value: 50 },
      { skill: 'Ideas', value: 40 },
      { skill: 'Thinking', value: 75 },
      { skill: 'Planning', value: 45 },
      { skill: 'Feelings', value: 65 },
      { skill: 'Caring', value: 55 },
      { skill: 'Remembering', value: 70 },
      { skill: 'Noticing', value: 55 },
    ],
    skillsHighlight: ['Thinking', 'Remembering', 'Feelings', 'Imagination'],
    careers: ['Entrepreneur', 'Police Officer', 'Stockbroker', 'Athlete'],
    strengths: {
      'Adapting to Changes': 90,
      'Problem Solving': 85,
      Communicative: 80,
      'Risk-Taking': 87,
      'High Energy': 88,
    },
    weaknesses: {
      'Long-Term Planning': 65,
      'Expressing Feelings': 60,
      'Keeping Routine': 55,
      Reflectiveness: 70,
      Patience: 60,
    },
  },
  enterteiner: {
    id: 'ESFP',
    caption:
      'Enterteiners are outgoing and spontaneous individuals who love being the center of attention. They are fun-loving, energetic, and excel in environments that allow for social interaction and creativity.',
    subjects: ['Drama', 'Music', 'Physical Education', 'Art', 'Sociology', 'Psychology'],
    approach:
      'Enterteiners flourish with role-playing and dynamic experiences, thriving in environments that allow for social interaction and creativity. Medium class sizes (16-30 students) provide enough diversity and interaction to keep them engaged and motivated.',
    recommendations: 'Role-Playing, Dynamic Experiences, Multiple Skills Model, Medium Class Sizes (16-30 students)',
    extracurricular: ['Drama Club', 'Music Club', 'Cheerleading', 'Dance Team', 'Student Government'],
    skillsValues: [
      { skill: 'Imagination', value: 55 },
      { skill: 'Ideas', value: 45 },
      { skill: 'Thinking', value: 80 },
      { skill: 'Planning', value: 50 },
      { skill: 'Feelings', value: 70 },
      { skill: 'Caring', value: 60 },
      { skill: 'Remembering', value: 75 },
      { skill: 'Noticing', value: 60 },
    ],
    skillsHighlight: ['Thinking', 'Remembering', 'Feelings', 'Imagination'],
    careers: ['Actor', 'Musician', 'Sales Representative', 'Flight Attendant'],
    strengths: {
      Enthusiastic: 90,
      Communicative: 85,
      'Adapting to Changes': 80,
      Social: 87,
      Positive: 88,
    },
    weaknesses: {
      'Keeping Routine': 65,
      'Long-Term Planning': 60,
      'Practical Thinking': 55,
      'Staying Focused': 70,
      'Handling Stress': 60,
    },
  },
};

export const skillsDefinitions = (student) => [
  {
    name: 'Imagination',
    value: `${student} can dream up big, exciting stories and think about fun possibilities for the future.`,
  },
  { name: 'Thinking', value: 'Loves solving puzzles and figuring out how things work.' },
  { name: 'Planning', value: 'Great at organizing tasks and making sure everything gets done.' },
  { name: 'Remembering', value: 'Has a good memory and can recall important details from past experiences.' },
  { name: 'Ideas', value: `${student} can think of lots of different possibilities and new ways to do things.` },
  { name: 'Caring', value: 'Good at understanding and caring for others.' },
  { name: 'Noticing', value: 'Getting better at noticing things around them and being aware of their surroundings.' },
  { name: 'Feelings', value: 'Excellent at understanding and expressing their own feelings.' },
];

export const edApproaches = {
  Montessori:
    'Children learn at their own pace in a structured environment, promoting independence and self-directed learning.',
  'Constructivist Approach':
    'Kids learn by exploring and building knowledge through hands-on activities and problem-solving.',
  Traditional: 'A structured, teacher-led approach with clear goals and a focus on discipline and performance.',
  'Reggio Emilia Approach':
    'Encourages creativity and exploration through art and hands-on activities in a child-centered environment.',
  'Social and Emotional Learning (SEL)':
    'Helps children develop emotional skills and positive relationships through understanding and managing emotions.',
  'Whole Child': 'Focuses on overall growth—academically, socially, and emotionally—ensuring balanced development.',
  'Hands-On Learning': 'Children learn by doing practical tasks, making lessons engaging and memorable.',
  Waldorf: "Uses arts and creativity to develop a child's imagination and holistic growth.",
};

export const learningModels = {
  'Personalized Education': "Customized learning based on each student's individual goals and needs.",
  'Problem-Based Learning (PBL)':
    'Students solve real-world problems to develop critical thinking and problem-solving skills.',
  'Direct Instruction': 'Teacher-led lessons with clear objectives and step-by-step guidance.',
  'Humanistic Model': 'Focuses on personal growth, emotional well-being, and human potential.',
  'Collaborative Learning Model': 'Learning through teamwork and group activities to build cooperation skills.',
  'Social Learning Model': 'Learning by observing and interacting with peers and others.',
  'Universal Design for Learning (UDL)': 'Adapts to diverse learning needs to ensure all students can succeed.',
  'Experiential Learning': 'Learning by doing, through direct, hands-on experiences.',
  'Multiple Intelligences Model': 'Encourages the development of various types of intelligence in students.',
};

export const learningMethods = {
  'Independent Study': 'Self-driven learning where students take charge of their education.',
  'Self-Paced Learning': 'Students learn at their own speed, adjusting as needed.',
  'Goal-Oriented Learning': 'Focused on achieving clear and specific learning objectives.',
  'Debate-Based Learning': 'Develops critical thinking through structured debates.',
  'Meaningful Projects': 'Learning through projects that have real-world relevance and purpose.',
  'Creative Projects': 'Learning by engaging in creative, artistic tasks and activities.',
  'Cooperative Learning': 'Group learning where students work together to solve problems.',
  'Dynamic Experiences': 'Learning through varied, active, and engaging experiences.',
  'Rigorous Instruction':
    'Structured learning that encourages focus and helps students meet challenging but achievable goals.',
  'Hands-On Learning': 'Learning by doing, with practical, real-world applications.',
  'Creative Expression': 'Learning through artistic and expressive forms of communication.',
  'Active Learning': 'Engaging, hands-on learning where students actively participate.',
  'Interactive Learning': 'Social and hands-on learning through engagement and participation.',
};

export const classSizesDescriptions = {
  'Small to medium (1-30)':
    'Class sizes that allow for more individual attention and personalized feedback from teachers.',
};

export const learningApproachByPersonality = {
  mastermind: {
    EducationalApproach: 'Montessori',
    LearningModel: 'Personalized Education',
    LearningMethod: 'Independent Study',
    ClassSize: 'Small to medium (1-30)',
    'Additional Educational Approaches': 'Constructivist Approach, Socratic Method, Blended Learning',
    'Additional Learning Models': 'Competency-Based Learning, UDL, Direct Instruction',
    'Additional Learning Methods': 'Hands-On Learning, Meaningful Projects, Self-Paced Learning',
  },
  thinker: {
    EducationalApproach: 'Constructivist Approach',
    LearningModel: 'Problem-Based Learning (PBL)',
    LearningMethod: 'Self-Paced Learning',
    ClassSize: 'Small (1-15)',
    'Additional Educational Approaches': 'Montessori, Flipped Classroom, Socratic Method',
    'Additional Learning Models': 'Experiential Learning, Constructivist Model, Cognitivist Model',
    'Additional Learning Methods': 'Independent Study, Hands-On Activities, Creative Thinking',
  },
  captain: {
    EducationalApproach: 'Traditional',
    LearningModel: 'Direct Instruction',
    LearningMethod: 'Goal-Oriented Learning',
    ClassSize: 'Medium to large (16-31+)',
    'Additional Educational Approaches': 'Whole Child, Socratic Method, Montessori',
    'Additional Learning Models': 'Collaborative Learning Model, Proficiency-Based Teaching, UDL',
    'Additional Learning Methods': 'Role-Playing, Ability Grouping, Real World Projects',
  },
  inventor: {
    EducationalApproach: 'Reggio Emilia Approach',
    LearningModel: 'Problem-Based Learning (PBL)',
    LearningMethod: 'Debate-Based Learning',
    ClassSize: 'Medium (16-30)',
    'Additional Educational Approaches': 'Montessori, Blended Learning, Whole Child',
    'Additional Learning Models': 'Collaborative Learning Model, Personalized Learning, Competency-Based Learning',
    'Additional Learning Methods': 'Dynamic Experiences, Creative Expression, Interactive Learning',
  },
  idealist: {
    EducationalApproach: 'Social and Emotional Learning (SEL)',
    LearningModel: 'Humanistic Model',
    LearningMethod: 'Meaningful Projects',
    ClassSize: 'Small (1-15)',
    'Additional Educational Approaches': 'Constructivist Approach, Whole Child, Trauma-Informed Approach',
    'Additional Learning Models': 'Collaborative Learning Model, Problem-Based Learning, UDL',
    'Additional Learning Methods': 'Independent Study, Cooperative Learning, Active Learning',
  },
  dreamer: {
    EducationalApproach: 'Whole Child',
    LearningModel: 'Humanistic Model',
    LearningMethod: 'Creative Projects',
    ClassSize: 'Small (1-15)',
    'Additional Educational Approaches': 'Montessori, Reggio Emilia Approach, Constructivist Approach',
    'Additional Learning Models': 'Social Learning Model, Multiple Intelligences Model, Personalized Learning',
    'Additional Learning Methods': 'Independent Study, Creative Thinking, Cooperative Learning',
  },
  mentor: {
    EducationalApproach: 'Social and Emotional Learning (SEL)',
    LearningModel: 'Collaborative Learning Model',
    LearningMethod: 'Cooperative Learning',
    ClassSize: 'Medium (16-30)',
    'Additional Educational Approaches': 'Whole Child, Reggio Emilia Approach, Montessori',
    'Additional Learning Models': 'Problem-Based Learning, Multiple Intelligences Model, Competency-Based Learning',
    'Additional Learning Methods': 'Role-Playing, Dynamic Experiences, Interactive Learning',
  },
  explorer: {
    EducationalApproach: 'Reggio Emilia Approach',
    LearningModel: 'Social Learning Model',
    LearningMethod: 'Dynamic Experiences',
    ClassSize: 'Medium (16-30)',
    'Additional Educational Approaches': 'Montessori, Whole Child, Blended Learning',
    'Additional Learning Models': 'Experiential Learning, Problem-Based Learning, Collaborative Learning Model',
    'Additional Learning Methods': 'Creative Projects, Meaningful Projects, Active Learning',
  },
  organizer: {
    EducationalApproach: 'Traditional',
    LearningModel: 'Direct Instruction',
    LearningMethod: 'Rigorous Instruction',
    ClassSize: 'Medium to large (16-31+)',
    'Additional Educational Approaches': 'Montessori, Constructivist Approach, Whole Child',
    'Additional Learning Models': 'Core Knowledge Curriculum, Proficiency-Based Teaching, Competency-Based Learning',
    'Additional Learning Methods': 'Drill and Practice, Goal-Oriented Learning, Ability Grouping',
  },
  advocate: {
    EducationalApproach: 'Whole Child',
    LearningModel: 'Universal Design for Learning (UDL)',
    LearningMethod: 'Cooperative Learning',
    ClassSize: 'Small to medium (1-30)',
    'Additional Educational Approaches': 'Social and Emotional Learning (SEL), Montessori, Constructivist Approach',
    'Additional Learning Models': 'Competency-Based Learning, Collaborative Learning Model, Differentiated Instruction',
    'Additional Learning Methods': 'Hands-On Activities, Ability Grouping, Real World Projects',
  },
  leader: {
    EducationalApproach: 'Traditional',
    LearningModel: 'Direct Instruction',
    LearningMethod: 'Goal-Oriented Learning',
    ClassSize: 'Medium to large (16-31+)',
    'Additional Educational Approaches': 'Montessori, Whole Child, Reggio Emilia Approach',
    'Additional Learning Models': 'Proficiency-Based Teaching, Core Knowledge Curriculum, Collaborative Learning Model',
    'Additional Learning Methods': 'Role-Playing, Real World Projects, Drill and Practice',
  },
  supporter: {
    EducationalApproach: 'Whole Child',
    LearningModel: 'Social Learning Model',
    LearningMethod: 'Cooperative Learning',
    ClassSize: 'Medium (16-30)',
    'Additional Educational Approaches': 'Social and Emotional Learning (SEL), Constructivist Approach, Montessori',
    'Additional Learning Models':
      'Universal Design for Learning (UDL), Differentiated Instruction, Multiple Intelligences Model',
    'Additional Learning Methods': 'Hands-On Activities, Role-Playing, Interactive Learning',
  },
  maker: {
    EducationalApproach: 'Hands-On Learning',
    LearningModel: 'Experiential Learning',
    LearningMethod: 'Hands-On Learning',
    ClassSize: 'Small to medium (1-30)',
    'Additional Educational Approaches': 'Montessori, Waldorf, Constructivist Approach',
    'Additional Learning Models': 'Competency-Based Learning, Collaborative Learning Model, Problem-Based Learning',
    'Additional Learning Methods': 'Real World Projects, Independent Study, Self-Paced Learning',
  },
  performer: {
    EducationalApproach: 'Waldorf',
    LearningModel: 'Multiple Intelligences Model',
    LearningMethod: 'Creative Expression',
    ClassSize: 'Small (1-15)',
    'Additional Educational Approaches': 'Reggio Emilia Approach, Montessori, Whole Child',
    'Additional Learning Models': 'Social Learning Model, Collaborative Learning Model, Universal Design for Learning',
    'Additional Learning Methods': 'Creative Projects, Hands-On Activities, Meaningful Projects',
  },
  visionary: {
    EducationalApproach: 'Traditional',
    LearningModel: 'Direct Instruction',
    LearningMethod: 'Active Learning',
    ClassSize: 'Medium to large (16-31+)',
    'Additional Educational Approaches': 'Blended Learning, Whole Child, Social and Emotional Learning',
    'Additional Learning Models': 'Proficiency-Based Teaching, Core Knowledge Curriculum, Problem-Based Learning',
    'Additional Learning Methods': 'Role-Playing, Real World Projects, Interactive Learning',
  },
  enterteiner: {
    EducationalApproach: 'Reggio Emilia Approach',
    LearningModel: 'Social Learning Model',
    LearningMethod: 'Interactive Learning',
    ClassSize: 'Medium (16-30)',
    'Additional Educational Approaches': 'Montessori, Whole Child, Blended Learning',
    'Additional Learning Models': 'Collaborative Learning Model, Personalized Learning, Universal Design for Learning',
    'Additional Learning Methods': 'Creative Expression, Dynamic Experiences, Cooperative Learning',
  },
};

export const careerPaths = [
  { name: 'Teacher', value: 'Educates and mentors students, fostering learning and personal development.' },
  { name: 'Counselor', value: 'Provides emotional and psychological support to help individuals navigate challenges.' },
  {
    name: 'Human Resources Specialist',
    value: 'Manages hiring, training, and employee well-being in an organization.',
  },
  {
    name: 'Public Relations Specialist',
    value: 'Manages communication between an organization and the public, promoting positive relations.',
  },
  {
    name: 'Journalist',
    value: 'Researches and writes stories, reports, or articles to inform the public on various issues.',
  },
  {
    name: 'Actor',
    value: 'Performs in theater, film, or television, bringing characters to life through performance.',
  },
  {
    name: 'Executive',
    value: 'Leads and manages an organization, making high-level decisions to achieve goals and grow the business.',
  },
  {
    name: 'Entrepreneur',
    value: 'Starts and manages a business, taking risks to innovate and solve problems in the market.',
  },
  {
    name: 'Lawyer',
    value: 'Represents clients in legal matters, providing strategic advice and defending cases in court.',
  },
  {
    name: 'Project Manager',
    value: 'Oversees projects from planning to completion, ensuring goals are met on time and within budget.',
  },
  {
    name: 'Market Analyst',
    value: 'Studies market trends and consumer behavior to help companies make informed business decisions.',
  },
  {
    name: 'Inventor',
    value: 'Develops new products or technologies, solving problems through creativity and innovation.',
  },
  { name: 'Nurse', value: 'Cares for patients, ensuring they receive the medical and emotional support they need.' },
  {
    name: 'Event Planner',
    value: 'Organizes events, ensuring all details are managed smoothly and attendees are satisfied.',
  },
  {
    name: 'Musician',
    value: 'Creates or performs music, expressing emotions and connecting with audiences through sound.',
  },
  {
    name: 'Sales Representative',
    value: 'Sells products or services, engaging with customers to meet their needs and close deals.',
  },
  {
    name: 'Flight Attendant',
    value: 'Ensures passenger safety and comfort on flights, providing customer service during travel.',
  },
  {
    name: 'Manager',
    value: 'Oversees teams or departments to ensure efficient operations and achieve business goals.',
  },
  {
    name: 'Military Officer',
    value: 'Leads military personnel and operations, maintaining discipline and achieving strategic goals.',
  },
  { name: 'Judge', value: 'Decides legal cases, ensuring the law is applied fairly and consistently.' },
  {
    name: 'Police Officer',
    value: 'Enforces the law, ensuring public safety by responding to incidents and solving crimes.',
  },
  {
    name: 'Stockbroker',
    value: 'Buys and sells financial products for clients, often working in high-pressure environments.',
  },
  { name: 'Athlete', value: 'Competes in sports, focusing on achieving physical excellence and performance.' },
  {
    name: 'Psychologist',
    value: 'Studies human behavior and helps individuals improve mental health and emotional well-being.',
  },
  {
    name: 'Writer',
    value: 'Creates stories, articles, or other written content, often focusing on deeper human experiences.',
  },
  {
    name: 'Librarian',
    value: 'Organizes and manages information resources, helping people find and use information effectively.',
  },
  {
    name: 'Research Scientist',
    value: 'Conducts in-depth research and experiments to uncover new knowledge and advancements in science.',
  },
  {
    name: 'Engineer',
    value: 'Designs and develops systems, products, or processes, solving complex technical problems.',
  },
  {
    name: 'Strategic Planner',
    value: 'Develops long-term strategies for organizations to meet goals efficiently and effectively.',
  },
  {
    name: 'Software Developer',
    value: 'Creates, tests, and maintains software programs and applications that meet user needs.',
  },
  {
    name: 'Philosopher',
    value: 'Studies deep questions about existence, knowledge, and mind through analysis and theory.',
  },
  {
    name: 'Theoretical Physicist',
    value: 'Develops theories to explain natural phenomena in physics, often dealing with abstract concepts.',
  },
  {
    name: 'Programmer',
    value: 'Writes and tests code for software, websites, and applications, solving problems creatively.',
  },
  {
    name: 'Academic Researcher',
    value: 'Conducts research in academic institutions and produces specialized studies and reports.',
  },
  { name: 'Analyst', value: 'Gathers and analyzes data to help organizations make informed decisions and strategies.' },
  { name: 'Chef', value: 'Prepares and creates meals, often focusing on creativity and presentation in cooking.' },
  { name: 'Fashion Designer', value: 'Creates clothing and accessories, combining creativity with practical design.' },
  {
    name: 'Accountant',
    value: 'Manages financial records, ensuring accuracy and compliance with laws and regulations.',
  },
  {
    name: 'Auditor',
    value: 'Examines financial records to ensure accuracy and identify potential areas of improvement.',
  },
  { name: 'Mechanic', value: 'Repairs and maintains machines and engines, ensuring they function properly.' },
  { name: 'Pilot', value: 'Operates aircraft, ensuring safe and efficient travel for passengers or goods.' },
  { name: 'Carpenter', value: 'Builds and repairs wooden structures, furniture, or other objects.' },
  { name: 'Artist', value: 'Expresses creativity through visual arts, music, or other artistic forms.' },
  {
    name: 'Human Resources Manager',
    value: 'Manages employee relations, hiring, and development within an organization.',
  },
];
