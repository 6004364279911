import { useState } from 'react';

export function useStatus() {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    success: false,
  });

  const onLoading = () =>
    setStatus({
      loading: true,
      error: false,
      success: false,
    });

  const onSuccess = () =>
    setStatus({
      loading: false,
      error: false,
      success: true,
    });

  const onError = () =>
    setStatus({
      loading: false,
      error: true,
      success: false,
    });

  return {
    status,
    onLoading,
    onSuccess,
    onError,
  };
}
