import { combineReducers } from 'redux';
import getApiReducer from 'redux/util/get-api-reducer';

import { GET_SCHOOL_EVENTS, SUBMIT_COMMENT_SCHOOL_REVIEWS } from './school-profile-actions';

const reducers = combineReducers({
  getSchoolEvents: getApiReducer(GET_SCHOOL_EVENTS),
  submitCommentSchoolReviews: getApiReducer(SUBMIT_COMMENT_SCHOOL_REVIEWS),
});

export default reducers;
