const curriculumFeaturesDictionary = {
  'Art Studies': [
    'Visual Arts',
    'Drawing',
    'Painting',
    'Sculpture',
    'Ceramics',
    'Photography',
    'Graphic Design',
    'Performing Arts',
    'Music',
    'Band',
    'Orchestra',
    'Choir',
    'Drama',
    'Theater Arts',
    'Dance',
    'Film Arts',
    'Art History',
    'Studio Art: Drawing',
    'Visual Media Arts',
    'Opera',
    'Design Thinking Lab',
    'Studio Art - Drawing',
    'Studio Art - 2D Design',
    'Studio Art - 3D Design',
    'Poetry',
    'Jazz Band',
    'Rock Band',
    'Instrumental and Theory Music lessons',
    'Video and Film Production',
    'Graphics',
    'Music Theory',
    'Choir / Chorus',
    'Theory',
    'Chamber Music',
    'Technical Design and Production',
    'Vocal Lessons / Coaching',
    'Improv',
    'Drawing / Sketching',
    'Printmaking',
    'Design',
    'Textiles',
    'Bell / Handbell Choir',
    'Architecture',
    'Computer Animation',
  ],
  'Business and Economics': [
    'Business',
    'Business Law',
    'Business Management',
    'Marketing',
    'Economics',
    'Accounting',
    'Finance',
    'Entrepreneurship',
    'Office Practices',
    'Typing',
    'Shorthand',
    'Business English',
    'Office Machines',
    'Recordkeeping',
    'Consumer Education',
    'Retail and Salesmanship',
    'Financial Literacy',
  ],
  'Engineering Sciences': [
    'Engineering',
    'Aerospace Engineering',
    'Electrical Engineering',
    'Mechanical Engineering',
    'Civil Engineering',
    'Computer Engineering',
    'Industrial Arts',
    'Electricity',
    'Electronics',
    'Machine Shop',
    'Drafting',
    'Woodworking',
    'Metalwork',
    'Computer Science',
    'Programming',
    'Data Processing',
    'Systems Analysis',
    'Web Development',
    'Network Administration',
    'Robotics',
  ],
  'Health Sciences': [
    'Health Education',
    'Physical and Health Education',
    'Safety Education',
    'Sex Education',
    'Nutrition',
    'Physical Education',
    'Sports and Recreation',
    'Gymnastics',
    'Fitness',
    'Driver Education',
    'First Aid and Emergency Response',
    'Medical Pathway',
    'Physical Education (PE Classes)',
    'Life Skills and Employment Skills for ESE',
  ],
  'Interpersonal Development': [
    'Leadership',
    'Interpersonal Skills',
    'Peer Counseling',
    'Building Human Relationships',
    'Dropout Prevention',
    'Career Preparation',
    'Career Exploration',
    'Work Experience',
    'Cooperative Education',
    'Special Education',
    'Study Skills',
    'Survival Skills',
    'Personal Awareness',
    'Self Perception',
    'Coping with Personal Problems',
    'Personal Development Techniques',
    'Tutoring/Academic Enrichment',
    'Mentoring',
    'Character Education',
    'College and Career Readiness',
    'Volunteering/Community Service',
  ],
  Linguistics: [
    'English Language Arts',
    'Grammar',
    'Composition',
    'Creative Writing',
    'Literature',
    'Comparative Literature',
    'American Literature',
    'British Literature',
    'Speech',
    'Debate',
    'Technical and Business Writing',
    'Reading Development',
    'Functional English',
    'Foreign Languages',
    'Spanish',
    'French',
    'German',
    'Italian',
    'Japanese',
    'Mandarin',
    'Russian',
    'Latin',
    'Arabic',
    'Hebrew',
    'Swahili',
    'American Sign Language (ASL)',
    'English as a Second Language',
    'Linguistics',
    'Public Speaking',
    'Journalism',
    'Vietnamese',
    'Korean',
    'Chinese - Mandarin',
    'Dutch',
    'Tagalog',
    'Chinese - Cantonese',
    'Amharic',
    'Hmong',
    'Urdu',
    'Turkish',
    'Samoan',
    'Tongan',
    'Hawaiian',
    'Greek',
    'Immersive Trilingual Curriculum',
    'Chinese Language and Culture',
    'French Language and Culture',
    'German Language and Culture',
    'Spanish Language and Culture',
    'Italian Language and Culture',
    'Japanese Language and Culture',
    'Latin: Virgil',
    'English Literature and Composition',
    'English Language and Composition',
    'Writing',
    'ELA',
    'Chinese',
    'Spanish Language',
    'German Language',
  ],
  'Mathematical Studies': [
    'Mathematics',
    'Pre-Calculus',
    'Algebra',
    'Geometry',
    'Trigonometry',
    'Calculus',
    'Statistics',
  ],
  'Social Sciences': [
    'Social Studies',
    'History',
    'Geography',
    'Civics',
    'Government',
    'Political Science',
    'Sociology',
    'Psychology',
    'United States Government',
    'Comparative Government',
    'Macroeconomics',
    'Microeconomics',
    'World History',
    'United States History',
    'Human Geography',
    'European History',
    'Comparative Government and Politics',
    'United States Government and Politics',
    'World Culture',
  ],
  'Military Science': [
    'Aerospace Science (Air Force)',
    'Civil Air Patrol',
    'Military Science (Army)',
    'Army Leadership Development',
    'Naval Science (Navy, Marines)',
    'Marine Corps Leadership Education',
    'Criminal Justice',
    'Fire Protection',
    'Security Services',
    'JROTC',
    'CCMR (College, Career and Military Readiness)',
  ],
  'Religious Studies': [
    'Religious Studies',
    'Comparative Religion',
    'Theology',
    'Bible Studies',
    'Bible, Bible Doctrine',
  ],
  Science: [
    'Biology',
    'Botany',
    'Zoology',
    'Microbiology',
    'Cell and Molecular Biology',
    'Anatomy',
    'Physiology',
    'Ecology',
    'Marine Biology',
    'Genetics',
    'Chemistry',
    'Organic Chemistry',
    'Inorganic Chemistry',
    'Physical Chemistry',
    'Physics',
    'Applied Physics',
    'Astrophysics',
    'Acoustics',
    'Rocketry and Space Science',
    'Earth Sciences',
    'Geology',
    'Meteorology',
    'Oceanography',
    'Environmental Science',
    'Astronomy',
    'Health Sciences',
    'Nursing',
    'Pharmacy',
    'Medical Ethics',
    'Public Health',
    'Maker Space',
    'Physics C: Mechanics',
    'Physics C: Electricity and Magnetism',
    'Biology',
    'Physics 1: Algebra-Based',
    'Physics 2: Algebra-Based',
    'Chemistry',
    'Environmental Science',
    'Marine Biology',
    'Research',
  ],
  'Advanced Academics': [
    'Gifted Students Support',
    'Gifted and Talented',
    'AP',
    'Dual enrollment',
    'Career Certifications',
    'International Baccalaureate',
    'High School Credit Courses',
    'Career Readiness',
    'Concurrent enrollment',
    'Dual Credit',
    'Honors Classes',
    'Credit Recovery',
    'Career and Technical Education (CTE)',
    'Four Day School Week (Mon-Thu)',
    'Night Program',
    'Flexible Schedule',
    'SAT/ACT Preparation',
    'College Credit Courses',
    'Internships',
    'Study Abroad',
  ],
};

// Fuzzy Match Function
export function fuzzyMatchFeatures(inputFeatures, threshold = 0.7) {
  let categorizedGroups = {};
  let uncategorizedFeatures = [];

  inputFeatures?.forEach(({ id, name }) => {
    let bestMatch = null;
    let bestGroup = null;
    let highestSimilarity = 0;

    // Iterate over each group and feature
    Object.entries(curriculumFeaturesDictionary).forEach(([groupName, features]) => {
      features.forEach((feature) => {
        const distance = getEditDistance(name.toLowerCase(), feature.toLowerCase());
        const maxLength = Math.max(name.length, feature.length);
        const similarity = 1 - distance / maxLength;

        if (similarity > highestSimilarity) {
          highestSimilarity = similarity;
          bestMatch = feature;
          bestGroup = groupName;
        }
      });
    });

    if (highestSimilarity >= threshold) {
      // Add to the categorized group
      if (!categorizedGroups[bestGroup]) {
        categorizedGroups[bestGroup] = [];
      }
      categorizedGroups[bestGroup].push({ id, name });
    } else {
      // Add to uncategorized features
      uncategorizedFeatures.push({ id, name });
    }
  });

  // Convert categorizedGroups to the desired format
  const categorized = Object.entries(categorizedGroups).map(([name, features]) => ({
    name,
    features,
  }));

  return { categorized, uncategorized: uncategorizedFeatures };
}

function getEditDistance(a, b) {
  const matrix = [];

  // Increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // Increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // Substitution
          matrix[i][j - 1] + 1, // Insertion
          matrix[i - 1][j] + 1 // Deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}
