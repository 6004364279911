export const getGradesRange = (grades) => {
  const intGrade = (grade) => {
    let ordinal = 0;
    switch (grade) {
      case 'Pre-K':
        ordinal = -3;
        break;
      case 'TK':
        ordinal = -2;
        break;
      case 'HS':
        ordinal = -1;
        break;
      case 'K':
        ordinal = 0;
        break;
      default:
        ordinal = parseInt(grade, 10);
        break;
    }
    return ordinal;
  };
  let rangeBegin = '';
  let rangeEnd = '';
  let ranges = [];
  let lastIndex;
  grades.split(',').map((grade) => {
    if (rangeBegin === '') {
      rangeBegin = grade === 'HS' ? 'Head Start' : grade;
    } else if (lastIndex + 1 !== intGrade(grade)) {
      const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
      ranges.push(_range);
      rangeBegin = grade === 'HS' ? 'Head Start' : grade;
      rangeEnd = grade === 'HS' ? 'Head Start' : grade;
    }
    rangeEnd = grade === 'HS' ? 'Head Start' : grade;
    lastIndex = intGrade(grade);
    return null;
  });
  const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
  ranges.push(_range);
  return ranges.join(', ');
};
