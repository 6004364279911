import { useEffect, useState } from 'react';

export function useForm({ initialValue = {}, validateValues, successCallback, dataAsyncCallback }) {
  const [values, setValues] = useState({});

  // initialValue for normal forms, dataCallback for form that receives async data
  useEffect(() => {
    if (dataAsyncCallback) {
      dataAsyncCallback().then((data) => setValues(data));
    } else {
      setValues(initialValue);
    }
  }, []);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleValue = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleToggle = (event, value) => {
    const { name } = event.target;

    setValues({
      ...values,
      [name]: !value,
    });
  };

  const reset = () => {
    setValues(initialValue);
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();

    setErrors(validateValues(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      successCallback();
    }
  }, [errors]);

  return {
    values,
    setValues,
    handleChange,
    handleValue,
    handleToggle,
    reset,
    handleSubmit,
    errors,
  };
}
