import { useEffect, useRef, useState } from 'react';

export const useAutocompletePlaces = () => {
  const [places, setPlaces] = useState([]);
  const [placeLocation, setPlaceLocation] = useState({});

  // google places api:
  const googleMapsRef = useRef(null);
  const autocompleteServiceRef = useRef(null);
  const sessionTokenRef = useRef(null);
  const placesServiceRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      googleMapsRef.current = window.google.maps;
      autocompleteServiceRef.current = new googleMapsRef.current.places.AutocompleteService();
      sessionTokenRef.current = new googleMapsRef.current.places.AutocompleteSessionToken();
      placesServiceRef.current = new googleMapsRef.current.places.PlacesService(document.createElement('div'));
      geocoderRef.current = new googleMapsRef.current.Geocoder();
    } else {
      console.error('Google Maps API is not available.');
    }
  }, []);

  const handleAutocomplete = (text) => {
    let minLength = 3;
    const options = {
      input: text,
      locationBias: null,
      sessionToken: sessionTokenRef.current,
    };
    options.componentRestrictions = { country: 'US' };
    if (!isNaN(text)) options.types = ['postal_code'];
    const inputLength = text.length;
    const isShorterThanMinLength = minLength && inputLength < minLength;
    if (isShorterThanMinLength) {
      // setPlaces([]);
      // this.updateSuggests();
      return;
    }
    // load locations
    if (!autocompleteServiceRef.current) {
      // setPlaces([]);
      return;
    }

    autocompleteServiceRef.current.getPlacePredictions(options, (suggestsGoogle) => {
      const finalPlaces = formatPlaces(suggestsGoogle);
      setPlaces(finalPlaces);
    });
  };

  const getGeolocation = (placeId, text) => {
    if (!geocoderRef.current) {
      return;
    }
    if (placeId && placesServiceRef.current) {
      const options = {
        placeId,
        sessionToken: sessionTokenRef.current,
      };

      return new Promise((resolve, reject) => {
        placesServiceRef.current.getDetails(options, (results, status) => {
          if (status === googleMapsRef.current.places.PlacesServiceStatus.OK) {
            const gmaps = results;
            if (gmaps) {
              const formattedData = {};
              let streetNumber = '';
              let route = '';

              gmaps.address_components.forEach((addressPart) => {
                if (addressPart.types.indexOf('street_number') !== -1) {
                  streetNumber = addressPart.long_name;
                }
                if (addressPart.types.indexOf('route') !== -1) {
                  route = addressPart.long_name;
                }
                if (addressPart.types.indexOf('locality') !== -1) {
                  formattedData.city = addressPart.long_name;
                }
                if (addressPart.types.indexOf('administrative_area_level_1') !== -1) {
                  formattedData.state = addressPart.short_name;
                }
                if (addressPart.types.indexOf('postal_code') !== -1) {
                  formattedData.zip = addressPart.long_name;
                }
              });
              formattedData.address = `${streetNumber} ${route}`;

              const location = gmaps.geometry && gmaps.geometry.location;
              const _item = {
                address: gmaps.formatted_address,
                lat: location.lat(),
                lng: location.lng(),
                text,
                formattedData,
              };

              setPlaceLocation(_item);
              resolve(_item);
            }
          } else {
            console.log(status);
            reject(status);
          }
        });
      });
    }
  };

  return { places, placeLocation, handleAutocomplete, getGeolocation };
};

const formatPlaces = (suggestsGoogle = []) => {
  let data = [];
  // if allow detect location
  // if (currentLocation.place_id) {
  //   const current = {
  //     type: 'current',
  //     value: currentLocation.place_id,
  //     text: currentLocation.address,
  //     subtext: 'Current Location',
  //   };
  //   data = [current];
  // }

  if (suggestsGoogle !== null && suggestsGoogle.length) {
    let suggests = [];
    suggestsGoogle.forEach((suggest) => {
      suggests.push({
        type: 'location',
        text: suggest.structured_formatting.main_text,
        subtext: suggest.structured_formatting.secondary_text,
        value: suggest.place_id,
      });
    });
    data = data.concat(suggests);
  }
  return data;
  // setPlaces(data);
};
