import React from 'react';
import PropTypes from 'prop-types';
import { CheckmarkFilled32 } from '@carbon/icons-react';
import styles from './school-name.module.scss';

export const SchoolName = ({ className, name, claimed, logo }) => {
  return (
    <div className={`${className} spacing-mb-16`}>
      {logo && (
        <div>
          <div className={styles.logo}>
            <img src={logo} alt={`${name} logo`} />
          </div>
        </div>
      )}
      <div className={styles.name_check_claimed}>
        <h1 className={styles.schoolName}>{name}</h1>
        {!claimed && (
          <div>
            <CheckmarkFilled32 fill="#0099CC" className={styles.claimed} />
          </div>
        )}
      </div>
    </div>
  );
};

SchoolName.propTypes = {
  name: PropTypes.string.isRequired,
  claimed: PropTypes.bool,
  logo: PropTypes.string,
  className: PropTypes.string,
};
