import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import apiRequestMiddleware from 'redux/middlewares/api-request';
import reducers from 'redux/reducers.js';
import { scholaApi } from './api';

let middlewareBean = [apiRequestMiddleware, thunk, scholaApi.middleware];

export const store = configureStore({
  reducer: reducers,
  middleware: middlewareBean,
});
