import { scholaApi } from '.';

const scholaMatchApi = scholaApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation({
      query: (payload) => ({
        url: '/contact/SM-feedback',
        method: 'post',
        body: payload,
      }),
    }),
  }),
});

export const { useSendFeedbackMutation } = scholaMatchApi;
