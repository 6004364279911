import React, { useState } from 'react';
import {
  Chat24,
  Phone24,
  Wikis24,
  Location24,
  Information24,
  ChevronRight24,
  Calendar24,
  DocumentBlank24,
  Search24,
} from '@carbon/icons-react';
import PropTypes from 'prop-types';
import styles from './school-action-item.module.scss';

const SchoolActionItem = ({ data, externalLink, icon, href, onClick }) => {
  let icons = {
    location: <Location24 />,
    email: <Chat24 />,
    phone: <Phone24 />,
    information: <Information24 />,
    web: <Wikis24 />,
    tour: <Calendar24 />,
    apply: <DocumentBlank24 />,
    search: <Search24 />,
  };

  return (
    <>
      <a href={href || null} rel="noreferrer" className={styles.link} target={externalLink ? '_black' : ''}>
        <li className={styles.actionItem} onClick={onClick || null}>
          <div className={styles.content}>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              {icons[icon]}
              <p>{data}</p>
            </div>
            <ChevronRight24 />
          </div>
        </li>
      </a>
    </>
  );
};

SchoolActionItem.propTypes = {
  data: PropTypes.string,
  externalLink: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SchoolActionItem;
