import { useEffect, useState } from 'react';

export const useScrollVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  const getLayout = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      setIsVisible(windowHeight > 500);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', getLayout);
    return () => window.removeEventListener('scroll', getLayout);
  }, []);

  return isVisible;
};
