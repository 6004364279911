import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './about-banner.module.scss';
import Button from 'components/button/Button';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';

export const AboutBanner = ({
  contactAction,
  className,
  title,
  subtitle,
  description,
  buttonText,
  arrowTextLeft,
  arrowTextRight,
}) => {
  const { schoolsPath } = useSelector((state) => state.schoolResults);

  const AboutBannerClasses = {
    [className]: className,
    [styles.about_banner]: true,
  };
  return (
    <div className={classNames(AboutBannerClasses)}>
      <h3 className={`${styles.title} heading-1 text-center`}>{title}</h3>
      <p className={`${styles.subtitle} text-center`}>{subtitle}</p>
      <p className="spacing-mb-16 body-large font-600 text-center">{description}</p>
      <div className={styles.actions}>
        <div className={styles.arrow_text_left}>
          <p>{arrowTextLeft}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="49" height="30" viewBox="0 0 49 30" fill="none">
            <path
              d="M5.56943 1.59593C5.89855 1.15242 5.80583 0.526086 5.36232 0.196963C4.91882 -0.132159 4.29248 -0.0394346 3.96336 0.40407L5.56943 1.59593ZM48.0534 21.4831C48.3475 21.0157 48.207 20.3983 47.7396 20.1042L40.1219 15.3112C39.6545 15.0171 39.0371 15.1576 38.743 15.6251C38.4489 16.0926 38.5894 16.7099 39.0569 17.0041L45.8281 21.2644L41.5677 28.0356C41.2736 28.5031 41.4141 29.1205 41.8815 29.4146C42.349 29.7087 42.9664 29.5682 43.2605 29.1007L48.0534 21.4831ZM3.96336 0.40407C2.00506 3.04294 -0.143281 7.32527 0.00750178 11.5331C0.0840557 13.6694 0.755718 15.797 2.34968 17.6394C3.93571 19.4726 6.37013 20.949 9.84573 21.9141L10.3808 19.987C7.17282 19.0962 5.12365 17.7889 3.86218 16.3308C2.60865 14.8819 2.06891 13.2108 2.00622 11.4614C1.87852 7.89777 3.74034 4.06069 5.56943 1.59593L3.96336 0.40407ZM9.84573 21.9141C23.4462 25.6906 40.5684 23.4871 47.4289 21.9256L46.9851 19.9755C40.2558 21.5071 23.5148 23.634 10.3808 19.987L9.84573 21.9141Z"
              fill="black"
            />
          </svg>
        </div>
        <div className={styles.button_container}>
          <Link to={schoolsPath}>
            <Button onClick={contactAction} color="green" label="buttonText">
              {buttonText}
            </Button>
          </Link>
        </div>
        <div className={styles.arrow_text_right}>
          <svg xmlns="http://www.w3.org/2000/svg" width="57" height="30" viewBox="0 0 57 30" fill="none">
            <path
              d="M46.9058 28.1956C46.4616 28.5238 46.3675 29.1499 46.6957 29.5941C47.0238 30.0383 47.65 30.1324 48.0942 29.8042L46.9058 28.1956ZM37 1.99994L37.1104 2.99383L37 1.99994ZM0.0048769 24.0418C-0.0494306 24.5914 0.35209 25.081 0.901698 25.1353L9.85808 26.0203C10.4077 26.0746 10.8973 25.6731 10.9516 25.1234C11.0059 24.5738 10.6044 24.0843 10.0547 24.03L2.09352 23.2433L2.88018 15.2821C2.93448 14.7325 2.53296 14.2429 1.98336 14.1886C1.43375 14.1343 0.944177 14.5358 0.88987 15.0854L0.0048769 24.0418ZM48.0942 29.8042C50.2623 28.2025 52.3528 25.4437 53.86 22.3087C55.3704 19.1672 56.3506 15.5333 56.1844 12.1321C56.0168 8.70362 54.6738 5.46475 51.5055 3.31809C48.3775 1.1987 43.633 0.256798 36.8896 1.00606L37.1104 2.99383C43.617 2.27088 47.8094 3.2296 50.3837 4.97382C52.9177 6.69078 54.0425 9.27852 54.1868 12.2298C54.3324 15.2084 53.4685 18.5074 52.0575 21.4421C50.6434 24.3835 48.7351 26.8442 46.9058 28.1956L48.0942 29.8042ZM36.8896 1.00606C27.8599 2.00934 19.1795 7.27407 12.5749 12.3937C9.25813 14.9647 6.43476 17.5231 4.31833 19.5379C3.26013 20.5452 2.37719 21.4182 1.70077 22.086C1.00163 22.7762 0.569993 23.1995 0.365882 23.3669L1.63418 24.9133C1.93006 24.6707 2.45154 24.1553 3.10591 23.5092C3.783 22.8407 4.65397 21.9797 5.69732 20.9864C7.78401 19 10.5544 16.4904 13.8002 13.9744C20.3205 8.92019 28.6401 3.93497 37.1104 2.99383L36.8896 1.00606Z"
              fill="black"
            />
          </svg>
          <p>{arrowTextRight}</p>
        </div>
      </div>
    </div>
  );
};
AboutBanner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  arrowTextLeft: PropTypes.string.isRequired,
  arrowTextRight: PropTypes.string.isRequired,
};
