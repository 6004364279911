import React from 'react';
import styles from './schola-review.module.scss';

export const ScholaReview = () => {
  return (
    <div className={styles.review}>
      <div>
        <img src="https://static.schola.com/schola-match-results/chip-testimonial-hero.png" alt="schola-reviews" />
      </div>
      <div className={styles.review_star_text}>
        <div style={{ display: 'flex' }}>
          <div>
            <img src="https://static.schola.com/schola-match-results/google-white-circle.svg" alt="" />
          </div>
          <div>
            <img src="https://static.schola.com/profile/max-rate.svg" alt="" />
          </div>
        </div>
        <div>
          <span className="caption">Helped 160k+ Families!</span>
        </div>
      </div>
    </div>
  );
};
