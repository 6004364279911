import React, { useEffect } from 'react';
import { Close16 } from '@carbon/icons-react';
import './drawer.scss';

const Drawer = ({ children, title, activeDrawer, toggleDrawer, className }) => {
  useEffect(() => {
    if (activeDrawer) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [activeDrawer]);

  if (activeDrawer) {
    return (
      <div className={`drawer_overlay ${className}`}>
        <div className={`drawer_container `}>
          <div className="drawer_container_header">
            {title && <span>{title}</span>}
            <Close16 onClick={toggleDrawer} className="drawer_container_header_icon" />
          </div>
          <div className="drawer_container_content">{children}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default Drawer;
