import React from 'react';
import { FeatureList } from 'components/feature-list/feature-list';
import { Modal } from 'schola-components';
import { useDisplayFeatures } from '../../hooks/useDisplayFeatures';
import styles from './feature-modal.module.scss';

export const FeatureModal = ({ isVisible, features, type, title, onClose }) => {
  return (
    <Modal className={styles.featureModal} title={title} isVisible={isVisible} onClose={onClose}>
      <div className={styles.listsContainer}>
        {Object.entries(features).map(([key, value]) => {
          const displayFeatures = useDisplayFeatures(value);
          return displayFeatures && <FeatureList key={key} features={value} subTitle={key} type={type} />;
        })}
      </div>
    </Modal>
  );
};
