import gradesArray from 'util/grades-array';

export const getGradesPerSchool = (gradesBySchool) => {
  const grades = gradesBySchool.split(',');
  if (grades.length === 1) {
    return getMapGradeValues(grades);
  }

  grades.sort((a, b) => {
    // get the order of the grade referencing on the general array grades
    const indexA = gradesArray.indexOf(a);
    const indexB = gradesArray.indexOf(b);

    if (indexA === -1) {
      return 1;
    }

    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });

  return getMapGradeValues(grades);
};

export const getMapGradeValues = (grades) => {
  const gradesMap = grades.map((grade) => ({ value: grade, label: grade }));

  return [{ value: null, label: ' ' }, ...gradesMap];
};
