import React, { useState } from 'react';
import './Pagination.scss';

const Pagination = ({ reviewsPerPage, totalReviews, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalReviews / reviewsPerPage); i++) {
    pageNumbers.push(i);
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [pagesPerPage, setPagesPerPage] = useState(3);
  const indexOfLast = pageNumber * pagesPerPage;
  const indexOfFirst = indexOfLast - pagesPerPage;

  const amountPages = pageNumbers.slice(indexOfFirst, indexOfLast);

  const changePages = () => {
    if (pageNumbers === amountPages) return null;
    setPageNumber(pageNumber + 1);
  };

  return (
    <nav className="paginationProfile">
      <ul className="paginationProfile_container">
        {/* <span onClick={() => setPageNumber(pageNumber - 1)}>Prev</span> */}

        {pageNumbers.map((number, index) => (
          <li
            key={index}
            className={`paginationProfile_item ${currentPage === number && 'paginationProfile_selected'}`}
            onClick={() => paginate(number)}>
            {number}
          </li>
        ))}
        {/* {
          pageNumbers.length > 3  && <span onClick={() => changePages}>Next</span>
        } */}
      </ul>
    </nav>
  );
};

export default Pagination;
