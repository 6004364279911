import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'schola-components';
import Drawer from 'components/pages/scholamatch/components/Drawer/Drawer';

const ModalAdapter = ({ renderMobile, children, title, onClose, isVisible, className, classNameContent }) => {
  if (renderMobile) {
    return (
      <Drawer className={className} title={title} activeDrawer={isVisible} toggleDrawer={onClose}>
        {children}
      </Drawer>
    );
  }

  return (
    <Modal
      className={className}
      classNameContent={classNameContent}
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      zIndex={10000}>
      {children}
    </Modal>
  );
};

ModalAdapter.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderMobile: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalAdapter;
