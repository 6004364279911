import client from '../client';

export const _createLead = async (payload) => {
  return await client.post('leads', payload);
};

export const _batchEditLeads = (schoolId, leads, values, createdBy) => {
  return client.patch(`schools/${schoolId}/leads/batch`, { leads, values, createdBy });
};

export const _handleParentScholaMatch = async (payload) => {
  return await client.post('sf/parents/scholamatch', payload);
};

export const _handleScholaMatchEmail = async (payload) => {
  return await client.post('sf/parents/scholamatch-email', payload);
};

export const _handleParentSF = async (parent) => {
  return await client.post('sf/parents', parent);
};

export const _handleParentWithStudentSF = async (parent) => {
  return await client.post('sf/parentswithstudent', parent);
};

export const _sendApplications = (schoolId, leads, emailRequired = true, loggedId) => {
  return client.post(`schools/${schoolId}/leads/send-apps`, { leads, emailRequired, logged_id: loggedId });
};

export const _updateFeedbackByLead = async (schoolId, leadId, parentFeedback) => {
  return client.patch(`schools/${schoolId}/leads/${leadId}/feedback`, { parent_feedback: parentFeedback });
};

export const _getNotesByLead = async (leadId) => {
  return client.get(`notes/lead/${leadId}`);
};
