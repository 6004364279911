import React from 'react';
import { AddAlt20, SubtractAlt20 } from '@carbon/icons-react';
import { useDispatch } from 'react-redux';
import { removeStudent, addStudent } from 'redux/slices/contact-school.slice';
import styles from './students-stage.module.scss';
import { t } from 'components/translation/Translation';

export const StudentsCounter = ({ students }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.students}>
      <p className="body-regular">{t('contactSchool:students.number')}</p>
      <div className={styles.students_counter}>
        <div
          style={{ cursor: 'pointer' }}
          className="subtract-student"
          data-testid="subtract-student"
          onClick={() => dispatch(removeStudent())}
          disabled={students.length < 2}>
          <SubtractAlt20 fill="#003D52" />
        </div>
        <span className="spacing-mx-16" data-testid="students-counter">
          {students.length}
        </span>
        <div
          style={{ cursor: 'pointer' }}
          id="add_button"
          className="add-student"
          data-testid="add-student"
          onClick={() => dispatch(addStudent())}>
          <AddAlt20 fill="#003D52" />
        </div>
      </div>
    </div>
  );
};
