export const logosReviews = {
  Schola: 'https://static.schola.com/profile/schola-logo-icon.webp',
  'Great Schools': 'https://static.schola.com/profile/greatSchoolsLogo.webp',
  Google: 'https://static.schola.com/profile/googleLogo60.webp',
};

export const transformExternalReviews = (response) => {
  const validReviews = ['Schola', 'Great Schools', 'Google'];
  const reviews = response.filter((review) => {
    if (validReviews.includes(review.site) && review.url && review.total > 0) return review;
  });

  reviews.forEach((item) => {
    if (item.site === 'Schola') {
      item.ranking /= 20;
    }
    item.ranking = Math.round(item.ranking);
  });

  if (reviews.length > 1) {
    reviews.sort((a, b) => {
      if (a.site === 'Schola') return -1;
      if (b.site === 'Schola') return 1;
      return 0;
    });
  }

  return reviews;
};
