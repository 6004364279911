import React from 'react';
import styles from './banner-tours.module.scss';
import { Checkmark16 } from '@carbon/icons-react';
import Button from 'components/button/Button';

export const BannerTours = ({ toggleScheduleTourModal }) => {
  return (
    <div className={styles.container}>
      <div>
        <h3 className="spacing-mb-16">Explore the School in Person – Schedule Your Tour!</h3>
        <ul className={styles.list}>
          <li>
            <Checkmark16 /> See Classrooms and Facilities Firsthand
          </li>
          <li>
            <Checkmark16 /> Meet Teachers and Staff
          </li>
          <li>
            <Checkmark16 /> Experience the School Community
          </li>
        </ul>
        <Button id="btnTourOpenBanner" color="green" onClick={toggleScheduleTourModal}>
          Schedule a Tour
        </Button>
      </div>
      <div>
        <img
          src="https://scholamatch-static.s3.us-west-2.amazonaws.com/profile/figure-tour-schola.webp"
          width="213"
          height="162"
        />
      </div>
    </div>
  );
};
