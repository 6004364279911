import React, { useState, useEffect } from 'react';
import { InputRadio, Button, LoadingSpinner } from 'schola-components';
import { _createSchoolReview } from '../../../../../controllers/school-profile/reviews_controller';
import AlertNotification from 'components/pages/parent-portal-referrals/components/AlertNotification';
import { t } from 'components/translation/Translation';

const RelationshipWithSchool = ({
  schoolName,
  state,
  setState,
  decreaseStep,
  increaseStep,
  finalReview,
  school_id: schoolId,
  loggedIn,
  setFlag,
}) => {
  const options = [
    {
      display: t('schoolProfile:reviews.relations.legalGuardian'),
      id: 'guardian',
      name: 'relationWithSchool',
      value: 'Guardian',
    },
    {
      display: t('schoolProfile:reviews.relations.student'),
      id: 'student',
      name: 'relationWithSchool',
      value: 'Student',
    },
    {
      display: t('schoolProfile:reviews.relations.other'),
      id: 'other',
      name: 'relationWithSchool',
      value: 'Other',
    },
  ];

  const [relation, setRelation] = useState(null);

  useEffect(() => {
    setRelation(state);
  }, []);

  const handleOption = (e) => {
    const { value } = e.target;
    setRelation(value);
    setState(value);
  };

  const [statusSend, setStatusSend] = useState(null);

  const [alertState, setAlertState] = useState({
    isActive: false,
    status: null,
    message: '',
  });

  const [error, setError] = useState('');
  const handleNext = (e) => {
    if (!state) {
      setError('Please, Choose One Option');
    } else {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusSend('loading');

    try {
      const res = await _createSchoolReview(schoolId, finalReview);
      const data = await res.json();

      if (res.ok) {
        setStatusSend('success');
        increaseStep();
        // Flag to re-call fetch reviews
        setFlag(true);
      }

      if (data.statusCode === 400) {
        setStatusSend(null);

        setAlertState({
          isActive: true,
          status: 'error',
          message: `You Have Already Added a Review to This School Previously`,
        });
      }

      if (data.statusCode === 401) {
        setStatusSend(null);

        setAlertState({
          isActive: true,
          status: 'error',
          message: `Please, Make Login Again`,
        });
      }
    } catch (error) {
      console.error(error);
      setStatusSend(null);
    }
  };

  return (
    <div>
      {!statusSend && (
        <>
          <h2 className="spacing-mb-32">{t('schoolProfile:reviews.whatIsYourRelationship', { schoolName })}</h2>
          <InputRadio data={options} currentSelect={relation} onChange={(e) => handleOption(e)} />
        </>
      )}

      {statusSend === 'loading' && (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <LoadingSpinner color="#0099CC" />
        </div>
      )}

      <p className="warningValidation spacing-mt-32">{error}</p>

      <div className="bigModalReview_buttons spacing-mt-64">
        <Button color="primarylegacy" onClick={decreaseStep}>
          {t('schoolProfile:reviews.prevButton')}
        </Button>
        <Button color="primarylegacy" onClick={(e) => handleNext(e)}>
          {t('schoolProfile:reviews.sendButton')}
        </Button>
      </div>
      {alertState.isActive && (
        <AlertNotification
          status={alertState.status}
          state={alertState.isActive}
          setState={setAlertState}
          message={alertState.message}
          seconds={7}
        />
      )}
    </div>
  );
};

export default RelationshipWithSchool;
