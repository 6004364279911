import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { DivIcon } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import styles from './leaflet-map.module.scss';

const MapEventHandler = ({ onMoveEnd }) => {
  useMapEvents({
    moveend: (e) => {
      const map = e.target;
      const center = map.getCenter();
      onMoveEnd({
        lat: center.lat,
        lng: center.lng,
      });
    },
  });
  return null;
};

export const LeafletMap = ({ className, center, zoom = 14, height = '200px', children, onCenterChange }) => {
  return (
    <MapContainer
      style={{ height }}
      className={`${styles.map} ${className || ''}`}
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      aria-label="School and user locations map">
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      <MapEventHandler onMoveEnd={onCenterChange} />
      {children}
    </MapContainer>
  );
};

const LeafletMarker = ({ position, Icon, children }) => {
  const customMapMarker = (Icon) => {
    return new DivIcon({
      html: ReactDOMServer.renderToString(<Icon />),
      className: styles.icon,
    });
  };
  const icon = customMapMarker(Icon);

  return (
    <Marker position={position} icon={icon} aria-label="School Location Marker">
      <Popup>{children}</Popup>
    </Marker>
  );
};

LeafletMap.Marker = LeafletMarker;
