import client from 'redux/client';

export const ENDORSE = 'ENDORSE';
export const GET_AUTOCOMPLETE_SCHOOLS = 'GET_AUTOCOMPLETE_SCHOOLS';
export const GET_ENDORSEMENTS = 'GET_ENDORSEMENTS';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOL_APPLICATION = 'GET_SCHOOL_APPLICATION';
export const GET_USER_APPLICATIONS = 'GET_USER_APPLICATIONS';
export const SEARCH_SCHOOLS = 'SEARCH_SCHOOLS';
export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const UNENDORSE = 'UNENDORSE';
export const UPDATE_ENDORSEMENTS = 'UPDATE_ENDORSEMENTS';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const GET_SCHOOL_FEATURES = 'GET_SCHOOL_FEATURES';
export const GET_SCHOOL_REVIEWS = 'GET_SCHOOL_REVIEWS';
export const SUBMIT_SCHOOL_REVIEWS = 'SUBMIT_SCHOOL_REVIEWS';

export const ADD_SCHOOL_SCHEDULE_TOUR = 'ADD_SCHOOL_SCHEDULE_TOUR';
export const GET_SCHOOL_SCHEDULE_TOUR = 'GET_SCHOOL_SCHEDULE_TOUR';
export const GET_SCHOOL_SCHEDULE_TOUR_ID = 'GET_SCHOOL_SCHEDULE_TOUR_ID';
export const GET_SCHOOL_TESTIMONIAL = 'GET_SCHOOL_TESTIMONIAL';
export const GET_SCHOOL_SEATS = 'GET_SCHOOL_SEATS';
export const GET_SCHOOL_SEATS_GRADE = 'GET_SCHOOL_SEATS_GRADE';
export const GET_SCHOOL_TOUR_TIMES = 'GET_SCHOOL_TOUR_TIMES';
export const GET_SCHOOL_SITES_REVIEWS = 'GET_SCHOOL_SITES_REVIEWS';
export const GET_SCHOOL_ACADEMICS = 'GET_SCHOOL_ACADEMICS';
export const UPDATE_CONNECT_REQUEST = 'UPDATE_CONNECT_REQUEST';
export const ADD_DECLINED_REASON = 'ADD_DECLINED_REASON';
export const GET_SCHOOL_DEMOGRAPHICS = 'GET_SCHOOL_DEMOGRAPHICS';
export const UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD = 'UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD';
export const UPDATE_SCHOOL_SCHEDULE_TOUR = 'UPDATE_SCHOOL_SCHEDULE_TOUR';
export const EVENT_INTERESTED_EMAIL = 'EVENT_INTERESTED_EMAIL';
export const UPDATE_SCHOOL_STATUS_DATE = 'UPDATE_SCHOOL_STATUS_DATE';
export const SEND_EMAIL_SCHOOL_STATUS_DATE = 'SEND_EMAIL_SCHOOL_STATUS_DATE';

export const SEARCH_SCHOOLS_LOADING = 'SEARCH_SCHOOLS_LOADING';
const rowsPackage = 100;

export function getSchool(id) {
  return {
    type: GET_SCHOOL,
    promise: client.get(`schools/${id}`),
  };
}

const startLoadSchools = (query) => ({
  type: 'SEARCH_SCHOOLS',
  query,
});

const loadSchools = (query, action, data) => ({
  type: `SEARCH_SCHOOLS_${action}`,
  query,
  ...data,
});
export const _searchSchools = (query) => {
  return searchSchools(query, true);
};
export const searchSchools = (query, simple) => {
  return async (dispatch) => {
    !simple && dispatch(startLoadSchools(query));

    const mainPageSize = parseInt(query.pageSize || 0);
    const mainPage = parseInt(query.page || 0);
    let maxRows = 0;
    let maxPages = 0;

    let q = {
      ...query,
      pageSize: rowsPackage,
      page: Math.round(mainPageSize / rowsPackage) * ((query.page || 0) - 1) + 1,
    };
    let jsonResult = null;

    try {
      let response = await client.get('schools/search', q);
      if (response.ok) {
        let json = await response.json();
        jsonResult = { ...json };

        maxPages = jsonResult.pagination.pageCount;
        maxRows = jsonResult.pagination.rowCount;

        jsonResult.pagination.pageSize = mainPageSize;
        jsonResult.pagination.page = mainPage;
        jsonResult.pagination.pageCount = Math.ceil(jsonResult.pagination.rowCount / mainPageSize);

        !simple && dispatch(loadSchools(query, 'LOADING', { response, json: jsonResult }));
      }
    } catch (error) {
      console.log('api-request error:');
      console.error(error);
      !simple && dispatch(loadSchools(query, 'ERROR', error));
    }

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    let response = null;

    for (let page = q.page + 1; page <= maxPages && jsonResult.results.length < mainPageSize; page++) {
      // await delay(500);
      response = await client.get('schools/search', { ...q, page });
      if (response.ok) {
        let json = await response.json();
        jsonResult.results = [...jsonResult.results, ...json.results];

        if (jsonResult.results.length >= mainPageSize || page == jsonResult.pagination.pageCount - 1) {
          jsonResult.pagination.pageSize = mainPageSize;
          jsonResult.pagination.page = mainPage;
          jsonResult.pagination.pageCount = Math.ceil(jsonResult.pagination.rowCount / mainPageSize);
          !simple && dispatch(loadSchools(query, 'SUCCESS', { response, json: jsonResult }));
        } else {
          !simple && dispatch(loadSchools(query, 'LOADING', { response, json: jsonResult }));
        }
      }
    }

    return { json: jsonResult };
  };
};

export function getEndorsements(schoolId) {
  return {
    type: GET_ENDORSEMENTS,
    promise: client.get(`schools/${schoolId}/endorsements`),
  };
}

export function endorse(schoolId, featureId) {
  return {
    type: ENDORSE,
    schoolId,
    featureId,
    promise: client.post(`endorsements`, {
      school_id: schoolId,
      feature_id: featureId,
    }),
  };
}

export function unendorse(schoolId, featureId) {
  return {
    type: UNENDORSE,
    schoolId,
    featureId,
    promise: client.delete(`endorsements/${schoolId}/${featureId}`),
  };
}

export function updateEndorsements(schoolId, features) {
  return {
    type: UPDATE_ENDORSEMENTS,
    schoolId,
    features,
    promise: client.post(`endorsements/batch/${schoolId}`, { features }),
  };
}

export function getAutocompleteSchools(query) {
  return {
    type: GET_AUTOCOMPLETE_SCHOOLS,
    promise: client.get('schools/autocomplete-v2', query),
  };
}

export function submitApplicationBySchoolId(schoolId, payload) {
  return {
    type: SUBMIT_APPLICATION,
    promise: client.post(`schools/${schoolId}/applications`, payload),
  };
}

export function submitApplicationByToken(token, payload) {
  return {
    type: SUBMIT_APPLICATION,
    promise: client.post(`applications/${token}`, payload),
  };
}

export function getSchoolApplicationByToken(token) {
  return {
    type: GET_SCHOOL_APPLICATION,
    promise: client.get(`applications/${token}`),
  };
}

export function getSchoolApplicationBySchoolId(schoolId) {
  return {
    type: GET_SCHOOL_APPLICATION,
    promise: client.get(`schools/${schoolId}/application`),
  };
}

export function getUserApplications(query) {
  return {
    type: GET_USER_APPLICATIONS,
    promise: client.get('user/applications', query),
  };
}

export function resetAutocompleteSchools() {
  return { type: 'GET_AUTOCOMPLETE_SCHOOLS_RESET' };
}

export function resetSearchSchools() {
  return { type: 'SEARCH_SCHOOLS_RESET' };
}

export function submitReview(schoolId, payload) {
  return {
    type: SUBMIT_REVIEW,
    promise: client.post(`review/answers/${schoolId}`, payload),
  };
}

export function getSchoolFeatures(schoolId) {
  return {
    type: GET_SCHOOL_FEATURES,
    promise: client.get(`schools/${schoolId}/features-with-fs`),
  };
}

export function getSchoolReviews(schoolId, query) {
  return {
    type: GET_SCHOOL_REVIEWS,
    promise: client.get(`schools/${schoolId}/user-reviews`, query),
  };
}

export function submitSchoolReviews(schoolId, payload) {
  return {
    type: SUBMIT_SCHOOL_REVIEWS,
    promise: client.post(`schools/${schoolId}/user-reviews`, payload),
  };
}

export function getSchoolAcademics(schoolId) {
  return {
    type: GET_SCHOOL_ACADEMICS,
    promise: client.get(`academics/${schoolId}`),
  };
}

export function getSchoolSeats(schoolId) {
  return {
    type: GET_SCHOOL_SEATS,
    promise: client.get(`schools/${schoolId}/seats`),
  };
}
export function getSchoolSeatsGrade(schoolId, grade) {
  return {
    type: GET_SCHOOL_SEATS_GRADE,
    promise: client.get(`schools/${schoolId}/seatsgrade/${grade}`),
  };
}
export function getSchoolTestimonials(schoolId) {
  return {
    type: GET_SCHOOL_TESTIMONIAL,
    promise: client.get(`schools/${schoolId}/testimonial`),
  };
}

export function getSchoolSitesReviews(schoolId) {
  return {
    type: GET_SCHOOL_SITES_REVIEWS,
    promise: client.get(`schools/${schoolId}/sites-reviews`),
  };
}

export function addSchoolScheduleTour(schoolId, payload) {
  return {
    type: ADD_SCHOOL_SCHEDULE_TOUR,
    promise: client.post(`schools/${schoolId}/scheduletour`, payload),
  };
}

export function getSchoolScheduleTour(schoolId) {
  return {
    type: GET_SCHOOL_SCHEDULE_TOUR,
    promise: client.get(`schools/${schoolId}/scheduletour`),
  };
}

export function getSchoolScheduleTourById(schoolId, tourId) {
  return {
    type: GET_SCHOOL_SCHEDULE_TOUR_ID,
    promise: client.get(`schools/${schoolId}/scheduletour/${tourId}`),
  };
}

export function getSchoolTourTimes(schoolId) {
  return {
    type: GET_SCHOOL_TOUR_TIMES,
    promise: client.get(`schools/${schoolId}/tour-times`),
  };
}

export function responseConnectRequest(connectId, payload) {
  return {
    type: UPDATE_CONNECT_REQUEST,
    promise: client.post(`connect-response/${connectId}`, payload),
  };
}

export function addDeclinedReason(connectId, payload) {
  return {
    type: ADD_DECLINED_REASON,
    promise: client.post(`connect-response/${connectId}/declined`, payload),
  };
}

export function getSchoolDemographics(schoolId) {
  return {
    type: GET_SCHOOL_DEMOGRAPHICS,
    promise: client.get(`schools/${schoolId}/school-demographics`),
  };
}

export function updateSchoolTourLead(schoolId, tourId, payload) {
  return {
    type: UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD,
    promise: client.post(`schools/${schoolId}/scheduletour/${tourId}/lead`, payload),
  };
}

export function updateSchoolScheduleTour(schoolId, tourId, payload) {
  return {
    type: UPDATE_SCHOOL_SCHEDULE_TOUR,
    promise: client.post(`schools/${schoolId}/scheduletour/${tourId}`, payload),
  };
}

export function sendEmailEventInterested(schoolId, eventId, payload) {
  return {
    type: EVENT_INTERESTED_EMAIL,
    promise: client.post(`schools/${schoolId}/event-interested/${eventId}`, payload),
  };
}
export function updateSchoolStatusDate(id, status) {
  return {
    type: UPDATE_SCHOOL_STATUS_DATE,
    promise: client.patch(`schools/${id}/status`, { status }),
  };
}
export function sendReviewBusinessStatus(id, name) {
  return {
    type: SEND_EMAIL_SCHOOL_STATUS_DATE,
    promise: client.post(`schools/${id}/status/notification`, name),
  };
}
