import React from 'react';
// import styles from '../../schools-results.module.scss';
import styles from './skill-table.module.scss';
import { getStudent } from 'components/pages/schools-results-v2/utils/get-student';

export const SkillTable = ({ skills }) => {
  const student = getStudent();

  return (
    <div className="w-100">
      <h4 className={styles.table_title_gray}>
        <span>{student}’s</span> Brain Superpowers
      </h4>

      {skills.map((skill) => (
        <div key={skill.name} className={styles.table_row}>
          <div className={styles.table_row_title}>{skill.name}</div>
          <div className="personalyti_description">{skill.value}</div>
        </div>
      ))}
    </div>
  );
};
