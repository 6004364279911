import React, { useState, useRef, useEffect, useCallback } from 'react';
import './photo-gallery.scss';
import { ChevronLeft16, ChevronRight16 } from '@carbon/icons-react';
import { useToggle } from 'hooks/useToggle';
import { usePreventBodyScroll } from 'components/pages/parent-portal/track/hooks';
import { useResponsiveRender } from 'hooks/useResponsiveRender';

export const PhotoGallery = ({ school, columns }) => {
  const [isModalOpen, toggleModal] = useToggle(false);

  const { images: photos, name } = school;

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [zoomedImage, setZoomedImage] = useState({ src: '', index: null });
  const carouselRef = useRef(null);

  usePreventBodyScroll(isModalOpen);

  const checkOverflow = useCallback(() => {
    if (carouselRef.current) {
      const isOverflowing = carouselRef.current.scrollWidth > carouselRef.current.clientWidth;
      setIsOverflowing(isOverflowing);
    }
  }, []);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [checkOverflow]);

  const nextPhoto = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.clientWidth + 4; // Account for the gap
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const prevPhoto = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.clientWidth + 4; // Account for the gap
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const openZoomModal = (image, index) => {
    setZoomedImage({ src: image, index });
  };

  const closeZoomModal = () => {
    setZoomedImage({ src: '', index: null });
  };

  const handleImageLoad = () => {
    checkOverflow();
  };

  const { renderMobile } = useResponsiveRender({ breakpoint: 767 });

  let schoolGalleryImages = photos?.map((o) => {
    const filenameArr = o?.filename.split('/');
    const largeName = `/l-${filenameArr.pop()}`;
    const largeUrl = filenameArr.join('/') + largeName;

    if (renderMobile) {
      return largeUrl.replace('/l-', '/m-');
    }
    return largeUrl.replace('/m-', '/l-');
  });

  const handleImageError = (e, index) => {
    e.target.src = photos[index].filename; // Replace src with original image path
  };

  return (
    <div className={`photo-gallery ${photos.length === 0 ? 'empty' : ''}`}>
      {photos.length > 0 ? (
        <>
          <div className={`carousel-container ${isOverflowing ? 'show-arrows' : ''}`}>
            <button
              className="arrow prev"
              onClick={prevPhoto}
              aria-label="Previous Photos"
              aria-disabled={!isOverflowing}>
              <ChevronLeft16 />
            </button>
            <div
              className={`carousel ${isOverflowing ? '' : 'center'}`}
              ref={carouselRef}
              role="region"
              aria-label="Photo Carousel">
              {schoolGalleryImages.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`School Photo ${index + 1}`}
                  onLoad={handleImageLoad}
                  tabIndex="0"
                  role="img"
                  onError={(e) => handleImageError(e, index)}
                />
              ))}
            </div>
            <button className="arrow next" onClick={nextPhoto} aria-label="Next Photos" aria-disabled={!isOverflowing}>
              <ChevronRight16 />
            </button>
          </div>
          <button className="open-modal" onClick={toggleModal} aria-label="See All Photos">
            See All Photos
          </button>
        </>
      ) : (
        <div className="no-photos" aria-label="No photos available" />
      )}

      {isModalOpen && (
        <div
          className="modal-overlay"
          onClick={toggleModal}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title">
          <div className="modal-content" onClick={(e) => e.stopPropagation()} tabIndex="0">
            <h2 id="modal-title" className="visually-hidden">
              Photo Gallery
            </h2>
            <button className="close-modal" onClick={toggleModal} aria-label="Close Photo Gallery">
              Close
            </button>
            <div className="modal-photos" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
              {schoolGalleryImages.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Photo ${index + 1}`}
                  className="modal-photo"
                  onClick={() => openZoomModal(photo, index)}
                  onLoad={handleImageLoad}
                  tabIndex="0"
                  aria-label={`Zoom Photo ${index + 1}`}
                  onError={(e) => handleImageError(e, index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {zoomedImage.src && (
        <div
          className="zoom-modal-overlay"
          onClick={closeZoomModal}
          role="dialog"
          aria-modal="true"
          aria-labelledby="zoom-modal-title">
          <div className="zoom-modal-content" onClick={(e) => e.stopPropagation()} tabIndex="0">
            <h2 id="zoom-modal-title" className="visually-hidden">
              Zoomed Photo
            </h2>
            <button className="close-zoom-modal" onClick={closeZoomModal} aria-label="Close Zoomed Photo">
              Close
            </button>
            <img
              src={zoomedImage.src}
              alt="Zoomed"
              className="zoomed-image"
              onError={(e) => handleImageError(e, zoomedImage.index)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
