import { useGetSearchSchoolsQuery } from 'redux/api';

export const useSearchSchools = ({ query }) => {
  const { data, isSuccess, isLoading, isError, isFetching } = useGetSearchSchoolsQuery(query);

  return {
    rawSchools: data?.results,
    successSchools: isSuccess,
    loadingSchools: isLoading,
    errorSchools: isError,
    isFetching,
  };
};
