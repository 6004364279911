/* eslint-disable */ 
import { combineReducers } from 'redux';
import getApiReducer from 'redux/util/get-api-reducer';

import {
    GET_BOARDS,
    ADD_BOARD,
    ADD_SCHOOL_TO_BOARD,
    UPDATE_BOARDS_BY_SCHOOL,
    GET_BOARD,
    UPDATE_BOARD
} from './board-actions';

const reducers = combineReducers({
    getBoards: getApiReducer(GET_BOARDS),
    getBoard: getApiReducer(GET_BOARD),
    addBoard: getApiReducer(ADD_BOARD),
    addSchoolToBoard: getApiReducer(ADD_SCHOOL_TO_BOARD),
    updateBoardsBySchool: getApiReducer(UPDATE_BOARDS_BY_SCHOOL),
    updateBoard: getApiReducer(UPDATE_BOARD),
    
});

const initialState = {
    isOpen: false,
    schoolId: null,
    boards: {},
    statusBoards: {
      loading: false,
      error: false,
      success: false,
    },
    favoritesIDs: []
}

export const boardFlowReducer = (state = initialState, action) => {
    const { boardSchools } = state.boards

    switch (action.type) {
      case 'OPEN_BOARD':
        return {
          ...state,
          isOpen: true,
          schoolId: action.payload,
        };

      case 'CLOSE_BOARD':
        return {
            ...state,
            isOpen: false,
            schoolId: null,
        }

      case 'LOADING_BOARDS':
        return {
          ...state,
          statusBoards: {
            ...state.statusBoards,
            loading: true
          },
        }

      case 'ERROR_BOARDS':
        return {
          ...state,
          statusBoards: {
            success: false,
            loading: false,
            error: true
          },
        }

      case 'FETCH_BOARDS_SUCCESS':
        return {
            ...state,
            statusBoards: {
              ...state.statusBoards,
              loading: false,
              success: true,
            },
            boards: action.payload
        }

      case 'GET_FAVORITES_IDS':
        return {
          ...state,
          favoritesIDs: action.payload
        }

      case 'ADD_SCHOOL':
        return {
            ...state,
            boards: {
                ...state.boards,
                boardSchools: [...boardSchools, { school_id: action.payload }]
            }
        }
  
      case 'REMOVE_SCHOOL':
        const filteredSchools = boardSchools.filter((item) => item.school_id !== action.payload);
        const filterIDs = state.favoritesIDs.filter((item) => item !== action.payload);

        return {
            ...state,
            boards: {
                ...state.boards,
                boardSchools: filteredSchools
            },
            favoritesIDs: filterIDs
        }

      default:
        return state;
    }
  };

export default reducers;
