import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  isTourOpen: false,
  schedule: {
    day: null,
    time: null,
  },
  times: [],
  disabledDays: [],
};

const tourSchoolSlice = createSlice({
  name: 'tourSchool',
  initialState,
  reducers: {
    setDay: (state, { payload }) => {
      state.schedule.day = payload;
    },
    setTime: (state, { payload }) => {
      state.schedule.time = payload;
    },
    setDisabledDays: (state, { payload }) => {
      const disabledDays = [];
      const weekArr = payload.split('');
      const sunday = weekArr[weekArr.length - 1];
      weekArr.pop();
      const days = [sunday, ...weekArr];
      days.map((day, index) => {
        if (day === '0') {
          disabledDays.push(index);
        }
      });

      state.disabledDays = disabledDays;
    },
    setAvailableTimes: (state, { payload }) => {
      const { morningStart, morningEnd, afternoonStart, afternoonEnd } = payload;
      const morningTimes = parseTimes(morningStart, morningEnd);
      const afternoonTimes = parseTimes(afternoonStart, afternoonEnd);

      state.times = [...morningTimes, ...afternoonTimes];
    },
    toggleTourModal: (state) => {
      state.isTourOpen = !state.isTourOpen;
    },
  },
});

export const { setDay, setTime, setDisabledDays, setAvailableTimes, toggleTourModal } = tourSchoolSlice.actions;

export default tourSchoolSlice.reducer;

const parseTimes = (startTime, endTime) => {
  let availableTimes = [];
  if (startTime && endTime) {
    const start = moment.utc(startTime, 'HH:mm:ss');
    const end = moment.utc(endTime, 'HH:mm:ss');

    do {
      availableTimes.push({
        value: start.format('HH:mm:ss'),
        text: start.format('hh:mm A'),
      });
      start.add(30, 'm');
    } while (start <= end);
  }
  return availableTimes;
};
