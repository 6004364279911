/* eslint-disable */ 
import { combineReducers } from 'redux';
import getApiReducer from 'redux/util/get-api-reducer';

import {
    GET_STUDENTS,
    ADD_STUDENT,
    DELETE_STUDENT,
    GET_STUDENT,
    UPDATE_STUDENTS,
} from './student-actions';

const reducers = combineReducers({
    getStudents: getApiReducer(GET_STUDENTS),
    getStudent: getApiReducer(GET_STUDENT),
    addStudent: getApiReducer(ADD_STUDENT),
    deleteStudent: getApiReducer(DELETE_STUDENT),
    updateStudent: getApiReducer(UPDATE_STUDENTS),
    
});

export default reducers;
