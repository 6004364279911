import client from 'redux/client';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const GET_USER_AVATARS = 'GET_USER_AVATARS';
export const GET_USER_CONNECTIONS = 'GET_USER_CONNECTIONS';
export const ACCEPT_USER_CONNECTIONS = 'ACCEPT_USER_CONNECTIONS';
export const DECLINE_USER_CONNECTIONS = 'DECLINE_USER_CONNECTIONS';
export const GET_CONNECTION_COUNT = 'GET_CONNECTION_COUNT';
export const GET_USER_EMAIL = 'GET_USER_EMAIL';
export const ADD_USER = 'ADD_USER';

export const ADD_SEARCH = 'ADD_SEARCH';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const GET_SEARCHES = 'GET_SEARCHES';
export const GET_TOURS_BY_USER = 'GET_TOURS_BY_USER';
export const GET_LEADS_BY_USER = 'GET_LEADS_BY_USER';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const DELETE_USER_SEARCH = 'DELETE_USER_SEARCH';

export const GET_USER_APPLICATION = 'GET_USER_APPLICATION';
export const GET_USER_APPLICATIONS = 'GET_USER_APPLICATIONS';
export const ADD_USER_APPLICATION = 'ADD_USER_APPLICATION';
export const UPDATE_USER_APPLICATION = 'UPDATE_USER_APPLICATION';
export const DELETE_USER_APPLICATION = 'DELETE_USER_APPLICATION';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_SCHOOL_DEPARTMENT = 'UPDATE_USER_SCHOOL_DEPARTMENT';

export function uploadImage(file, userId) {
  const formData = new FormData();
  formData.append('avatar', file);

  return {
    type: UPLOAD_IMAGE,
    promise: client.patch(`users/${userId}`, formData),
  };
}

export function getUserAvatars(userIds) {
  return {
    type: GET_USER_AVATARS,
    promise: client.get(`users/avatars?userIds=${JSON.stringify(userIds)}`),
  };
}

export function getUserIdByEmail(email) {
  return {
    type: GET_USER_EMAIL,
    promise: client.get(`users/${email}/id`),
  };
}

export function createUser(payload) {
  return {
    type: ADD_USER,
    promise: client.post(`users`, payload),
  };
}

export function getUserConnectionRequests(userId, query) {
  return {
    type: GET_USER_CONNECTIONS,
    promise: client.get(`requesttoconnect/${encodeURI(userId)}/school-connections`, query),
  };
}

export function acceptConnectionRequest(requestId, query) {
  return {
    type: ACCEPT_USER_CONNECTIONS,
    promise: client.patch(`requesttoconnect/${encodeURI(requestId)}/accept`, query),
  };
}

export function declineConnectionRequest(requestId, query) {
  return {
    type: DECLINE_USER_CONNECTIONS,
    promise: client.patch(`requesttoconnect/${encodeURI(requestId)}/decline`, query),
  };
}

export function getConnectionCounts(userId) {
  return {
    type: GET_CONNECTION_COUNT,
    promise: client.get(`requesttoconnect/${encodeURI(userId)}/school-connections-unread-count`),
  };
}

export function addSearch(userId, searchName, notifyFrecuency, search) {
  const formData = new FormData();
  formData.append('name', searchName);
  formData.append('notifyFrecuency', JSON.stringify(notifyFrecuency));
  formData.append('search', JSON.stringify(search));

  return {
    type: ADD_SEARCH,
    promise: client.post(`users/${userId}/searches`, formData),
  };
}

export function updateSearch(userId, searchId, name, notifyFrecuency, search) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('notifyFrecuency', JSON.stringify(notifyFrecuency));
  formData.append('search', JSON.stringify(search));

  return {
    type: UPDATE_SEARCH,
    promise: client.patch(`users/${userId}/searches/${searchId}`, formData),
  };
}

export function getSearches(userId) {
  return {
    type: GET_SEARCHES,
    promise: client.get(`users/${userId}/searches`),
  };
}

export function deleteSearch(userId, searchId) {
  return {
    type: DELETE_USER_SEARCH,
    promise: client.delete(`users/${userId}/searches/${searchId}`),
  };
}

export function getTourList(userId) {
  return {
    type: GET_TOURS_BY_USER,
    promise: client.get(`users/${userId}/tours`),
  };
}

export function getUserLeads(userId) {
  return {
    type: GET_LEADS_BY_USER,
    promise: client.get(`users/${userId}/leads`),
  };
}

export function updateUserInfo(userId, userData) {
  var payload = new FormData();
  payload.append('firstName', userData.firstName);
  payload.append('lastName', userData.lastName);
  payload.append('phone', userData.phone);
  payload.append('email', userData.email);
  if (userData.preferred_contact) payload.append('preferred_contact', userData.preferred_contact);
  if (userData.language) payload.append('language', userData.language);
  if (userData.address) payload.append('address', userData.address);
  if (userData.zipcode) payload.append('zipcode', userData.zipcode);
  if (userData.referred_by) payload.append('referred_by', userData.referred_by);
  if (userData.referred_by_other) payload.append('referred_by_other', userData.referred_by_other);

  if (userData.password) {
    payload.append('password', userData.password);
  }
  if (userData.password_new) {
    payload.append('password_new', userData.password_new);
  }

  return {
    type: UPDATE_USER_INFO,
    promise: client.patch(`users/${userId}/info`, payload),
  };
}

export function updateUserPassword(userId, userData) {
  var payload = new FormData();
  payload.append('password', userData.password);

  return {
    type: UPDATE_USER_PASSWORD,
    promise: client.patch(`users/${userId}/password`, payload),
  };
}

export function updateUserSchoolDepartment(schoolId, userId, userData) {
  var payload = new FormData();
  payload.append('user_school_deparment_id', userData.departmentValue);
  return {
    type: UPDATE_USER_SCHOOL_DEPARTMENT,
    promise: client.patch(`schools/${schoolId}/users/${userId}/department`, payload),
  };
}

export function getUserApplications(userId) {
  return {
    type: GET_USER_APPLICATIONS,
    promise: client.get(`users/${userId}/applications`),
  };
}

export function getUserApplication(userId, userApplicationId) {
  return {
    type: GET_USER_APPLICATION,
    promise: client.get(`users/${userId}/applications/${userApplicationId}`),
  };
}

export function addUserApplication(userId, data) {
  var payload = new FormData();
  payload.append('parent_relationship', data.parent_relationship);
  payload.append('parent_first_name', data.parent_first_name);
  payload.append('parent_last_name', data.parent_last_name);
  payload.append('email', data.email);
  payload.append('phone', data.phone);
  payload.append('preferred_contact', data.preferred_contact);
  payload.append('student_id', parseInt(data.student_id, 10));

  return {
    type: ADD_USER_APPLICATION,
    promise: client.post(`users/${userId}/applications`, payload),
  };
}

export function updateUserApplication(userId, userApplicationId, data) {
  var payload = new FormData();
  payload.append('parent_relationship', data.parent_relationship);
  payload.append('parent_first_name', data.parent_first_name);
  payload.append('parent_last_name', data.parent_last_name);
  payload.append('email', data.email);
  payload.append('phone', data.phone);
  payload.append('preferred_contact', data.preferred_contact);
  payload.append('student_id', data.student_id);

  return {
    type: UPDATE_USER_APPLICATION,
    promise: client.patch(`users/${userId}/applications/${userApplicationId}`, payload),
  };
}

export function deleteUserApplication(userId, userApplicationId) {
  return {
    type: DELETE_USER_APPLICATION,
    promise: client.delete(`users/${userId}/applications/${userApplicationId}`),
  };
}

export function getUserProfile(userId) {
  return {
    type: GET_USER_PROFILE,
    promise: client.get(`users/${userId}/profile`),
  };
}
