/**
  Feature ID groups skiped:
  School Supports = 29
  Special Needs Support = 32
  Teachers = 32
  Transportation - 20
  Facilities - 27
  Uniforms - 25
  Other - 44
  Other - 13
*/

export const transformGroupFeatures = (features) => {
  const uselessGroups = [29, 32, 34, 20, 25, 44, 13, 27, 30, 31, 28];
  if (features) {
    const { groups, coed, environmets, specialNeeds, curriculumIds } = features.reduce(
      (acc, groupFeatures) => {
        if (uselessGroups.includes(groupFeatures.id)) {
          return acc;
        }

        const groupName = groupFeatures.name;
        switch (groupName) {
          case 'Gender':
            acc.coed = groupFeatures;
            break;

          case 'School Learning Environment':
            acc.environmets = groupFeatures;
            break;

          case 'Specific Educational Approach':
          case 'Core Knowledge':
          case 'Alternative':
          case 'Traditional':
            acc.groups['Teaching Methods'].push(groupFeatures);
            break;

          case 'Gifted Students support':
          case 'Language Education':
          case 'Scholarship Programs':
            acc.groups.Programs.push(groupFeatures);
            break;

          case 'Sports & Outdoors':
          case 'Art & Communication':
          case 'Extra Features':
          case 'Student Government & Leadership':
          case 'Volunteer & Community':
            acc.groups.Extracurricular.push(groupFeatures);
            break;

          case 'Special Needs':
            acc.specialNeeds = groupFeatures;
            break;

          case 'Religious Affiliation':
            acc.groups.Religion.push(groupFeatures);
            break;

          default:
            const ids = groupFeatures.features.map(({ id, name }) => ({ id, name }));
            acc.curriculumIds.push(...ids);
            break;
        }

        return acc;
      },
      {
        coed: {},
        environmets: {},
        specialNeeds: {},
        curriculumIds: [],
        groups: {
          'Teaching Methods': [],
          Programs: [],
          Extracurricular: [],
          Religion: [],
        },
      }
    );

    return { groups, coed, environmets, specialNeeds, curriculumIds };
  }
  return {};
};
