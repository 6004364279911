import React from 'react';
import PropTypes from 'prop-types';
import { FeatureList } from '../../../feature-list/feature-list';
import styles from './school-features.module.scss';
import { useDisplayFeatures } from '../hooks/useDisplayFeatures';

export const SchoolFeatures = ({ topFeatures, title, description }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.features_heading}>{title}</h2>
      {description && (
        <p className={`${styles.body_regular} ${styles.feature_description} spacing-mb-16`}>{description}</p>
      )}
      {useDisplayFeatures(topFeatures) && <FeatureList features={topFeatures} type="TOP" data-testid="feature-list" />}
    </div>
  );
};

FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
