import React from 'react';
import styles from './careers.module.scss';

export const Careers = ({ careers }) => {
  return (
    <div className={styles.careersGrid}>
      {careers.map((career) => (
        <CareerCard key={career.name} name={career.name} description={career.value} />
      ))}
    </div>
  );
};

const CareerCard = ({ name, description }) => {
  const careerImage = getCareerImage(name);

  return (
    <div className={styles.career}>
      <img src={careerImage} className={styles.image} />
      <div className={styles.text}>
        <h3 className={styles.title}>{name}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

const getCareerImage = (career = '') => {
  let careerKey = career.toLowerCase().replace(/ /g, '-');

  if (careerKey === 'programmer') {
    careerKey = 'software-developer';
  }
  if (careerKey === 'psychologist') {
    careerKey = 'counselor';
  }
  if (careerKey === 'human-resources-specialist') {
    careerKey = 'human-resources-manager';
  }

  const imageUrl = `https://static.schola.com/personality/${careerKey}.webp`;
  return imageUrl;
};
