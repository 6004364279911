import React from 'react';
import { BannerCard } from '../components/banner-card/banner-card';
import styles from '../schools-results.module.scss';
import {
  SkillTable,
  RadarChart,
  AboutBanner,
  Careers,
  StrengthsAreasImprovement,
  EffectiveOptions,
  FeatureLists,
  SchoolChoice,
} from './components';
import {
  getPersonalityData,
  getCareerPaths,
  getLearnignApproach,
  getSkills,
} from 'components/pages/personality-match/utils';
import { getStudent } from '../utils/get-student';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';

const PersonalityOverview = ({ location, params }) => {
  const personalityId = params.personality;
  const personalityMatch = getPersonalityData(personalityId);

  const student = getStudent();
  const { schoolsPath } = useSelector((state) => state.schoolResults);
  const goToSchools = () => browserHistory.push(schoolsPath);

  const { educationalApproach, alternatives } = getLearnignApproach(personalityId);

  const skills = getSkills(personalityId, student);

  const careers = getCareerPaths(personalityId);

  return (
    <div className={styles.layout_overview}>
      <section className={styles.container}>
        <h3 className="heading-1 font-600 spacing-mb-12">
          <span className="underline">{student}’s</span> Brain Superpowers
        </h3>
        <p>
          These are the special abilities that our brains have to help us think, learn, and understand the world around
          us.
        </p>
      </section>
      <section className={styles.container}>
        <div className={styles.columns_graph_superpowers}>
          <RadarChart data={personalityMatch.skillsValues} />
          <SkillTable skills={skills} />
        </div>
      </section>
      <section className={styles.container}>
        <h3 className="heading-1 font-600">
          <span className="underline">{student}’s</span> Strengths & Areas for Improvement
        </h3>
      </section>
      <section className={styles.container}>
        <StrengthsAreasImprovement
          strengthsSkills={personalityMatch.strengths}
          weaknessesSkills={personalityMatch.weaknesses}
        />
      </section>
      <section className={styles.container}>
        <SchoolChoice educationalApproach={educationalApproach} />
      </section>
      <section className={styles.container}>
        <EffectiveOptions alternatives={alternatives} />
      </section>
      <section className={styles.container}>
        <h4 className="spacing-mb-16">Subjects & Extracurricular Activities For {personalityId}</h4>
        <FeatureLists subjects={personalityMatch.subjects} extracurricular={personalityMatch.extracurricular} />
      </section>
      <section className={styles.container}>
        <BannerCard
          title="Review Your Top School Choices!"
          description="Learn more about the best schools for your child. Get connected and gather detailed information to make the best decision."
          buttonLabel={`See ${student}’s School Matches!`}
          onClick={goToSchools}
        />
      </section>
      <section className={styles.container}>
        <h3 className="heading-1 font-600 spacing-mb-20">Common Career Paths for {student}</h3>
        <p>
          Here are some of the most common careers {student} can pursue, though she is not limited to these options.
        </p>
      </section>
      <section className={styles.container}>
        <hr style={{ border: '1px solid lightgray' }} />
      </section>
      <section className={styles.container}>
        <Careers careers={careers} />
      </section>
      <section className={styles.container}>
        <AboutBanner
          title={`Discover ${student}'s Perfect School Match!`}
          subtitle="Are you ready to see how we matched your child’s personality with the perfect school?"
          description={`Explore personalized school options tailored to ${student}'s unique strengths and interests. Our expert recommendations ensure the best fit for their educational journey. Don't miss out on finding the ideal environment for ${student}'s success today!`}
          buttonText={`Uncover ${student}’s Best School Options!`}
          arrowTextLeft="Start Here!"
          arrowTextRight="Unlock your child's Success"
        />
      </section>
    </div>
  );
};
export default PersonalityOverview;
